import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { Button } from '~/components/Button';
import { IntercomArticleHelp } from '~/components/IntercomArticleHelp';
import { VStack } from '~/components/containers/Stack';
import { Body, Title } from '~/components/next/foundation/Typography';
import { Layout } from '~/components/next/layout';
import { Modal } from '~/components/next/modal';
import type { MessageQueryParamValue } from '~/utils/urlMessageParam';
import {
  MESSAGE_QUERY_PARAM_KEY,
  MESSAGE_QUERY_PARAM_VALUES,
} from '~/utils/urlMessageParam';
import { omit } from '~/utils/utility';

/**
 * Aside for intercepting a query parameter and displaying a message to the
 * user. Uses shared `urlMessageParam` module to define possible messages to
 * handle.
 *
 * Dismissing the message will remove the query parameter from the URL.
 */
export function UrlMessageAside() {
  const router = useRouter();
  const value = router.query[MESSAGE_QUERY_PARAM_KEY];
  const content = isValidValue(value) ? getContent(value) : null;
  const onDismiss = (): void =>
    void router.replace(
      { query: omit(router.query, MESSAGE_QUERY_PARAM_KEY) },
      undefined,
      { shallow: true },
    );

  // TODO: Ignore for new UI.
  if (router.pathname.includes('/v2')) {
    return null;
  }

  return (
    <Modal.Aside onClose={onDismiss} show={content !== null}>
      <Layout.Base
        footer={
          <div className="flex w-full justify-end">
            <Button variant="black" onClick={onDismiss}>
              Dismiss
            </Button>
          </div>
        }
      >
        <VStack gap="6">
          <Title>{content?.title}</Title>
          {content?.description}
        </VStack>
      </Layout.Base>
    </Modal.Aside>
  );
}

function isValidValue(value: unknown): value is MessageQueryParamValue {
  let v = value;
  if (Array.isArray(v)) {
    v = v[0];
  }
  if (typeof v !== 'string') {
    return false;
  }
  return MESSAGE_QUERY_PARAM_VALUES.includes(v as MessageQueryParamValue);
}

type Content = {
  title: string;
  description: ReactNode;
};

// TODO: MIGRATE THIS
function getContent(value: MessageQueryParamValue): Content {
  switch (value) {
    case 'quickbooks-success':
    case 'xero-success': {
      const [name, article] =
        value === 'quickbooks-success'
          ? ['QuickBooks', 8708558]
          : ['Xero', 8123282];
      return {
        title: `${name} Live-Sync is Connected`,
        description: (
          <>
            <Body>
              We are now importing your contacts, bills, and invoices to Tola
              from {name}.
            </Body>
            <Body>
              This may take a few minutes, but you can continue to use Tola
              while we sync data in the background.
            </Body>
            <Body>
              For more information on {name} Live-Sync, check out our article on{' '}
              <IntercomArticleHelp
                text="how it works."
                intercomArticleId={article}
              />
            </Body>
          </>
        ),
      };
    }
    case 'accounting-error': {
      return {
        title: 'Something went wrong',
        description: (
          <>
            <Body>
              We were unable to complete the connection to your accounting
              software.
            </Body>
            <Body>
              Please try again, or contact support if the problem persists.
            </Body>
          </>
        ),
      };
    }
  }
}
