import { Temporal } from '@js-temporal/polyfill';
import type { E164Number } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';
import { z } from 'zod';
import { usdAmount } from '~/utils/usdAmount';

/**
 * Create an enum with a zod schema and a helper function to get the options
 * @example
 * const example = createEnum({
 *  foo: 'Foo',
 *  bar: 'Bar',
 * });
 * example.options(); // [{ label: 'Foo', value: 'foo' }, { label: 'Bar', value: 'bar' }]
 * example.schema.parse('foo'); // 'foo'
 */
export function createEnum<TKeys extends string>(
  values: Record<TKeys, string> | TKeys[],
  opts?: {
    errorMessage?: string;
  },
) {
  const errorMessage = opts?.errorMessage ?? 'Please select an option';
  const record: Record<TKeys, string> = Array.isArray(values)
    ? values.reduce(
        (acc, key) => {
          acc[key] = key;
          return acc;
        },
        {} as Record<TKeys, string>,
      )
    : values;

  return {
    options() {
      return Object.entries(record).map(([key, value]) => ({
        label: value,
        value: key,
      })) as Array<{
        label: string;
        value: TKeys;
      }>;
    },
    schema: z.enum(Object.keys(record) as [TKeys, ...TKeys[]], {
      errorMap() {
        return {
          message: errorMessage,
        };
      },
    }),
  };
}

// Useful validation functions

export const keepDigits = (v: string) => v.replace(/\D/g, '');

const isPostalCode = (value: string) => /^\d{5}(-\d{4})?$/.test(value);
export const postalCodeUsSchema = z
  .string({ message: 'Please enter a valid ZIP code.' })
  .refine(isPostalCode, {
    message: 'Please enter a valid ZIP code.',
  });

export const parseRequiredPhoneNumber = (
  val: string,
  ctx: z.RefinementCtx,
): E164Number => {
  const phoneNumber = parsePhoneNumber(val, { defaultCountry: 'US' });
  if (!phoneNumber || !phoneNumber.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Please enter a valid phone number',
      params: [{ value: val }],
    });
    return z.NEVER;
  }

  return phoneNumber.number;
};

export const parseRequiredUsPhoneNumber = (
  val: string,
  ctx: z.RefinementCtx,
): E164Number => {
  const phoneNumber = parsePhoneNumber(val, { defaultCountry: 'US' });
  if (!phoneNumber || !phoneNumber.isValid() || phoneNumber.country !== 'US') {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Please enter a valid US phone number',
      params: [{ value: val }],
    });
    return z.NEVER;
  }

  return phoneNumber.number;
};

export const parseOptionalPhoneNumber = (
  val: string | null | undefined,
  ctx: z.RefinementCtx,
): E164Number | null | undefined => {
  if (!val) {
    return val;
  }
  return parseRequiredPhoneNumber(val, ctx);
};

export const requiredStringSchema = z.string().trim().min(1, 'Required');

export const optionalStringSchema = z.string().trim().nullish();

export const requiredEmailSchema = requiredStringSchema.email().toLowerCase();

export const requiredUsPhoneSchema = requiredStringSchema.transform(
  parseRequiredUsPhoneNumber,
);
export const requiredPhoneSchema = requiredStringSchema.transform(
  parseRequiredPhoneNumber,
);
export const optionalPhoneSchema = z
  .union([
    z.literal('').transform(() => null),
    z.string().trim().transform(parseOptionalPhoneNumber),
  ])
  .nullish();

// This was created using ChatGPT
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*|(\".+\"))@([a-zA-Z0-9_]+([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;

export const optionalEmail = z
  .union([
    z.literal('').transform(() => null),
    z.string().trim().regex(emailRegex).toLowerCase(),
  ])
  .nullish();

/**
 * Optional ID schema that transforms an empty string to `undefined`.
 * This is because of the way HTML forms works, an unselected value is often an empty string.
 */
export const optionalIdSchema = z
  .string()
  .trim()
  .optional()
  .transform((v) => (v === '' ? undefined : v));

const digitStringSchema = z
  .string()
  .trim()
  .regex(/^\d*$/, 'Only digits accepted');

// Complex schemas

export const withIdSchema = z.object({
  id: requiredStringSchema,
});

export const withOrgIdSchema = z.object({
  organizationId: requiredStringSchema,
});

export const plainDateSchema = z
  .string()
  .date()
  .transform((it) => Temporal.PlainDate.from(it));

export const routingNumberSchema = digitStringSchema.length(9);

export const accountNumberSchema = z
  .string()
  .trim()
  .min(1)
  .regex(/^\d{5,17}$/, 'Invalid account number');

/**
 * This would be nicer as a `usdAmount.createDecimalStringSchema()`, but Retool coerces
 * inputs into numbers automatically and there's no way to override it AFAICT.
 */
export const retoolUSDAmountInputSchema = z
  .number()
  .min(0)
  .transform((v) => usdAmount.fromDecimals(v));
