import { Transition } from '@headlessui/react';
import type { ReactNode } from 'react';
import React from 'react';
import type { z } from 'zod';
import { Button } from '~/components/Button';
import { Form } from '~/components/Form';
import { ArrowLeftIcon, CloseIcon } from '~/components/GeneratedIcon';
import type { createWizard } from '~/components/Wizard';
import { VStack } from '~/components/containers/Stack';
import { Body, Title } from '~/components/next/foundation/Typography';
import { Layout } from '~/components/next/layout';
import { cx } from '~/utils/css-utils';
import { omit, pick } from '~/utils/utility';

export type WizardTransitionType = 'forward' | 'backward' | 'none';

export type WizardTransitionProps = {
  children: ReactNode;
  show: boolean;
  transitionType: WizardTransitionType;
};

export type WizardTransition = React.FC<WizardTransitionProps>;

const HorizontalTransition: WizardTransition = (props) => {
  const { transitionType } = props;

  const transitions = {
    className: 'transition duration-500 ease-drawer',
    forward: {
      enterFrom: 'translate-x-full',
      enterTo: 'translate-x-0',
      leave: 'brightness-75 -z-1',
      leaveFrom: 'translate-x-0',
      leaveTo: '-translate-x-1/4',
    },
    backward: {
      enterFrom: '-translate-x-1/4 brightness-75',
      enterTo: 'translate-x-0',
      leaveFrom: 'translate-x-0',
      leaveTo: 'translate-x-full',
    },
  } as const;

  const transition =
    transitionType === 'none' ? {} : transitions[transitionType];

  return (
    <Transition
      show={props.show}
      as="div"
      className={cx('absolute inset-0 flex', transitions.className)}
      {...transition}
    >
      {props.children}
    </Transition>
  );
};

export const WizardTransitions = {
  horizontal: HorizontalTransition,
} as const;

export function configureTransitions<
  TStep extends string,
  TFormState extends Record<string, z.ZodType>,
>(Wizard: ReturnType<typeof createWizard<TStep, TFormState>>) {
  Wizard.Layout = function CustomLayout({ children }) {
    const { hasPreviousStep, isFinalStep, back, onClose } = Wizard.useContext();
    const showBackButton = hasPreviousStep && !isFinalStep;
    return (
      <div className="relative flex h-full flex-1 overflow-hidden pt-8">
        <Layout.Base.Header>
          {(showBackButton || onClose) && (
            <Layout.Base.InnerHeader
              className={cx(showBackButton ? 'justify-between' : 'justify-end')}
            >
              {showBackButton && (
                <Button
                  onClick={back}
                  EndIcon={ArrowLeftIcon}
                  variant="subtle"
                />
              )}
              {onClose && (
                <Button
                  onClick={onClose}
                  EndIcon={CloseIcon}
                  variant="subtle"
                />
              )}
            </Layout.Base.InnerHeader>
          )}
        </Layout.Base.Header>

        {children}
      </div>
    );
  };
  Wizard.Step = function Step(props) {
    const { footer, title, subtitle, description, children } = props;
    return (
      <Layout.Base
        footer={footer && <div className="flex justify-end">{footer}</div>}
      >
        <VStack gap="2">
          {[title, subtitle, description].some(Boolean) && (
            <VStack gap="4" className="overflow-hidden py-8">
              <VStack>
                {subtitle && (
                  <Title className="text-base-muted">{subtitle}</Title>
                )}
                {title && <Title>{title}</Title>}
              </VStack>

              {description && (
                <Body className="text-base-muted">{description}</Body>
              )}
            </VStack>
          )}

          {children}
        </VStack>
      </Layout.Base>
    );
  };
  Wizard.FormStep = function FormStep(props) {
    const stepProps = pick(props, [
      'title',
      'subtitle',
      'description',
      'footer',
      'children',
    ]);
    const formProps = omit(props, [
      'children',
      'footer',
      'title',
      'subtitle',
      'description',
      'shrink',
      'fullWidth',
      'footerPadding',
    ]);
    return (
      <Form {...formProps} className={cx(formProps.className, 'flex flex-1')}>
        <Wizard.Step {...stepProps} />
      </Form>
    );
  };

  Wizard.setTransition('horizontal');
  Wizard.useSingleLayout = true;
}
