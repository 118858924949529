import { EmptyState } from '~/components/EmptyState';
import { SadIllustration } from '~/components/Illustrations';
import { createLogger } from '~/utils/logger';

const logger = createLogger('DefaultError');
interface ErrorLike {
  message: string;
}

export function DefaultError(props: { error: Error | ErrorLike }) {
  logger.error(
    'Something went wrong.',
    { error: props.error },
    props.error instanceof Error ? props.error : undefined,
  );
  return (
    <EmptyState
      Illustration={SadIllustration}
      title="Something went wrong."
      subtitle={props.error.message}
      data-test-id="default-error"
    />
  );
}
