import { Portal } from '@headlessui/react';
import type { Toast as _Toast } from 'react-hot-toast';
import toast, {
  Toaster as BaseToaster,
  useToasterStore,
} from 'react-hot-toast';
import {
  AlertIcon,
  CheckmarkIcon,
  CloseIcon,
  InformationIcon,
} from '~/components/Icon';
import type { SVGComponent } from '~/components/SVGComponent';
import { Spinner } from '~/components/Spinner';
import { VStack } from '~/components/containers/Stack';
import {
  type IconOrnamentProps,
  Ornament,
} from '~/components/next/atoms/Ornament';
import { Body } from '~/components/next/foundation/Typography';
import { cx } from '~/utils/css-utils';

export interface ToastOpts {
  /**
   * @default 'default'
   */
  variant?: 'default' | 'success' | 'information' | 'danger';
  title: string;
  message?: React.ReactNode;
  loading?: boolean;
  StartIcon?: SVGComponent;
}

const ToastOrnaments: Record<string, IconOrnamentProps> = {
  success: {
    Icon: CheckmarkIcon,
    variant: 'inverse',
    bgColor: 'bg-brand-nicewin95',
  },
  information: {
    Icon: InformationIcon,
    variant: 'inverse',
    bgColor: 'bg-tint',
  },
  danger: {
    Icon: AlertIcon,
    variant: 'inverse',
    bgColor: 'bg-brand-carrotjuice',
  },
};

export function ToastContainer(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className, ...rest } = props;

  return (
    <div
      className={cx(
        'pointer-events-auto grid w-[390px] max-w-md items-start gap-3 rounded-xl border-hairline border-theme bg-fill-material px-4 pt-4 pb-5 shadow-toast backdrop-blur-3xl',
        className,
      )}
      {...rest}
    />
  );
}

export function Toast(t: _Toast, opts: ToastOpts) {
  const {
    variant = 'default',
    title,
    message,
    loading = false,
    StartIcon,
  } = opts;

  const ornamentProps = (() => {
    if (!StartIcon && variant === 'default') {
      return undefined;
    }

    const variantProps = ToastOrnaments[variant];

    return StartIcon ? { ...variantProps, Icon: StartIcon } : variantProps;
  })();

  const ornament = (() => {
    if (!ornamentProps?.Icon) {
      return null;
    }
    if (loading) {
      return <Spinner style="black" className="h-6 w-6" />;
    }
    return <Ornament.Icon {...ornamentProps} />;
  })();

  return (
    <ToastContainer
      data-test-id={`toast-${variant}`}
      className={cx(
        ornamentProps?.Icon
          ? 'grid-cols-[min-content_1fr_0fr]'
          : 'grid-cols-[1fr_0fr]',
        t.visible ? 'animate-enter' : 'animate-leave',
      )}
      {...t.ariaProps}
    >
      {ornament}

      <VStack gap="0">
        <Body>{title}</Body>
        {message && <Body className="text-secondary">{message}</Body>}
      </VStack>

      <button
        type="button"
        onClick={() => toast.dismiss(t.id)}
        className="text-secondary hover:text-gray-900"
      >
        <CloseIcon className={cx('ml-2 h-5 w-5 self-center')} />
      </button>
    </ToastContainer>
  );
}

function createToast(opts: ToastOpts) {
  return function (t: _Toast) {
    return Toast(t, opts);
  };
}

export function showToast(opts: ToastOpts) {
  toast.custom(createToast(opts), {
    position: 'top-right',
    duration: opts.variant === 'success' ? 2000 : 4000,
    ariaProps: {
      role: opts.variant === 'danger' ? 'alert' : 'status',
      'aria-live': 'polite',
    },
  });
}

export const useToasts = () => {
  const { toasts } = useToasterStore();
  return toasts;
};

export function Toaster() {
  return (
    // The Portal is required to prevent triggering TolaDialog onClose event when interacting with toasts
    // https://github.com/tailwindlabs/headlessui/issues/780#issuecomment-917663939
    <Portal>
      <BaseToaster gutter={16} />
    </Portal>
  );
}
