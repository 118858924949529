import Link from 'next/link';
import { Route } from 'nextjs-routes';
import type { ComponentPropsWithoutRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppViewerOrNull } from '~/components/AppContext';
import { useLogout } from '~/hooks/useLogout';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { Card } from '~/modules/ui/primitives/card';
import { LogOutIcon } from '~/modules/ui/primitives/icon';
import { Lumiflex } from '~/modules/ui/primitives/lumiflex';
import { MotionProvider, m } from '~/modules/ui/primitives/motion-provider';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { Toolbar, ToolbarButton } from '~/modules/ui/primitives/toolbar';
import { default as Tooltip } from '~/modules/ui/primitives/tooltip';
import { PaymentsByTola, Wordmark } from '~/modules/ui/primitives/wordmark';

export function AuthView({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <div className="t2-bg-background">
      <HStack
        className={cn(
          't2-relative t2-min-h-screen t2-w-full t2-p-0.5 t2-text-foreground',
          className,
        )}
        gap="0.5"
        {...props}
      >
        {children}

        <AuthViewAside variant="ghost">
          <m.div
            className="t2-absolute t2-inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
            data-test-screenshot-mask
          >
            <Lumiflex />
          </m.div>
        </AuthViewAside>
      </HStack>
    </div>
  );
}

export function AuthViewColumn({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Card>) {
  return (
    <Card className={cn('t2-relative t2-flex-auto', className)} {...props}>
      {children}
    </Card>
  );
}

function AuthViewHeader() {
  const viewer = useAppViewerOrNull();
  const intercom = useIntercom();
  const logout = useLogout();

  return (
    <HStack
      justifyContent="between"
      alignItems="center"
      className="t2-sticky t2-top-4 md:t2-top-6 t2-z-30 t2-pointer-events-none [&>*]:t2-pointer-events-auto"
    >
      <Link
        // We want to throw back to index which doesn't exist to redirect to where they should be
        // based on their current authentication status.
        href={'/' as unknown as Route}
        className={cn('t2-self-center t2-rounded-full', focusRingClassNames)}
      >
        <Wordmark />
      </Link>

      {viewer && (
        <Toolbar>
          <Tooltip
            disableHoverableContent
            delayDuration={0}
            side="bottom"
            align="center"
            content="Sign out"
          >
            <ToolbarButton intent="ghost" onClick={() => logout.logout()}>
              <LogOutIcon />
            </ToolbarButton>
          </Tooltip>
        </Toolbar>
      )}
    </HStack>
  );
}

type AuthViewOptions = {
  showFooterWordmark?: boolean;
};

const defaultAuthViewOptions: AuthViewOptions = {
  showFooterWordmark: true,
};

interface AuthViewContentProps
  extends ComponentPropsWithoutRef<typeof AuthViewColumn> {
  options?: AuthViewOptions;
}

export function AuthViewContent({
  className,
  children,
  options = defaultAuthViewOptions,
  ...props
}: AuthViewContentProps) {
  return (
    <AuthViewColumn
      className={cn(
        't2-flex t2-flex-col t2-gap-10 md:t2-gap-12 lg:t2-gap-16 t2-shrink-0 t2-w-full md:t2-w-7/12 t2-min-h-full t2-p-8 lg:t2-p-12',
        className,
      )}
      {...props}
    >
      <AuthViewHeader />
      <VStack className="t2-grow t2-max-w-md">{children}</VStack>
      <AuthViewFooter options={options} />
    </AuthViewColumn>
  );
}

export function AuthViewContentHeader(
  props: ComponentPropsWithoutRef<typeof VStack>,
) {
  return <VStack gap="3" {...props} />;
}

export function AuthViewTitle({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1 className={cn('t2-text-title t2-text-balance', className)} {...props} />
  );
}

export function AuthViewDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={cn(
        't2-text-body t2-text-foreground-secondary t2-text-pretty',
        className,
      )}
      {...props}
    />
  );
}

function AuthViewAside({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof AuthViewColumn>) {
  return (
    <AuthViewColumn
      className={cn(
        't2-hidden md:t2-block t2-grow md:t2-w-full t2-sticky t2-top-0.5 t2-h-[calc(100vh-4px)] t2-overflow-hidden',
        className,
      )}
      {...props}
    />
  );
}

interface AuthViewFooterProps extends ComponentPropsWithoutRef<'div'> {
  options?: AuthViewOptions;
}

export function AuthViewFooter({
  className,
  options = defaultAuthViewOptions,
  ...props
}: AuthViewFooterProps) {
  return (
    <div
      className={cn(
        't2-flex t2-flex-col-reverse t2-items-start t2-gap-y-5 t2-py-2 t2-w-full',
        'sm:t2-flex-row sm:t2-items-center sm:t2-justify-between',
        'md:t2-flex-col-reverse md:t2-items-start',
        'lg:t2-flex-row lg:t2-items-center lg:t2-justify-between',
        className,
      )}
      {...props}
    >
      {options?.showFooterWordmark ? <PaymentsByTola /> : <span aria-hidden />}

      <HStack alignItems="center" gap="6" className="sm:t2-gap-8">
        <AuthViewFooterLink href="https://usetola.com/legal/terms-of-service">
          Terms of Service
        </AuthViewFooterLink>
        <AuthViewFooterLink href="https://usetola.com/legal/privacy-policy">
          Privacy policy
        </AuthViewFooterLink>
      </HStack>
    </div>
  );
}

export function AuthViewFooterLink({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={cn(
        focusRingClassNames,
        'focus-visible:t2-rounded-sm focus-visible:t2-ring-offset-background',
        't2-text-foreground-secondary t2-leading-4 t2-decoration-1 t2-underline-offset-2 hover:t2-underline',
        className,
      )}
      {...props}
    />
  );
}

interface DefaultAuthViewProps extends ComponentPropsWithoutRef<typeof VStack> {
  options?: AuthViewOptions;
}

export function DefaultAuthView({
  options = defaultAuthViewOptions,
  children,
  className,
  ...props
}: DefaultAuthViewProps) {
  return (
    <MotionProvider>
      <AuthView>
        <AuthViewContent options={options}>
          <VStack
            gap="10"
            className={cn('t2-min-h-full', className)}
            justifyContent="center"
            {...props}
          >
            {children}
          </VStack>
        </AuthViewContent>
      </AuthView>
    </MotionProvider>
  );
}
