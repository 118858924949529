import type { SVGComponent } from '~/components/SVGComponent';

export const QuickBooksLogo: SVGComponent = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse
      id="Oval"
      fill="#2CA01C"
      cx="53.2753788"
      cy="53.2070588"
      rx="52.8409091"
      ry="52.9411765"
    ></ellipse>
    <path
      d="M15.107803,53.2047059 C15.107803,64.5752941 24.3079924,73.7929412 35.6570455,73.7929412 L38.5926515,73.7929412 L38.5926515,66.1458824 L35.6570455,66.1458824 C28.5340909,66.1458824 22.7403788,60.3411765 22.7403788,53.2047059 C22.7403788,46.0694118 28.5340909,40.2635294 35.6570455,40.2635294 L42.7107197,40.2635294 L42.7107197,80.2635294 C42.7107197,84.4870588 46.1277652,87.9105882 50.3432955,87.9105882 L50.3432955,32.6164706 L35.6570455,32.6164706 C24.3079924,32.6164706 15.107803,41.8352941 15.107803,53.2047059 L15.107803,53.2047059 Z M70.8960606,32.62 L67.9604545,32.62 L67.9604545,40.2670588 L70.8960606,40.2670588 C78.0166667,40.2670588 83.8127273,46.0729412 83.8127273,53.2082353 C83.8127273,60.3435294 78.0166667,66.1494118 70.8960606,66.1494118 L63.8388636,66.1494118 L63.8388636,26.1494118 C63.8388636,21.9258824 60.4218182,18.5023529 56.2062879,18.5023529 L56.2062879,73.7964706 L70.8960606,73.7964706 C82.2439394,73.7964706 91.445303,64.5788235 91.445303,53.2082353 C91.445303,41.8376471 82.2439394,32.62 70.8960606,32.62 L70.8960606,32.62 Z"
      id="Shape"
      fill="#FFFFFF"
    ></path>
  </svg>
);

export const Visa: SVGComponent = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_668_379)">
      <rect x="2" y="5" width="32" height="22" rx="3" fill="#F6F7F8" />
      <rect
        x="2.165"
        y="5.165"
        width="31.67"
        height="21.67"
        rx="2.835"
        stroke="#E2E6E9"
        strokeWidth="0.33"
      />
    </g>
    <path
      d="M15.4052 12.1414L12.1622 19.879H10.0463L8.45039 13.704C8.3535 13.3237 8.26926 13.1844 7.97458 13.0241C7.49348 12.7631 6.69901 12.5183 6 12.3663L6.04748 12.1414H9.45336C9.8875 12.1414 10.2778 12.4304 10.3763 12.9303L11.2192 17.4077L13.3023 12.1413H15.4052V12.1414ZM23.6955 17.3527C23.704 15.3105 20.8716 15.198 20.8911 14.2858C20.8972 14.0081 21.1615 13.7129 21.7402 13.6376C22.027 13.6001 22.8172 13.5714 23.7135 13.9839L24.065 12.3431C23.5834 12.1682 22.9637 12 22.1927 12C20.2143 12 18.822 13.0517 18.8102 14.5577C18.7974 15.6717 19.804 16.2933 20.5624 16.6634C21.3425 17.0424 21.6043 17.286 21.6013 17.6251C21.5958 18.1441 20.9791 18.3732 20.4028 18.3823C19.3967 18.3978 18.8129 18.1101 18.3476 17.8937L17.9848 19.5888C18.4525 19.8034 19.3157 19.9906 20.2107 20C22.3135 20 23.6891 18.9613 23.6955 17.3527ZM28.9199 19.879H30.7711L29.1552 12.1414H27.4465C27.0623 12.1414 26.7382 12.3651 26.5948 12.7091L23.5911 19.879H25.6929L26.1102 18.7233H28.6782L28.9199 19.879ZM26.6865 17.1374L27.74 14.2322L28.3464 17.1374H26.6865ZM18.2651 12.1414L16.61 19.879H14.6084L16.2642 12.1414H18.2651Z"
      fill="#1434CB"
    />
    <defs>
      <filter
        id="filter0_d_668_379"
        x="0"
        y="4"
        width="36"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_668_379"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_668_379"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Mastercard: SVGComponent = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_668_381)">
      <rect x="2" y="5" width="32" height="22" rx="3" fill="#F6F7F8" />
      <rect
        x="2.165"
        y="5.165"
        width="31.67"
        height="21.67"
        rx="2.835"
        stroke="#E2E6E9"
        strokeWidth="0.33"
      />
    </g>
    <circle cx="14" cy="16" r="6" fill="#ED0006" />
    <circle cx="22" cy="16" r="6" fill="#F9A000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 20.4722C19.2275 19.3736 20 17.777 20 16C20 14.223 19.2275 12.6264 18 11.5278C16.7725 12.6264 16 14.223 16 16C16 17.777 16.7725 19.3736 18 20.4722Z"
      fill="#FF5E00"
    />
    <defs>
      <filter
        id="filter0_d_668_381"
        x="0"
        y="4"
        width="36"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_668_381"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_668_381"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Amex: SVGComponent = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_1276_951)">
      <rect x="2" y="5" width="32" height="22" rx="3" fill="#F6F7F8" />
      <rect
        x="2.165"
        y="5.165"
        width="31.67"
        height="21.67"
        rx="2.835"
        stroke="#E2E6E9"
        strokeWidth="0.33"
      />
    </g>
    <path d="M11 9V15.9781V23H17.9998H25V16.0002V9H18.0025H11Z" fill="white" />
    <path
      d="M22.5033 12.4347L22.9513 11.2167H25V9H11V23H25V20.788H23.068L22.354 19.9527L21.612 20.788H16.124V16.3407H14.3133L16.5767 11.2167H18.7793L19.3113 12.3787V11.2167H22.046L22.5033 12.4347ZM20.9587 13.242L20.954 12.752L21.1407 13.242L22.0507 15.6733H22.956L23.8707 13.242L24.048 12.7567V15.6733H25V11.94H23.418L22.6993 13.8347L22.508 14.348L22.312 13.8347L21.5887 11.94H20.0067V15.6733H20.9587V13.242ZM18.91 15.6733H20.0067L18.364 11.94H17.09L15.438 15.6733H16.5207L16.81 14.9547H18.6207L18.91 15.6733ZM17.5287 13.2047L17.7153 12.738L17.902 13.2047L18.2893 14.1473H17.1413L17.5287 13.2047ZM16.8893 16.3453V20.0647H20.0067V19.2573H17.8413V18.6087H19.9647V17.806H17.8413V17.1527H20.0067V16.3453H16.8893V16.3453ZM23.474 20.0647H24.7107L22.9653 18.198L24.7107 16.3453H23.4927L22.368 17.554L21.248 16.3453H20.0067L21.7473 18.212L20.0067 20.0647H21.2107L22.3447 18.8513L23.474 20.0647ZM23.9453 18.1933L25 19.2667V17.134L23.9453 18.1933Z"
      fill="#006FCF"
    />
    <defs>
      <filter
        id="filter0_d_1276_951"
        x="0"
        y="4"
        width="36"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1276_951"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1276_951"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Discover: SVGComponent = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_1676_1020)">
      <rect x="2" y="5" width="32" height="22" rx="3" fill="#F6F7F8" />
      <rect
        x="2.165"
        y="5.165"
        width="31.67"
        height="21.67"
        rx="2.835"
        stroke="#E2E6E9"
        strokeWidth="0.33"
      />
    </g>
    <path
      d="M7.73438 10.18V21.8201C7.73438 22.0658 7.93424 22.2656 8.17988 22.2656H20.5386C23.9991 22.2656 26.8042 19.4605 26.8042 16C26.8042 12.5396 23.9991 9.73448 20.5386 9.73448H8.17988C7.93424 9.73448 7.73438 9.93425 7.73438 10.18Z"
      fill="white"
    />
    <path
      d="M9.86871 16.6636C9.69964 16.8162 9.4803 16.8828 9.13276 16.8828H8.98841V15.0594H9.13276C9.4803 15.0594 9.69104 15.1213 9.86871 15.2823C10.0548 15.4478 10.1666 15.7047 10.1666 15.9687C10.1666 16.2334 10.0548 16.4981 9.86871 16.6636ZM9.24057 14.592H8.45093V17.3495H9.23638C9.6538 17.3495 9.95538 17.2512 10.2202 17.0312C10.5348 16.7708 10.7207 16.3785 10.7207 15.9728C10.7207 15.1589 10.1127 14.592 9.24057 14.592Z"
      fill="#231F20"
    />
    <path d="M10.9683 17.3497H11.506V14.5922H10.9683V17.3497Z" fill="#231F20" />
    <path
      d="M12.8213 15.6502C12.4985 15.5308 12.4037 15.452 12.4037 15.3032C12.4037 15.1294 12.5727 14.9973 12.8044 14.9973C12.9656 14.9973 13.0979 15.0637 13.238 15.2207L13.5196 14.8523C13.2884 14.6498 13.0115 14.5465 12.7094 14.5465C12.2214 14.5465 11.8492 14.8853 11.8492 15.3366C11.8492 15.7166 12.0227 15.9111 12.5277 16.0928C12.7382 16.167 12.8453 16.2167 12.8995 16.2499C13.0068 16.3198 13.061 16.4194 13.061 16.5352C13.061 16.7587 12.8832 16.9242 12.6431 16.9242C12.3867 16.9242 12.18 16.7958 12.0561 16.5564L11.7086 16.8909C11.9566 17.2547 12.2542 17.4158 12.6633 17.4158C13.2225 17.4158 13.6145 17.0442 13.6145 16.5105C13.6145 16.0723 13.4332 15.8741 12.8213 15.6502Z"
      fill="#231F20"
    />
    <path
      d="M13.7841 15.9728C13.7841 16.7831 14.4205 17.4115 15.2396 17.4115C15.471 17.4115 15.6693 17.3661 15.9138 17.2512V16.6179C15.6987 16.833 15.5084 16.9199 15.2646 16.9199C14.7228 16.9199 14.3383 16.5272 14.3383 15.9688C14.3383 15.4393 14.7351 15.0219 15.2396 15.0219C15.496 15.0219 15.6905 15.1132 15.9138 15.3319V14.6991C15.6778 14.5794 15.4837 14.5297 15.2523 14.5297C14.4375 14.5297 13.7841 15.1708 13.7841 15.9728Z"
      fill="#231F20"
    />
    <path
      d="M20.1787 16.4442L19.4431 14.592H18.8557L20.0258 17.4205H20.3152L21.5065 14.592H20.9235"
      fill="#231F20"
    />
    <path
      d="M21.7496 17.3496H23.275V16.8827H22.2871V16.1384H23.2385V15.6714H22.2871V15.0595H23.275V14.5921H21.7496"
      fill="#231F20"
    />
    <path
      d="M24.3258 15.8616H24.1685V15.0265H24.334C24.6692 15.0265 24.8513 15.1669 24.8513 15.4352C24.8513 15.7123 24.6692 15.8616 24.3258 15.8616ZM25.405 15.4061C25.405 14.8899 25.049 14.5921 24.4289 14.5921H23.6312V17.3496H24.1685V16.2418H24.2389L24.9831 17.3496H25.6446L24.7763 16.1879C25.1818 16.1055 25.405 15.8284 25.405 15.4061Z"
      fill="#231F20"
    />
    <path
      d="M25.7312 14.7699H25.7212V14.7061H25.7317C25.7605 14.7061 25.7753 14.7167 25.7753 14.7375C25.7753 14.7589 25.7602 14.7699 25.7312 14.7699ZM25.8335 14.7367C25.8335 14.6881 25.8 14.6616 25.7412 14.6616H25.6633V14.9049H25.7212V14.8106L25.7893 14.9049H25.8602L25.7802 14.8046C25.8143 14.7952 25.8335 14.7703 25.8335 14.7367Z"
      fill="#231F20"
    />
    <path
      d="M25.7522 14.957C25.6593 14.957 25.5833 14.8797 25.5833 14.783C25.5833 14.6864 25.6587 14.6088 25.7522 14.6088C25.8449 14.6088 25.9199 14.6881 25.9199 14.783C25.9199 14.8788 25.8449 14.957 25.7522 14.957ZM25.7531 14.5707C25.635 14.5707 25.5412 14.665 25.5412 14.7828C25.5412 14.9004 25.6361 14.9948 25.7531 14.9948C25.868 14.9948 25.9624 14.8996 25.9624 14.7828C25.9624 14.6667 25.868 14.5707 25.7531 14.5707Z"
      fill="#231F20"
    />
    <path
      d="M19.0088 15.9678C19.0088 16.7742 18.3552 17.428 17.5486 17.428C16.742 17.428 16.0883 16.7742 16.0883 15.9678C16.0883 15.1613 16.742 14.5077 17.5486 14.5077C18.3552 14.5077 19.0088 15.1613 19.0088 15.9678Z"
      fill="#F47921"
    />
    <path
      d="M26.8043 16C26.8043 19.4605 23.9992 22.2656 20.5387 22.2656H8.17998C7.93425 22.2656 7.73447 22.0658 7.73447 21.8201V10.18C7.73447 9.93425 7.93425 9.73448 8.17998 9.73448H20.5387C23.9992 9.73448 26.8043 12.5396 26.8043 16ZM27.1344 9.51173H8.17998C7.81087 9.51173 7.51172 9.81087 7.51172 10.18V21.8201C7.51172 22.1892 7.81087 22.4884 8.17998 22.4884H27.1344C27.5035 22.4884 27.8027 22.1892 27.8027 21.8201V10.18C27.8027 9.81087 27.5035 9.51173 27.1344 9.51173Z"
      fill="#F47921"
    />
    <defs>
      <filter
        id="filter0_d_1676_1020"
        x="0"
        y="4"
        width="36"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1676_1020"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1676_1020"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const OtherCard: SVGComponent = (props) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_1704_1035)">
      <rect x="2" y="5" width="32" height="22" rx="3" fill="#F6F7F8" />
      <rect
        x="2.165"
        y="5.165"
        width="31.67"
        height="21.67"
        rx="2.835"
        stroke="#E2E6E9"
        strokeWidth="0.33"
      />
    </g>
    <path
      d="M11.25 14.5H25.25M14 18.5H16.5M22 18.5H22.5M11 12C11 10.8954 11.8954 10 13 10H23.5C24.6046 10 25.5 10.8954 25.5 12V20.5C25.5 21.6046 24.6046 22.5 23.5 22.5H13C11.8954 22.5 11 21.6046 11 20.5V12Z"
      stroke="#0B0C0D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_d_1704_1035"
        x="0"
        y="4"
        width="36"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1704_1035"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1704_1035"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const SmileSvg: SVGComponent = (props) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 41.8C32.9352 41.8 41.8 32.9352 41.8 22C41.8 11.0648 32.9352 2.2 22 2.2C11.0648 2.2 2.2 11.0648 2.2 22C2.2 32.9352 11.0648 41.8 22 41.8ZM22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
        fill="#C7CCD1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1333 16.5C16.9434 16.5 17.6 15.8434 17.6 15.0334C17.6 14.2233 16.9434 13.5667 16.1333 13.5667C15.3233 13.5667 14.6667 14.2233 14.6667 15.0334C14.6667 15.8434 15.3233 16.5 16.1333 16.5ZM16.1333 18.7C18.1584 18.7 19.8 17.0584 19.8 15.0334C19.8 13.0083 18.1584 11.3667 16.1333 11.3667C14.1083 11.3667 12.4667 13.0083 12.4667 15.0334C12.4667 17.0584 14.1083 18.7 16.1333 18.7Z"
        fill="#C7CCD1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 16.5C28.3101 16.5 28.9667 15.8434 28.9667 15.0334C28.9667 14.2233 28.3101 13.5667 27.5 13.5667C26.69 13.5667 26.0334 14.2233 26.0334 15.0334C26.0334 15.8434 26.69 16.5 27.5 16.5ZM27.5 18.7C29.5251 18.7 31.1667 17.0584 31.1667 15.0334C31.1667 13.0083 29.5251 11.3667 27.5 11.3667C25.475 11.3667 23.8334 13.0083 23.8334 15.0334C23.8334 17.0584 25.475 18.7 27.5 18.7Z"
        fill="#C7CCD1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 23.1C12.1 24.4001 12.3561 25.6874 12.8536 26.8885C13.3511 28.0897 14.0804 29.181 14.9997 30.1003C15.919 31.0196 17.0103 31.7489 18.2115 32.2464C19.4126 32.7439 20.6999 33 22 33C23.3001 33 24.5875 32.7439 25.7886 32.2464C26.9897 31.7489 28.0811 31.0196 29.0004 30.1003C29.9197 29.181 30.6489 28.0897 31.1464 26.8885C31.644 25.6874 31.9 24.4001 31.9 23.1L34.1 23.1C34.1 24.689 33.787 26.2624 33.179 27.7304C32.5709 29.1985 31.6796 30.5324 30.556 31.656C29.4324 32.7796 28.0985 33.6708 26.6305 34.2789C25.1625 34.887 23.589 35.2 22 35.2C20.411 35.2 18.8376 34.887 17.3696 34.2789C15.9015 33.6708 14.5676 32.7796 13.444 31.656C12.3204 30.5324 11.4292 29.1985 10.8211 27.7304C10.213 26.2624 9.90002 24.689 9.90002 23.1H12.1Z"
        fill="#C7CCD1"
      />
    </svg>
  );
};

export const XeroIcon: SVGComponent = (props) => {
  const { className, ...rest } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g>
        <g>
          <path
            d="M300.001 598.856C465.055 598.856 598.856 465.055 598.856 300C598.856 134.946 465.055 1.14389 300.001 1.14389C134.946 1.14389 1.14386 134.946 1.14386 300C1.14386 465.055 134.946 598.856 300.001 598.856Z"
            fill="#1AB4D7"
          />
          <path
            d="M144.202 299.136L195.204 248.008C196.895 246.285 197.838 244.003 197.838 241.583C197.838 236.54 193.739 232.445 188.696 232.445C186.239 232.445 183.936 233.407 182.2 235.165C182.195 235.176 131.243 286.097 131.243 286.097L80.059 235.089C78.3295 233.384 76.0377 232.445 73.6077 232.445C68.5707 232.445 64.4702 236.538 64.4702 241.576C64.4702 244.033 65.4513 246.339 67.2123 248.077L118.207 299.065L67.2388 350.129C65.4472 351.893 64.4702 354.205 64.4702 356.673C64.4702 361.715 68.5707 365.806 73.6077 365.806C76.0418 365.806 78.3356 364.861 80.059 363.137L131.164 312.101L182.075 362.951C183.88 364.817 186.21 365.812 188.696 365.812C193.734 365.812 197.833 361.715 197.833 356.673C197.833 354.24 196.891 351.954 195.176 350.231L144.202 299.136Z"
            fill="white"
          />
          <path
            d="M447.402 299.121C447.402 308.278 454.848 315.727 464.016 315.727C473.162 315.727 480.611 308.278 480.611 299.121C480.611 289.962 473.162 282.514 464.016 282.514C454.848 282.514 447.402 289.962 447.402 299.121Z"
            fill="white"
          />
          <path
            d="M415.907 299.129C415.907 272.612 437.479 251.037 463.998 251.037C490.505 251.037 512.082 272.612 512.082 299.129C512.082 325.64 490.505 347.206 463.998 347.206C437.479 347.206 415.907 325.64 415.907 299.129ZM396.989 299.129C396.989 336.072 427.05 366.128 463.998 366.128C500.946 366.128 531.022 336.072 531.022 299.129C531.022 262.181 500.946 232.117 463.998 232.117C427.05 232.117 396.989 262.181 396.989 299.129Z"
            fill="white"
          />
          <path
            d="M392.233 233.263L389.422 233.255C380.982 233.255 372.842 235.917 366.043 241.156C365.147 237.057 361.482 233.972 357.118 233.972C352.094 233.972 348.068 237.997 348.056 243.031C348.056 243.049 348.087 355.89 348.087 355.89C348.1 360.915 352.198 364.999 357.225 364.999C362.251 364.999 366.349 360.915 366.362 355.879C366.362 355.857 366.366 286.485 366.366 286.485C366.366 263.356 368.481 254.015 388.293 251.539C390.124 251.311 392.116 251.347 392.124 251.347C397.546 251.161 401.398 247.436 401.398 242.401C401.398 237.363 397.285 233.263 392.233 233.263Z"
            fill="white"
          />
          <path
            d="M216.768 288.097C216.768 287.847 216.788 287.587 216.8 287.33C222.105 266.357 241.099 250.838 263.72 250.838C286.614 250.838 305.782 266.739 310.816 288.097H216.768ZM329.529 286.372C325.591 267.728 315.384 252.414 299.841 242.578C277.121 228.154 247.118 228.952 225.173 244.558C207.272 257.293 196.939 278.125 196.939 299.585C196.939 304.965 197.587 310.397 198.938 315.743C205.697 342.333 228.555 362.462 255.808 365.798C263.896 366.777 271.767 366.308 279.919 364.197C286.923 362.493 293.701 359.652 299.946 355.65C306.427 351.484 311.843 345.989 317.087 339.412C317.193 339.291 317.3 339.184 317.405 339.057C321.045 334.543 320.37 328.123 316.37 325.058C312.997 322.473 307.333 321.424 302.877 327.131C301.919 328.496 300.851 329.9 299.674 331.304C296.131 335.22 291.734 339.013 286.467 341.956C279.763 345.537 272.124 347.583 264.003 347.629C237.424 347.333 223.2 328.78 218.141 315.536C217.257 313.064 216.576 310.498 216.101 307.857C216.039 307.363 215.993 306.886 215.975 306.447C221.471 306.447 311.359 306.431 311.359 306.431C324.435 306.157 331.473 296.927 329.529 286.372Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export * from './GeneratedIcon';
