import { useConfirmDialog } from '~/components/ConfirmDialog';
import { useKeyDown } from '~/hooks/useKeyDown';

export function useOnEscape(opts: {
  handler: (event: KeyboardEvent) => void;
  /**
   * @default true
   */
  enabled?: boolean;
}) {
  const { hasVisibleDialog } = useConfirmDialog();

  useKeyDown({
    key: 'Escape',
    // hasVisibleDialog suppresses any Escape event when it's visible.
    // See ConfirmDialog.tsx
    enabled: !hasVisibleDialog && (opts.enabled ?? true),
    handler: opts.handler,
  });
}
