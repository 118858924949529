'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import type { ElementRef } from 'react';
import { type ComponentPropsWithoutRef, Fragment, forwardRef } from 'react';
import { dialogOverlayClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { Button } from '~/modules/ui/primitives/button';
import { CloseIcon } from '~/modules/ui/primitives/icon';
import { ScrollArea } from '~/modules/ui/primitives/scroll-area';
import { HStack, VStack } from '~/modules/ui/primitives/stack';

export const Sheet = DialogPrimitive.Root;

export const SheetTrigger = DialogPrimitive.Trigger;

export const SheetClose = DialogPrimitive.Close;

export const SheetPortal = DialogPrimitive.Portal;

export const SheetOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(function SheetOverlayComponent({ className, ...props }, forwardedRef) {
  return (
    <DialogPrimitive.Overlay
      ref={forwardedRef}
      className={cn(dialogOverlayClassNames, className)}
      {...props}
    />
  );
});

interface SheetPanelProps
  extends ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  withPortal?: boolean;
  side?: 'right' | 'left';
}

export const SheetPanel = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  SheetPanelProps
>(function SheetPanelComponent(
  {
    className,
    children,
    side = 'right',
    onInteractOutside,
    withPortal = true,
    ...props
  },
  forwardedRef,
) {
  const Comp = withPortal ? DialogPrimitive.Portal : Fragment;

  return (
    <Comp>
      <SheetOverlay />
      <DialogPrimitive.Content
        ref={forwardedRef}
        className={cn(
          't2-rounded-3xl t2-z-50 t2-fixed t2-inset-y-1 t2-inset-x-1 t2-flex t2-flex-col t2-overflow-hidden t2-bg-surface-muted t2-transition t2-will-change-transform t2-zoom-in-100',
          'data-[state]:t2-duration-400 data-[state]:t2-ease-drawer data-[state=open]:t2-animate-in data-[state=closed]:t2-animate-out',
          'sm:t2-w-full sm:t2-max-w-xl',
          side === 'right'
            ? 'sm:t2-left-auto data-[state=closed]:t2-slide-out-to-right-full data-[state=open]:t2-slide-in-from-right-full'
            : 'sm:t2-right-auto data-[state=closed]:t2-slide-out-to-left-full data-[state=open]:t2-slide-in-from-left-full',
          className,
        )}
        onInteractOutside={(event) => {
          // Note: duplicated logic in dialog.tsx / sheet.tsx
          const { target } = event;
          if (
            target &&
            'tagName' in target &&
            target.tagName === 'COM-1PASSWORD-BUTTON'
          ) {
            // Prevent 1Password closing sheet.
            event.preventDefault();
          }
          if (
            target &&
            'closest' in target &&
            typeof target.closest === 'function' &&
            target.closest('[class^="pac"]')
          ) {
            // Prevent GoogleAutoComplete closing sheet.
            event.preventDefault();
          }

          onInteractOutside?.(event);
        }}
        {...props}
      >
        <ScrollArea className="t2-min-h-full t2-grow [&_[data-radix-scroll-area-viewport]>div:first-child]:t2-h-full">
          <VStack className="t2-min-h-full">{children}</VStack>
          <DialogPrimitive.Close asChild>
            <Button
              aria-label="Close sheet"
              className="t2-absolute t2-right-4 t2-top-4"
              intent="secondary"
            >
              <CloseIcon size="md" />
            </Button>
          </DialogPrimitive.Close>
        </ScrollArea>
      </DialogPrimitive.Content>
    </Comp>
  );
});

export function SheetContent({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      gap="8"
      className={cn(
        't2-w-full t2-max-w-xl t2-px-6 t2-pt-16 t2-pb-12 sm:t2-px-12 sm:t2-pt-24 sm:t2-pb-16 t2-grow',
        className,
      )}
      {...props}
    >
      {children}
    </VStack>
  );
}

export function SheetHeader({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack className={cn('t2-gap-y-2 md:t2-gap-y-3', className)} {...props} />
  );
}

export function SheetTitle({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof DialogPrimitive.Title>) {
  return (
    <DialogPrimitive.Title
      className={cn('t2-text-title', className)}
      {...props}
    />
  );
}

export function SheetDescription({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof DialogPrimitive.Description>) {
  return (
    <DialogPrimitive.Description
      className={cn('t2-text-body t2-text-foreground-secondary', className)}
      {...props}
    />
  );
}

export const SheetFooter = forwardRef<
  ElementRef<typeof HStack>,
  ComponentPropsWithoutRef<typeof HStack>
>(function SheetFooter(
  {
    className,
    alignItems = 'center',
    justifyContent = 'start',
    children,
    ...props
  }: ComponentPropsWithoutRef<typeof HStack>,
  ref,
) {
  return (
    <div className="t2-bg-surface-muted t2-sticky t2-inset-x-0 t2-bottom-0 t2-border-t t2-border-foreground/10">
      <HStack
        ref={ref}
        alignItems={alignItems}
        justifyContent={justifyContent}
        gap="3"
        className={cn(
          't2-max-w-xl t2-w-full t2-p-6 sm:t2-py-6 sm:t2-px-12',
          className,
        )}
        {...props}
      >
        {children}
      </HStack>
    </div>
  );
});
