import type { ReactNode } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Button } from '~/components/Button';
import type { FormProps } from '~/components/Form';
import { Form } from '~/components/Form';
import { ArrowLeftIcon, CloseIcon } from '~/components/GeneratedIcon';
import { HStack, VStack } from '~/components/containers/Stack';
import { Typography } from '~/components/next/foundation/Typography';
import { classNames } from '~/utils/style';

export type ViewProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  /**
   * Description will be wrapped in a <p> tag is it is a string
   * and in a <div> tag if it is a JSX.Element
   */
  description?: ReactNode;
  children?: ReactNode;
  /**
   * The children of the footer
   *
   * Layout is already taken care of
   */
  footer?: ReactNode;
  /**
   * Configure the vertical space taken by the view
   * @true will take all the available space
   * @false will take as little space as possible
   * @default false
   */
  shrink?: boolean;
  /**
   * Whether or not it should be restrained to a limited width
   * @default false
   */
  fullWidth?: boolean;
  horizontalPadding?: string;
  footerPadding?: string;
};

/**
 * Uniformize how we display various screen
 *
 * Used for wizard steps, dialogs, etc.
 *
 * Will generaly be used as a child of {@link View.Layout}
 *
 * See [UI Guidelines](https://www.notion.so/tolahq/UI-Guidelines-3f927b4ca7c841209f5d9231b4d4834f)
 */
export function View(props: ViewProps) {
  const {
    footer,
    shrink,
    fullWidth,
    horizontalPadding,
    footerPadding = 'py-6',
    ...rest
  } = props;
  return (
    <div
      className={classNames(
        'mx-auto w-full',
        horizontalPadding || 'px-6',
        !fullWidth && 'max-w-[448px]',
        !shrink && 'grid flex-1 grid-rows-[1fr_0fr]',
        !footer && 'pb-8',
      )}
    >
      <View.Body shrink={shrink} {...rest} />
      {footer && (
        <VStack
          gap="3"
          className={classNames(
            'w-full bg-white',
            !shrink && 'sticky bottom-0',
            footerPadding,
          )}
        >
          {footer}
        </VStack>
      )}
    </div>
  );
}

export type ViewBodyProps = Omit<ViewProps, 'footer'>;

View.Body = function ViewBody(props: ViewBodyProps) {
  const { title, subtitle, description, children, shrink } = props;
  return (
    <VStack
      gap="0"
      className={classNames(
        !shrink && 'relative min-w-0 flex-1',
        'max-w-[inherit]',
      )}
    >
      {[title, subtitle, description].some(Boolean) && (
        <VStack gap="4" className="overflow-hidden py-8">
          <div>
            {subtitle && (
              <Typography variant="title" as="h2" color="base-muted">
                {subtitle}
              </Typography>
            )}
            {title && (
              <Typography variant="title" as="h1">
                {title}
              </Typography>
            )}
          </div>

          {description && (
            <Typography variant="body" as="p" className="text-base-muted">
              {description}
            </Typography>
          )}
        </VStack>
      )}

      <div className={classNames(!shrink && 'flex-1')}>{children}</div>
    </VStack>
  );
};

// Form
export type FormViewProps<T extends FieldValues> = ViewProps &
  Omit<FormProps<T>, 'title'>;

/**
 * Form view
 *
 * To be used instead of {@link Form} and {@link View}
 */
View.Form = function FormView<T extends FieldValues>(props: FormViewProps<T>) {
  const {
    children,
    footer,
    title,
    subtitle,
    description,
    shrink,
    fullWidth,
    footerPadding,
    ...formProps
  } = props;
  const viewProps = {
    title,
    subtitle,
    description,
    footer,
    shrink,
    fullWidth,
    footerPadding,
  };

  return (
    <Form
      {...formProps}
      className={classNames('w-full', !shrink && 'flex flex-1')}
    >
      <View {...viewProps}>
        <VStack gap="6" className="h-full">
          {children}
        </VStack>
      </View>
    </Form>
  );
};

// ViewLayout

export interface ViewLayoutProps {
  header: ReactNode;
  children: ReactNode;
}

/**
 * Configure the layout of the {@link View} and {@link View.Header}
 *
 * Used as fullpage Dialogs and Wizard
 *
 * Its children should always be a {@link View}
 */
View.Layout = function ViewLayout(props: ViewLayoutProps) {
  const { header, children } = props;
  return (
    <VStack
      alignItems="center"
      className={classNames('relative h-screen flex-1')}
      gap="0"
    >
      {header && (
        <div className={classNames('sticky top-0 z-10 w-full')}>{header}</div>
      )}
      {children}
    </VStack>
  );
};

interface ViewHeaderProps {
  onBack?: () => void;
  onClose?: () => void;
  /**
   * @default true
   */
  showBack?: boolean;
}

/**
 * Utility container to create custom {@link View.Header}
 */
export function ViewHeaderContainer(props: { children: ReactNode }) {
  return (
    <HStack
      justifyContent="between"
      className="w-full bg-white px-6 py-4 md:py-6"
      {...props}
    />
  );
}

/**
 * Utility container to be used in the `header` property of {@link View.Layout}
 */
View.Header = function Header(props: ViewHeaderProps) {
  const { onBack, onClose, showBack = true } = props;
  return (
    <ViewHeaderContainer>
      <Button
        variant="subtle"
        EndIcon={ArrowLeftIcon}
        onClick={onBack}
        className={classNames(!showBack && 'pointer-events-none invisible')}
      />
      <Button
        variant="subtle"
        EndIcon={CloseIcon}
        onClick={onClose}
        className="ml-auto"
      />
    </ViewHeaderContainer>
  );
};
