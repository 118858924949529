import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useConfirmDialog } from '~/components/ConfirmDialog';
import { NoSSRSuspense } from '~/components/NoSSRSuspense';
import { useOnMount } from '~/hooks/useOnMount';
import { cx } from '~/utils/css-utils';

export interface AsideModalProps {
  children: React.ReactNode;
  show?: boolean;
  onClose: () => void;
  'data-test-id'?: string;
  className?: string;
}

export function AsideModal(props: AsideModalProps) {
  const {
    show = false,
    onClose,
    children,
    'data-test-id': dataTestId = 'aside-modal',
  } = props;
  const { hasVisibleDialog } = useConfirmDialog();

  // Don't render nested dialogs until the main dialog is mounted. The order in
  // which dialogs are rendered is managed by HeadlessUI, to make sure most
  // recent dialog is on top.
  const [mounted, setMounted] = useState(false);
  useOnMount(() => setMounted(true));

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        className={cx('relative z-dialog', props.className)}
        // Prevent close on click outside when there's a confirm dialog
        onClose={hasVisibleDialog ? () => null : onClose}
      >
        <Transition.Child
          className="fixed inset-0 opacity-0 backdrop-blur-[60px] backdrop-grayscale-50"
          enter="ease-drawer duration-400"
          enterTo="opacity-100 bg-fill-shim"
          leave="ease-drawer duration-400"
        />
        <div className="fixed inset-0 overflow-x-hidden overflow-y-scroll">
          <Transition.Child
            className="fixed top-0 right-0 bottom-0 w-screen max-w-full bg-primary transition will-change-transform md:max-w-2xl"
            enter="ease-drawer duration-400"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="ease-drawer duration-400"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel as="div" className="h-full" data-test-id={dataTestId}>
              <NoSSRSuspense>{mounted ? children : <></>}</NoSSRSuspense>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
