import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { SVGComponent } from '~/components/SVGComponent';
import { VStack } from '~/components/containers/Stack';
import { Body, Title } from '~/components/next/foundation/Typography';
import type { MarblesProps } from '~/components/screensavers/marbles/Marbles';
import { Marbles } from '~/components/screensavers/marbles/Marbles';
import { useOnMount } from '~/hooks/useOnMount';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import { cx } from '~/utils/css-utils';

export type EmptyStateProps = {
  title: string;
  subtitle?: ReactNode;
  children?: ReactNode;
  preset?: PresetType;
  className?: string;
  'data-test-id'?: string;
} & (
  | { decoration: ReactNode; Illustration?: never }
  | { Illustration: SVGComponent; decoration?: never }
  | { decoration?: never; Illustration?: never }
);

export type PresetType = 'draft' | 'scheduled' | 'paid' | 'financing' | 'face';

const presets: Record<PresetType, MarblesProps> = {
  draft: {
    sprites: ['/marbles/empty-states/draft.svg'],
    gravityX: 0.5,
  },
  scheduled: {
    sprites: ['/marbles/empty-states/scheduled.svg'],
    gravityX: -0.5,
    xx: 800,
  },
  paid: {
    sprites: ['/marbles/empty-states/paid.svg'],
    gravityX: 0.5,
  },
  face: {
    sprites: ['/marbles/empty-states/face.svg'],
    gravityX: 0,
    gravityY: 2,
    xx: 400,
  },
  financing: {
    sprites: ['/marbles/empty-states/financing.svg'],
    gravityX: 0,
    columns: 3,
  },
};

export function EmptyState(props: EmptyStateProps) {
  const [isMounted, setIsMounted] = useState(false);
  const emptyStateRef = useRef<HTMLDivElement>(null);

  const {
    title,
    subtitle,
    children,
    preset,
    Illustration,
    decoration,
  }: typeof props = props;

  const domElement = emptyStateRef.current as HTMLElement;

  useOnMount(() => {
    setIsMounted(true);
  });

  const publicConfig = usePublicConfig();
  const isTest = publicConfig.APP_ENV === 'test';
  return (
    <div
      className={cx(
        '-ml-gutter-left -mr-gutter-right relative grid flex-1 place-items-center pb-[140px]',
        props.className,
      )}
    >
      {/* waiting for EmptyState component to be mounted, so the element interacts with the Marbles (via domBounds) */}
      {isMounted && preset && !isTest && (
        <Marbles
          key={preset}
          scaleFactor={0.7}
          yy={800}
          {...presets[preset]}
          enableMouseEvents={false}
          domBounds={[domElement]}
        />
      )}

      <VStack
        gap="2"
        alignItems="center"
        justifyContent="center"
        className="pointer-events-none relative h-full w-full flex-1 pt-3 pb-4 text-gray-500"
        data-test-id={props['data-test-id']}
      >
        {Illustration && <Illustration className="h-48 w-48" />}

        {decoration && <div>{decoration}</div>}

        <div
          className="inline-flex max-w-[400px] justify-start"
          ref={emptyStateRef}
        >
          <VStack
            gap="8"
            alignItems="center"
            className="pointer-events-auto inline-flex"
          >
            <VStack
              gap="4"
              justifyContent="center"
              className="w-full text-center"
            >
              {title && <Title className="text-balance">{title}</Title>}
              {subtitle && <Body className="text-balance">{subtitle}</Body>}
            </VStack>
            {children}
          </VStack>
        </div>
      </VStack>
    </div>
  );
}
