import type { ToastOpts } from '~/components/Toast';
import { showToast } from '~/components/Toast';
import { isTRPCClientError } from '~/server-shared/trpc';
import { getErrorFromUnknown } from '~/utils/utility';

export const toastErrorHandler: (error: unknown) => void = (error) => {
  const err = getErrorFromUnknown(error);
  let toastOpts: ToastOpts;
  if (err instanceof FormError) {
    toastOpts = err.toastOpts;
  } else if (
    isTRPCClientError(err) &&
    err.data?.httpStatus &&
    // 4xx
    err.data.httpStatus >= 400 &&
    err.data.httpStatus < 500
  ) {
    toastOpts = {
      variant: 'danger',
      title: 'Invalid input',
      message: err.message,
    };
  } else {
    toastOpts = {
      variant: 'danger',
      title: 'Something went wrong',
      message: err.message,
    };
  }
  showToast(toastOpts);
};

/**
 * @deprecated use `FormError` from `~/modules/ui/form-error` instead
 */
export class FormError extends Error {
  public readonly toastOpts: ToastOpts;
  public constructor(toastOpts: ToastOpts) {
    super(toastOpts.title);
    this.toastOpts = {
      variant: 'danger',
      ...toastOpts,
    };

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
