import gsap from 'gsap';
import type { MouseEvent } from 'react';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useMarbles } from './useMarbles';

// https://brm.io/matter-js/docs/classes/Composites.html
export interface MarblesProps {
  sprites?: string[];
  svgBoundingBox?: number;
  scaleFactor?: number;
  widthBase?: number;
  xx?: number;
  yy?: number;
  columns?: number;
  rows?: number;
  columnGap?: number;
  rowGap?: number;
  density?: number;
  frictionAir?: number;
  restitution?: number;
  friction?: number;
  enableMouseEvents?: boolean;
  enableMouseConstraint?: boolean;
  gravityX?: number;
  gravityY?: number;
  domBounds?: HTMLElement[];
}

const defaultProps: MarblesProps = {
  sprites: [
    '/marbles/t.svg',
    '/marbles/o.svg',
    '/marbles/l.svg',
    '/marbles/a.svg',
  ],
  svgBoundingBox: 144,
  /** scale factor of balls */
  scaleFactor: 1.75,
  xx: 0,
  yy: 0,
  columns: 5,
  rows: 3,
  columnGap: 0,
  rowGap: 0,
  density: 0.00005,
  frictionAir: 0.01,
  restitution: 0.3,
  friction: 0.09,
  enableMouseEvents: true,
  enableMouseConstraint: true,
  gravityX: 1,
  gravityY: 1,
};

function Marbles(props: MarblesProps) {
  const mergedProps = { ...defaultProps, ...props };

  const { marbles, containerRef } = useMarbles(mergedProps);

  const debouncedResize = useDebouncedCallback((entries) => {
    const [entry] = entries;

    if (!entry || !marbles) {
      return;
    }

    const { width, height } = entry.target.getBoundingClientRect();
    marbles.resize({
      width,
      height,
      scaleFactor: mergedProps.scaleFactor ?? 1,
    });
  }, 0);

  const onMousemove = (e: MouseEvent<HTMLDivElement>) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - bounds.left) / bounds.width;
    const y = (e.clientY - bounds.top) / bounds.height;

    // convert range from [0, 1] to [-1, 1]
    const mappedX = x * 2 - 1;
    const mappedY = y * 2 - 1;

    marbles?.setGravity({ x: mappedX, y: mappedY });
  };

  const onMouseleave = () => {
    marbles?.setGravity({ x: 0, y: 0.5 });
  };

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    gsap.fromTo(
      containerRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 0.25 },
    );

    const observer = new ResizeObserver(debouncedResize);
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [containerRef, debouncedResize]);

  return (
    <div
      data-test-screenshot-mask
      ref={containerRef}
      onMouseMove={mergedProps.enableMouseEvents ? onMousemove : undefined}
      onMouseLeave={mergedProps.enableMouseEvents ? onMouseleave : undefined}
      className="absolute inset-0"
    ></div>
  );
}

export { Marbles };
