import { Combobox as HeadlessUICombobox, Transition } from '@headlessui/react';
import type { Temporal } from '@js-temporal/polyfill';
import type { ComponentPropsWithoutRef } from 'react';
import React, { Fragment, useState } from 'react';
import type {
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import {
  FormProvider,
  get,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { CheckmarkIcon, ChevronDownIcon } from '~/components/Icon';
import { toastErrorHandler } from '~/components/ToastErrorHandler';
import type { InputControlProps } from '~/components/form/InputControl';
import { useDialogState } from '~/hooks/useDialogState';
import type { AnyUseZodFormReturn, UseZodFormReturn } from '~/hooks/useZodForm';
import { createLogger } from '~/utils/logger';
import { isObject } from '~/utils/object';
import { classNames } from '~/utils/style';
import type { Maybe } from '~/utils/utility';
import { isString } from '~/utils/utility';
import type { ButtonProps } from './Button';
import { Button } from './Button';
import type { SVGComponent } from './SVGComponent';

const logger = createLogger('Form');

export type GetSubmitHandler<TForm> = TForm extends UseFormReturn<infer T>
  ? SubmitHandler<T>
  : never;

export const SubmitButton = (
  props: Omit<Extract<ButtonProps, { type: 'submit' }>, 'type' | 'form'> & {
    /**
     * If you are using `SubmitButton` outside of a `Form`, you must provide the result from `useZodForm()` here
     */
    form?: AnyUseZodFormReturn;
  },
) => {
  const context = useFormContext();
  const { form, ...passThroughProps }: typeof props = props;
  const formState = form?.formState ?? context?.formState;
  if (!formState) {
    throw new Error(
      'SubmitButton must be used within a Form or be given a form prop',
    );
  }

  const loading = props.loading || formState.isSubmitting;
  const disabled = props.disabled || loading;
  const error = props.error || !formState.isValid;

  return (
    <Button
      {...({
        form: form?.uniqueId,
        ...passThroughProps,
      } as ButtonProps)}
      type="submit"
      disabled={disabled}
      loading={loading}
      error={error}
    />
  );
};

export type LabelProps =
  | {
      /**
       * @deprecated use `children`
       */
      label: string;
      htmlFor: string;
      children?: never;
      className?: never;
      variant?: never;
    }
  | (ComponentPropsWithoutRef<'label'> & {
      label?: never;
      /**
       * @default 'default'
       */
      variant?: 'subtitle' | 'default';
    });

export const Label = (props: LabelProps) => {
  const { label, variant = 'default', ...rest } = props;

  const variantClassName: Record<typeof variant, string> = {
    subtitle: 'text-caption font-500 pb-2 text-gray-900 block',
    default: 'font-base pb-1 block text-footnote text-gray-500 block',
  };

  return (
    <label
      {...rest}
      className={classNames(variantClassName[variant], props.className)}
    >
      {props.children || label}
    </label>
  );
};
export function LabelOptional() {
  return <span className="font-400">(Optional)</span>;
}

export interface InputHtmlAttributesWithName<T>
  extends React.InputHTMLAttributes<T> {
  /**
   * `name` is required
   */
  name: string;
}

export interface TextareaHTMLAttributesWithName<T>
  extends React.TextareaHTMLAttributes<T> {
  /**
   * `name` is required
   */
  name: string;
}

/**
 * Get the default value of a field
 */
export function useDefaultValue(props: { name: string }) {
  const defaultValues = useFormContext()?.control?._defaultValues;

  const value = get(defaultValues, props.name);

  return value;
}
/**
 * Get the current error of a field
 */
export function useFieldError(props: { name: string }) {
  // DX: give better error message when used outside a form
  const context = useFormContext();
  if (!context) {
    throw new Error('useFieldError must be used within a Form');
  }

  const formState = useFormState({
    name: props.name,
  });

  type ErrorType = {
    message: string;
    type: string;
  };
  const error: Maybe<ErrorType> | Record<string, ErrorType> = get(
    formState.errors,
    props.name,
  );

  if (!error) {
    return error;
  }
  if (!error.message) {
    // if you do e.g. `useFieldError('attachment')` where attachment is a nested object `{ id: string; originalName: string; } }`
    // we'll propagate the first error message we find
    for (const obj of Object.values(error as Record<string, ErrorType>)) {
      if (obj.message) {
        return obj;
      }
    }
    // if we don't find any error messages, we'll return a fallback
    const fallback: ErrorType = {
      message: 'Something is wrong here',
      type: 'unknown',
    };
    return fallback;
  }

  return error as ErrorType;
}

export const InputHelperText = (props: JSX.IntrinsicElements['div']) => {
  return (
    <div
      {...props}
      className={classNames(
        `mt-1 text-footnote text-gray-500`,
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

type SuccessHandlerOpts<TInput, TOutput> = {
  input: TInput;
  output: TOutput | undefined;
};

/**
 * A function that handles a successful form submission
 */
type SuccessHandler<TInput> = (
  opts: SuccessHandlerOpts<TInput, unknown>,
) => void;

/**
 * A function that handles a failed form submission
 */
type ErrorHandler = (e: unknown) => void;

export type FormProps<T extends FieldValues> = Omit<
  JSX.IntrinsicElements['form'],
  'onSubmit'
> & {
  /**
   * The form's context, usually the result of `useZodForm()`
   */
  form: UseZodFormReturn<T>;
  /**
   * Async function that runs before the form is submitted.
   * This is useful for things like confirming the user's action.
   * The reason this exists is so that we don't show the loading state of the form until the user confirms.
   */
  confirmSubmit?: () => Promise<boolean> | boolean;
  /**
   * Submit handler for this Form. This is required.
   *
   * **Please make this `async`** (use `mutateAsync()`) so that:
   * - The form can handle errors automatically and show toasts on failures
   * - The `<SubmitButton />` component will show loading, error, and success states.
   * - To be able to use `useForm()` to see if we're submitting the form.
   */
  handleSubmit: SubmitHandler<T>;
  /**
   * Add a custom success handler for this Form.
   * By default, no success handler will be shown.
   * @default undefined
   */
  onSuccess?: SuccessHandler<T>;
  /**
   * Add a custom error handler for this Form.
   * If not provided, the default error handler will be used which shows a Toast with the error's message.
   * @default toastErrorHandler
   */
  onError?: ErrorHandler;

  autocomplete?: 'on' | 'off';
};

/**
 * Tries to return a message from an error cause.
 */
const getMessageFromCause = (cause: unknown): string => {
  if (cause instanceof Error) {
    // 99% case, but some libraries throw non-Error objects
    return cause.message;
  }
  if (isObject(cause)) {
    return isString(cause.message)
      ? // object is "ErrorLike"
        cause.message
      : JSON.stringify(cause);
  }
  return String(cause);
};

export const Form = <T extends FieldValues>(props: FormProps<T>) => {
  const {
    form,
    handleSubmit,
    onSuccess,
    onError = toastErrorHandler,
    confirmSubmit,
    ...formProps
  }: typeof props = props;

  return (
    <FormProvider {...form}>
      <form
        // We have a unique id for the `<form>` so we can place submit button outside of the form
        id={props.form.uniqueId}
        onSubmit={(event) => {
          // prevent bubbling in case of modals with multiple forms
          event.stopPropagation();
          // prevent default form submission
          event.preventDefault();

          (async () => {
            // trigger form's validation, then confirm submit
            if (confirmSubmit) {
              // trigger validation before confirming submit
              const isValid = await form.trigger();
              if (isValid && !(await confirmSubmit())) {
                // user cancelled submit
                return;
              }
            }

            await form.handleSubmit(async (...args) => {
              try {
                const [input] = args;
                const output = await handleSubmit(...args);
                onSuccess?.({ input, output });
              } catch (cause) {
                // `handleSubmit()`'s callback may not reject, otherwise it gets stuck in loading state

                // https://react-hook-form.com/api/useform/seterror/
                form.setError('root.server', {
                  message: getMessageFromCause(cause),
                  type: 'server',
                });
                onError(cause);
              }
            })(event);
          })().catch((error) => {
            // Should never happen, but just in case
            logger.error('Error in form submission', { error }, error);
          });
        }}
        {...formProps}
      >
        {props.children}
      </form>
    </FormProvider>
  );
};

/**
 * Create a date `YYYY-MM-DD` string from a `Date`
 */
export function dateToPlainDateString(date: Date | Temporal.PlainDate) {
  return date.toJSON().slice(0, 10);
}

export interface ComboboxOptionProps {
  id: string;
  name: string;
  description?: string;
  StartIcon?: SVGComponent;
  disabled?: boolean;
}
export interface ComboboxProps
  extends React.DetailedHTMLProps<
    InputHtmlAttributesWithName<HTMLInputElement> & {
      /**
       * Callback when the user selects an option
       */
      onPick?: (value: { id: string }) => void;
    },
    HTMLInputElement
  > {
  /**
   * Options that will be autocompleted when typing
   */
  options: ComboboxOptionProps[];
  /**
   * An option at the end that isn't affected by the typing
   */
  endOption?: ComboboxOptionProps;

  variant?: InputControlProps['variant'];
}
export const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>(
  function Combobox(props, ref) {
    const form = useFormContext();
    const state = useDialogState();
    const {
      options,
      endOption,
      // Omitting `onChange` and `onBlur`

      onChange: _onChange,
      onBlur: _onBlur,
      onPick,
      ...passThrough
    } = props;

    const error = useFieldError(props);
    const defaultValue = useDefaultValue(props);

    const combinedOptions = options.concat(endOption || []);

    const selectedValue: string = form.watch(props.name);
    const selectedOption = combinedOptions.find(
      (it) => it.id === selectedValue,
    );

    const [currentText, setCurrentText] = useState(
      () => combinedOptions.find((it) => it.id === defaultValue)?.name ?? '',
    );

    const filteredOptions =
      // Show all options if there are no typed characters
      !currentText.trim() ||
      // Show all options if the current text matches the current option
      currentText === selectedOption?.name
        ? options
        : options.filter((it) =>
            it.name.toLowerCase().includes(currentText.toLowerCase().trim()),
          );

    return (
      <>
        <HeadlessUICombobox
          value={selectedValue || ''}
          onChange={(value) => {
            form.setValue(props.name, value, {
              shouldDirty: true,
              shouldValidate: true,
            });
            setCurrentText(
              combinedOptions.find((it) => it.id === value)?.name ?? '',
            );
            onPick?.({ id: value });
            setTimeout(() => state.close());
          }}
          disabled={props.disabled}
        >
          <div
            className={classNames(
              'relative',
              props.variant !== 'condensed' && 'mt-1',
            )}
          >
            <div
              className={classNames(
                'relative w-full cursor-default rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-caption',
              )}
            >
              <HeadlessUICombobox.Input
                {...passThrough}
                name={props.name}
                className={classNames(
                  'input px-2 pr-7',
                  props.variant === 'condensed' ? 'h-8' : 'h-11',
                  error && 'input--error',
                  selectedOption?.StartIcon && 'pl-9',
                  props.className,
                )}
                displayValue={() => selectedOption?.name || ''}
                autoComplete="off"
                onChange={(event) => {
                  setCurrentText(event.target.value);
                }}
                onFocus={() => state.open()}
                onBlur={() => state.close()}
                ref={ref}
                type="search"
              />

              {selectedOption?.StartIcon && (
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <selectedOption.StartIcon className={classNames('h-5 w-5')} />
                </div>
              )}
              <HeadlessUICombobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                data-test-id="combobox-open"
                type="button"
              >
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </HeadlessUICombobox.Button>
            </div>
            <Transition
              show={state.show}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessUICombobox.Options
                static
                className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-body shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-caption"
              >
                {filteredOptions.length === 0 && !selectedValue ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((it) => (
                    <ComboboxOption key={it.id} {...it} />
                  ))
                )}
                {endOption && (
                  <>
                    <hr />

                    <ComboboxOption {...endOption} />
                  </>
                )}
              </HeadlessUICombobox.Options>
            </Transition>
          </div>
        </HeadlessUICombobox>

        {error && (
          <InputHelperText className="text-red-600">
            {error.message}
          </InputHelperText>
        )}
      </>
    );
  },
);

function ComboboxOption(props: ComboboxOptionProps) {
  const disabled = props.disabled ?? false;
  return (
    <HeadlessUICombobox.Option
      className={({ active }) =>
        classNames(
          `relative cursor-default select-none px-2 py-2`,
          active && 'bg-blue-600 text-white',
          !active && disabled && 'cursor-not-allowed text-gray-300',
          !active && !disabled && 'text-gray-900',
        )
      }
      disabled={disabled}
      value={props.id}
      data-test-id="combobox-option"
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center space-x-2">
            {props.StartIcon && (
              <props.StartIcon className={classNames('h-6 w-6 self-start')} />
            )}
            <div>
              <div
                className={classNames(`block truncate`, selected && 'font-500')}
              >
                {props.name}
              </div>
              {props.description && (
                <span
                  className={classNames(
                    'text-xs',
                    active && 'text-white',
                    !active && disabled && 'text-gray-300',
                    !active && !disabled && 'text-gray-500',
                  )}
                >
                  {props.description}
                </span>
              )}
            </div>
          </div>
          {selected ? (
            <span
              className={classNames(
                `absolute inset-y-0 right-3 flex items-center`,
                active && 'text-white',
                !active && 'text-blue-600',
              )}
            >
              <CheckmarkIcon className="h-5 w-5" />
            </span>
          ) : null}
        </>
      )}
    </HeadlessUICombobox.Option>
  );
}
