import { useRouter } from 'next/router';
import { ComponentPropsWithoutRef } from 'react';
import { z } from 'zod';
import { ArrowRightIcon } from '~/components/Icon';
import { testingApi } from '~/generated/trpc/testing';
import { viewerApi } from '~/generated/trpc/viewer';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import { useZodForm } from '~/hooks/useZodForm';
import { cn } from '~/modules/ui/cva';
import { InputField } from '~/modules/ui/fields/input-field';
import { Button, SubmitButton } from '~/modules/ui/primitives/button';
import { Form } from '~/modules/ui/primitives/form';
import { RefreshIcon } from '~/modules/ui/primitives/icon';
import { HStack } from '~/modules/ui/primitives/stack';

import { first } from '~/utils/first';
import { requiredEmailSchema } from '~/utils/validation';

interface LoginProps extends ComponentPropsWithoutRef<typeof HStack> {
  email?: string;
}

export function UnsafeDevLogin({ email, className, ...props }: LoginProps) {
  const router = useRouter();
  const usernames = [
    'testuser@tolahq.com',
    'testuser2@tolahq.com',
    'testuser3@tolahq.com',
    'testuser4@tolahq.com',
  ];
  const form = useZodForm({
    defaultValues: {
      email: email || usernames[0],
    },
    schema: z.object({
      email: requiredEmailSchema,
    }),
  });

  const loginMutation = testingApi.public.login.useMutation();

  return (
    <HStack
      className={cn(
        't2-bg-surface-muted t2-shadow-dialog t2-rounded-3xl t2-p-2',
        className,
      )}
      {...props}
    >
      <Form
        form={form}
        action="unsafe-dev"
        onSubmit={async (data) => {
          await loginMutation.mutateAsync(data);

          window.location.href = first(router.query.callbackUrl) || '/org';
          await new Promise(() => {
            // Never resolve loading state as we're doing a window redirect
          });
        }}
      >
        <HStack gap="2" alignItems="start">
          <InputField name="email" className="t2-rounded-full" />
          <Button
            intent="secondary"
            onClick={() =>
              form.setValue(
                'email',
                usernames[usernames.indexOf(form.getValues('email')) + 1] ||
                  usernames[0] ||
                  '',
              )
            }
          >
            <RefreshIcon />
          </Button>
          <SubmitButton data-test-id="developer-login-button">
            <ArrowRightIcon />
          </SubmitButton>
        </HStack>
      </Form>
    </HStack>
  );
}

export const DeveloperLogin = () => {
  const publicConfig = usePublicConfig();
  if (publicConfig.APP_ENV === 'production') {
    return null;
  }

  return (
    <UnsafeDevLogin
      className="t2-fixed t2-top-4 t2-right-4 t2-z-[100]"
      data-test-screenshot-hide
    />
  );
};

function UnsafeMFASkip({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  const mfaSubmit = viewerApi.mfa.verify.useMutation();

  return (
    <HStack
      className={cn(
        't2-bg-surface-muted t2-shadow-dialog t2-rounded-3xl t2-p-2',
        className,
      )}
      {...props}
    >
      <Button
        disabled={mfaSubmit.isPending}
        onClick={() => {
          if (mfaSubmit.isPending) {
            return;
          }
          mfaSubmit.mutate({ code: '000001' });
        }}
      >
        <span>Skip MFA</span>
        <ArrowRightIcon />
      </Button>
    </HStack>
  );
}

export const DeveloperMfaVerify = () => {
  const publicConfig = usePublicConfig();

  if (publicConfig.APP_ENV === 'production') {
    return null;
  }

  return (
    <UnsafeMFASkip
      className="t2-fixed t2-top-4 t2-right-4 t2-z-[100]"
      data-test-screenshot-hide
    />
  );
};
