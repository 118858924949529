import { Menu, Transition } from '@headlessui/react';
import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import type { ButtonProps } from '~/components/Button';
import { Button } from '~/components/Button';
import { classNames } from '~/utils/style';

type OpenSide = 'left' | 'right' | 'manual';
type OpenDirection = 'up' | 'down';

export type DropdownProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode;
  button: ReactNode;
  /**
   * @default left
   */
  openSide?: OpenSide;
  /**
   * @default down
   */
  openDirection?: OpenDirection;
  itemsClassName?: string;
  containerClassName?: string;
  /**
   * @default true
   */
  relative?: boolean;
  /**
   * @default true
   */
  pad?: boolean;
};
export function Dropdown({
  children,
  button,
  openSide = 'left',
  openDirection = 'down',
  className,
  itemsClassName,
  relative = true,
  pad = true,
  ...rest
}: DropdownProps) {
  // If we want it to open to the left, we need to anchor the items to the right.
  const openSideClassName =
    openSide === 'right'
      ? 'left-0 origin-top-left'
      : 'right-0 origin-top-right';

  return (
    <Menu
      as="div"
      className={classNames(
        'z-20 inline-block bg-white transition-all',
        'ring-2 ring-transparent ring-offset-2 transition focus-visible:ring-gray-300',
        relative && 'relative',
        className,
      )}
      {...rest}
    >
      {button}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            openSide === 'manual' ? '' : openSideClassName,
            openDirection === 'up'
              ? '-top-2 -translate-y-full'
              : 'top-full translate-y-3',
            'absolute z-10 flex flex-col gap-1 whitespace-nowrap rounded-md border-black/10 border-hairline bg-white p-3 shadow-md',
            pad && 'py-2',
            itemsClassName,
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

Dropdown.Button = function DropdownButton(
  props: Omit<ButtonProps, 'onClick' | 'type'> & {
    as?: typeof Button | 'button';
  },
) {
  const { children, as = Button, className, ...rest } = props;
  return (
    <Menu.Button
      as={as}
      className={classNames('hover:bg-opacity-70', className)}
      {...rest}
    >
      {children}
    </Menu.Button>
  );
};

export type DropdownItemProps = {
  disabled?: boolean;
  pad?: boolean;
} & Partial<LinkProps> &
  Omit<JSX.IntrinsicElements['a'], 'href'>;

Dropdown.Item = function DropdownItem(
  props: DropdownItemProps & { children: ReactNode },
) {
  const {
    disabled = false,
    pad = false,
    href,
    className,
    // We don't want to pass `ref`
    ref: _ref,
    shallow,
    ...rest
  } = props;

  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => {
        const menuItemClassName = classNames(
          'grid h-8 w-full grid-cols-[min-content_1fr] items-center gap-2 rounded-md px-1 text-left text-body hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-100',
          pad && 'px-4 py-3',
          disabled && 'text-gray-400 hover:cursor-not-allowed',
          !disabled && 'text-gray-500 hover:cursor-pointer',
          !disabled && active && 'bg-gray-100',
          className,
        );

        return href ? (
          <Link
            href={href}
            shallow={shallow}
            {...rest}
            className={menuItemClassName}
          />
        ) : (
          <a {...rest} className={menuItemClassName} />
        );
      }}
    </Menu.Item>
  );
};
