import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import { DefaultError } from '~/components/DefaultError';

interface Props {
  children: ReactNode;
  renderError?: (error: Error) => ReactNode;
}

interface State {
  error: Error | null;
}

/**
 * This is a component that catches errors anywhere in its child component tree
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      error,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.error) {
      return this.props.renderError ? (
        this.props.renderError(this.state.error)
      ) : (
        <DefaultError error={this.state.error} />
      );
    }

    return this.props.children;
  }
}
