import type { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import type { createOrgRouter } from '~/server/routers/org';
import { trpcBase } from '~/utils/trpc.base';

type OrgRouter = ReturnType<typeof createOrgRouter>;

/**
 * These are only relevant on the root "trpc."-object
 **/
type IgnoredTypes =
  | 'Provider'
  | 'createClient'
  | 'useDehydratedState'
  | 'useContext'
  | 'useUtils';

/**
 * Alex could fix these inside tRPC if we want to use them.
 **/
type TODO_TYPES = 'useQueries' | 'useSuspenseQueries';

type OrgTypes = ReturnType<
  typeof createTRPCReact<OrgRouter>
>;

type OrgApi = Omit<OrgTypes, IgnoredTypes | TODO_TYPES>;

// biome-ignore lint/suspicious/noExplicitAny: generated
export const orgApi = (trpcBase as any)
  .org as OrgApi;

export type OrgInputs = inferRouterInputs<OrgRouter>;
export type OrgOutputs = inferRouterOutputs<OrgRouter>;

export const useOrgUtils = (): Omit<
  ReturnType<
    
    OrgTypes['useUtils']
  >,
  'client'
> => {
  // biome-ignore lint/suspicious/noExplicitAny: generated
  return (trpcBase as any).useUtils().org;
};
