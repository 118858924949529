import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import type { DefaultValues, FieldValues } from 'react-hook-form';
import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import type { CRC } from '~/components/CRC';
import type {
  WizardTransition,
  WizardTransitionType,
} from '~/components/WizardTransition';
import { WizardTransitions } from '~/components/WizardTransition';
import type { FormViewProps, ViewProps } from '~/components/layout/view/View';
import { View } from '~/components/layout/view/View';
import { usePromptOnNavigation } from '~/hooks/usePromptOnNavigation';
import { createCtx } from '~/utils/context';

type InitialState<TFormState extends Record<string, z.infer<z.ZodType>>> = {
  [P in keyof TFormState]?: DefaultValues<TFormState[P]['_input']> | undefined;
};

interface WizardProps<
  TStep extends string,
  TFormState extends Record<TStep, z.infer<z.ZodType>>,
> {
  steps: Record<TStep, ReactNode>;
  Layout?: CRC;
  /**
   * @default true
   */
  closeable?: boolean;
  onClose?: () => void;
  /** Overide createWizard start step */
  start?: TStep;
  initialState?: InitialState<TFormState>;
  /**
   * Control the history from outside the wizard
   * If you pass `undefined` the wizard will not update its history
   */
  history?: TStep[];
}

type CreateWizardOpts<
  TStep extends string,
  TFormState extends Record<TStep, z.ZodType>,
> = {
  start: TStep;
  end: TStep | TStep[];
  steps: TStep[];
  schema: Partial<TFormState>;
  noPromptOnNavigation?: TStep[];
};

type WizardContext<
  TStep extends string,
  TFormState extends Record<TStep, z.ZodType>,
> = Simplify<
  {
    /**
     * Return the wizard to its start step and clear formState
     */
    reset: () => void;
    /**
     * Override the default closing behavior
     */
    onClose?: () => void;
    formState: TFormState;
    setFormState: Dispatch<SetStateAction<TFormState>>;
    setStepState: (
      step: keyof TFormState,
      state: TFormState[keyof TFormState]['_input'],
    ) => void;
    getStepState: <TCurrentStep extends keyof TFormState>(
      step: TCurrentStep,
    ) => TFormState[TCurrentStep]['_input'] | undefined;
    /**
     * Get step state, throw if state is not available in `formState`
     */
    getCompletedStepState: <TCurrentStep extends keyof TFormState>(
      step: TCurrentStep,
    ) => TFormState[TCurrentStep]['_input'];
  } & UseWizardReturn<TStep>
>;

export function createWizard<
  TStep extends string,
  TFormState extends Record<string, z.ZodType>,
>(opts: CreateWizardOpts<TStep, TFormState>) {
  const [useContext, ContextProvider] =
    createCtx<WizardContext<TStep, TFormState>>();

  function useWizard(hookOpts?: {
    start?: TStep;
    history?: TStep[];
  }): UseWizardReturn<TStep> {
    const transitionTypeRef = useRef<WizardTransitionType>('forward');
    const transitionType = transitionTypeRef.current;
    const start = hookOpts?.start ?? opts.start;
    const [history, setHistory] = useState<TStep[]>(
      hookOpts?.history?.length ? hookOpts.history : [start],
    );

    useEffect(() => {
      // update history if it changes from outside the wizard

      setHistory((currentHistory) => {
        if (!hookOpts?.history) {
          // do not update history if it is not passed
          return currentHistory;
        }
        const newHistory =
          hookOpts.history.length === 0 ? [start] : hookOpts.history;
        if (
          currentHistory.length === newHistory.length &&
          currentHistory.every((step, i) => {
            return step === newHistory[i];
          })
        ) {
          // do not update history if it is the same as current
          return currentHistory;
        }

        const determineTransitionType = () => {
          if (newHistory.length === currentHistory.length) {
            return 'none';
          }
          if (newHistory.length > currentHistory.length) {
            return 'forward';
          }
          return 'backward';
        };
        // update transition type based on history change
        transitionTypeRef.current = determineTransitionType();

        return newHistory;
      });
    }, [hookOpts?.history, start]);

    const selected = history.at(-1) ?? start;

    const back = () => {
      if (history.length < 2) {
        return;
      }
      transitionTypeRef.current = 'backward';
      setHistory((stepList) => {
        const newHistory = [...stepList];
        newHistory.pop();
        return newHistory;
      });
    };

    const push = (step: TStep, type?: WizardTransitionType) => {
      transitionTypeRef.current = type ?? 'forward';
      setHistory((stepList) => stepList.concat(step));
    };

    const replace = (step: TStep, type?: WizardTransitionType) => {
      transitionTypeRef.current = type ?? 'forward';
      setHistory((stepList) => {
        const newHistory = [...stepList];
        newHistory.pop();
        return newHistory.concat(step);
      });
    };

    const resetHistory = () => {
      transitionTypeRef.current = 'forward';
      setHistory([start]);
    };

    return {
      start,
      end: opts.end,
      selected,
      back,
      push,
      transitionType,
      resetHistory,
      replace,
      hasPreviousStep: history.length > 1,
      isFinalStep: [opts.end].flat().includes(selected),
      disablePromptOnNavigation: Boolean(
        opts.noPromptOnNavigation?.includes(selected),
      ),
    };
  }

  function Wizard(props: WizardProps<TStep, TFormState>) {
    const { steps, closeable = true, initialState = {} } = props;
    const [formState, setFormState] = useState<TFormState>(
      initialState as TFormState,
    );
    const wizard = useWizard({
      start: props.start,
      history: props.history,
    });

    usePromptOnNavigation(
      !wizard.disablePromptOnNavigation &&
        wizard.hasPreviousStep &&
        !wizard.isFinalStep,
    );

    const setStepState: WizardContext<TStep, TFormState>['setStepState'] = (
      step,
      state,
    ) => {
      setFormState((prevState) => ({
        ...prevState,
        [step]: state,
      }));
    };

    const getStepState: WizardContext<TStep, TFormState>['getStepState'] =
      useCallback(
        (step: keyof TFormState) => {
          const state = formState[step];
          return state as TFormState[typeof step]['_input'] | undefined;
        },
        [formState],
      );

    const getCompletedStepState: WizardContext<
      TStep,
      TFormState
    >['getCompletedStepState'] = useCallback(
      (step: keyof TFormState) => {
        const state = formState[step];
        if (!state) {
          throw new Error(`Step state not found: ${String(step)}`);
        }
        return state as TFormState[typeof step]['_input'];
      },
      [formState],
    );

    const reset = () => {
      wizard.resetHistory();
      setFormState({} as TFormState);
    };

    const onClose = closeable
      ? () => {
          if (props.onClose) {
            props.onClose();
            return;
          }

          const { isFinalStep, back } = wizard;
          const resetOrBack = isFinalStep ? reset : back;
          resetOrBack();
        }
      : undefined;
    const SingleLayout = Wizard.useSingleLayout ? Wizard.Layout : Fragment;
    return (
      <ContextProvider
        value={{
          reset,
          formState,
          setFormState,
          setStepState,
          getStepState,
          getCompletedStepState,
          onClose,
          ...wizard,
        }}
      >
        <SingleLayout>
          {Object.entries(steps).map(([step, children]) => (
            <Wizard.Transition
              key={step}
              show={step === wizard.selected}
              transitionType={wizard.transitionType}
            >
              {children as ReactNode}
            </Wizard.Transition>
          ))}
        </SingleLayout>
      </ContextProvider>
    );
  }

  Wizard.useContext = useContext;
  Wizard.schema = opts.schema;
  Wizard.steps = opts.steps;
  Wizard.getSchema = <T extends keyof TFormState>(step: T) =>
    opts.schema[step] as TFormState[T];

  type StepProps = Omit<ViewProps, 'shrink' | 'footer'> &
    Partial<Pick<ViewProps, 'footer'>>;

  /**
   * Configure wether each step should be individually wrapped in the layout or if it should be global to the wizard.
   *
   * useSingleLayout true is useful for transitions
   * */
  Wizard.useSingleLayout = false;

  const DefaultTransition: WizardTransition = (props) => {
    return <>{props.show && props.children}</>;
  };
  /**
   * @important setTransition should be prefered over manual override
   * */
  Wizard.Transition = DefaultTransition;

  Wizard.setTransition = function (transition: keyof typeof WizardTransitions) {
    // Transition expects a single layout
    Wizard.useSingleLayout = true;

    Wizard.Transition = WizardTransitions[transition];
  };

  Wizard.Layout = function Layout(props: { children: ReactNode }) {
    const {
      back,
      hasPreviousStep,
      isFinalStep,
      onClose: handleClose,
    } = useContext();
    return (
      <View.Layout
        header={
          <View.Header
            onClose={handleClose}
            onBack={back}
            showBack={hasPreviousStep && !isFinalStep}
          />
        }
        {...props}
      />
    );
  };
  Wizard.Step = function Step(props: StepProps) {
    const Layout = Wizard.useSingleLayout ? Fragment : Wizard.Layout;
    return (
      <Layout>
        <View {...props} />
      </Layout>
    );
  };

  Wizard.FormStep = function FormStep<T extends FieldValues>(
    props: FormViewProps<T>,
  ) {
    const Layout = Wizard.useSingleLayout ? Fragment : Wizard.Layout;
    return (
      <Layout>
        <View.Form {...props} />
      </Layout>
    );
  };

  return Wizard;
}

type UseWizardReturn<TStep extends string> = {
  start: TStep;
  end: TStep | TStep[];
  selected: TStep;
  back: () => void;
  push: (step: TStep, type?: WizardTransitionType) => void;
  transitionType: WizardTransitionType;
  resetHistory: () => void;
  replace: (step: TStep, type?: WizardTransitionType) => void;
  hasPreviousStep: boolean;
  isFinalStep: boolean;
  disablePromptOnNavigation: boolean;
};
