import { deterministicColors } from './deterministicColors';

function hashStr(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

function randomValueFromHash(array: string[], str: string) {
  return array[
    hashStr(str.trim().toLocaleLowerCase()) % array.length
  ] as string;
}

export function randomDeterministicColors(str: string): string {
  return randomValueFromHash(Object.keys(deterministicColors), str);
}
