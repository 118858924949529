/* generated, do not edit */
import type { SVGProps } from 'react';
import { withIcon } from '~/components/withIcon';

export const AddIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.425 12.575v5.675h1.15v-5.675h5.675v-1.15h-5.675V5.75h-1.15v5.675H5.75v1.15h5.675Z"
      clipRule="evenodd"
    />
  </svg>
));

export const AlertCircleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.85 12a6.85 6.85 0 1 1-13.7 0 6.85 6.85 0 0 1 13.7 0ZM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8.575 0V8h1.15v4h-1.15ZM12 14.25a.75.75 0 0 0 0 1.5h.01a.75.75 0 0 0 0-1.5H12Z"
      clipRule="evenodd"
    />
  </svg>
));

export const AlertRectangleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.174 4.876a3.075 3.075 0 0 0-4.349 0l-4.95 4.95a3.075 3.075 0 0 0 0 4.348l4.95 4.95a3.078 3.078 0 0 0 4.35 0l4.949-4.95c1.2-1.2 1.2-3.147 0-4.348l-4.95-4.95Zm-3.535.813a1.925 1.925 0 0 1 2.722 0l4.95 4.95c.752.752.752 1.97 0 2.722l-4.95 4.95a1.925 1.925 0 0 1-2.722 0l-4.95-4.95a1.925 1.925 0 0 1 0-2.722l4.95-4.95ZM11.425 12V8h1.15v4h-1.15Zm.57 2.25a.748.748 0 0 0-.745.75c0 .414.333.75.745.75h.01a.747.747 0 0 0 .745-.75.747.747 0 0 0-.745-.75h-.01Z"
      clipRule="evenodd"
    />
  </svg>
));

export const AlertIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.25 18.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm.575-3.5v-9h-1.15v9h1.15Z"
    />
  </svg>
));

export const AlignArrowDownIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M19.25 19.25H4.75m7.25-4V4.75m0 10.5 3.25-3.5M12 15.25l-3.25-3.5"
    />
  </svg>
));

export const AlignArrowUpIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M4.75 4.75h14.5"
    />
    <path
      fill="currentColor"
      d="M11.425 19.25a.575.575 0 1 0 1.15 0h-1.15Zm0-10.5v10.5h1.15V8.75h-1.15Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M8.75 12.25 12 8.75l3.25 3.5"
    />
  </svg>
));

export const AppleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.61 8.454c-.093.072-1.732.996-1.732 3.048 0 2.375 2.085 3.215 2.148 3.236-.01.05-.332 1.15-1.1 2.27-.684.986-1.4 1.97-2.488 1.97s-1.368-.632-2.624-.632c-1.224 0-1.659.652-2.654.652-.995 0-1.69-.912-2.488-2.032C5.747 15.651 5 13.608 5 11.67c0-3.11 2.022-4.76 4.013-4.76 1.057 0 1.939.694 2.603.694.632 0 1.618-.736 2.82-.736.457 0 2.095.042 3.174 1.587ZM13.866 5.55c.497-.59.85-1.41.85-2.228 0-.114-.01-.23-.031-.322-.81.03-1.773.54-2.354 1.213-.456.518-.881 1.337-.881 2.168 0 .125.02.25.03.29.051.009.134.02.218.02.726 0 1.64-.486 2.168-1.14Z"
    />
  </svg>
));

export const ArrowDownLeftIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.138 16.675 9.518-9.518-.813-.814-9.518 9.519V8.75h-1.15v8.5l.575.575h8.5v-1.15H8.138Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ArrowDownRightIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.825 17.082v-8.5h-1.15v7.111L7.156 6.175l-.813.813 9.519 9.519H8.75v1.15h8.5l.575-.575Z"
    />
  </svg>
));

export const ArrowDownIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.575 17.815V5h-1.15v12.815l-4.26-4.462-.831.794 5.25 5.5h.832l5.25-5.5-.832-.794-4.259 4.462Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ArrowLeftIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.853 6.334-5.5 5.25v.832l5.5 5.25.794-.832-4.462-4.259H19.25v-1.15H6.185l4.462-4.26-.794-.83Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ArrowRightIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.815 11.425-4.462-4.26.794-.83 5.5 5.25v.83l-5.5 5.25-.794-.83 4.462-4.26H4.75v-1.15h13.065Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ArrowUpRightIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.25 6.175h-8.5v1.15h7.112l-9.519 9.518.813.814 9.519-9.519v7.112h1.15v-8.5l-.575-.575Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ArrowUpIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.575 6.185 4.259 4.462.832-.794-5.25-5.5h-.832l-5.25 5.5.832.794 4.259-4.462V19h1.15V6.185Z"
      clipRule="evenodd"
    />
  </svg>
));

export const AsteriskIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.425 19.25v-6.303l-5.41 2.813-.53-1.02L10.754 12l-5.27-2.74.53-1.02 5.411 2.813V4.75h1.15v6.303l5.41-2.813.53 1.02L13.246 12l5.269 2.74-.53 1.02-5.41-2.813v6.303h-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const AtSignIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 12A6.675 6.675 0 0 1 12 5.325c3.274 0 4.875 1.042 5.7 2.289.858 1.298.975 2.954.975 4.386v1.25a1.425 1.425 0 0 1-2.85 0v-4.5h-1.15v.516a3.825 3.825 0 1 0 .283 5.16 2.575 2.575 0 0 0 4.867-1.176V12c0-1.443-.102-3.412-1.166-5.02-1.097-1.66-3.12-2.805-6.66-2.805a7.825 7.825 0 0 0 0 15.65v-1.15A6.675 6.675 0 0 1 5.326 12Zm9.35 0a2.675 2.675 0 1 0-5.35 0 2.675 2.675 0 0 0 5.35 0Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BankIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.75 10.825h.425v6.85H4.75v1.15h14.5v-1.15h-.425v-6.85h.425l.384-1.003-7.25-6.5h-.768l-7.25 6.5.384 1.003Zm1.575 6.85v-6.85h2.85v6.85h-2.85Zm4 0v-6.85h3.35v6.85h-3.35Zm4.5 0v-6.85h2.85v6.85h-2.85ZM12 4.522 6.253 9.675h11.494L12 4.522Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BellOffIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 5.325A4.675 4.675 0 0 0 7.325 10v2l-.055.245-1 2.125-1.04-.49.945-2.009V10A5.825 5.825 0 0 1 12 4.175v.575-.575h.024a5.407 5.407 0 0 1 .27.008c.176.009.422.025.701.059.543.064 1.274.198 1.859.495l-.52 1.026c-.4-.203-.966-.32-1.474-.38a8.822 8.822 0 0 0-.848-.058H12Zm5.825 1.663 1.831-1.831-.813-.814-14.5 14.5.813.814 2.832-2.832h.44a3.156 3.156 0 0 0 .056.428c.055.28.168.66.398 1.043.482.804 1.423 1.529 3.118 1.529 1.695 0 2.635-.725 3.118-1.53a3.292 3.292 0 0 0 .455-1.47h3.677l.52-.82-1.945-4.133V6.987Zm-1.15 1.15-7.537 7.537h9.206l-1.614-3.43-.055-.245V8.138Zm-7.064 8.89a2.038 2.038 0 0 1-.03-.203h4.839a2.141 2.141 0 0 1-.288.88c-.268.445-.827.97-2.132.97-1.305 0-1.865-.525-2.132-.97a2.143 2.143 0 0 1-.257-.677Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BellIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4.175A5.825 5.825 0 0 0 6.175 10v1.871l-1.946 4.134.52.82h3.678l.006.082a3.292 3.292 0 0 0 .449 1.39c.482.803 1.423 1.528 3.118 1.528 1.695 0 2.635-.725 3.118-1.53a3.292 3.292 0 0 0 .454-1.47h3.678l.52-.82-1.945-4.133V10A5.825 5.825 0 0 0 12 4.175Zm2.42 12.65H9.58a2.143 2.143 0 0 0 .288.88c.267.445.827.97 2.132.97 1.305 0 1.864-.525 2.132-.97a2.144 2.144 0 0 0 .287-.88ZM7.324 10a4.675 4.675 0 0 1 9.35 0v2l.055.245 1.614 3.43H5.656l1.614-3.43.055-.245v-2Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BoltIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.75 12.675H7.914l4.761-6.226v4.301l.575.575h2.837l-4.762 6.227V13.25l-.575-.575Zm2.043-8.274-6.5 8.5.457.924h3.425v5.425l1.032.35 6.5-8.5-.457-.925h-3.425V4.75l-1.031-.35Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BookIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.175 5.75c0-.87.705-1.575 1.575-1.575H10a2.57 2.57 0 0 1 2 .953 2.57 2.57 0 0 1 2-.953h4.25c.87 0 1.575.705 1.575 1.575v10.5c0 .87-.705 1.575-1.575 1.575h-2.593c-.909 0-1.78.36-2.422 1.003l-.829.829a.575.575 0 0 1-.813 0l-.828-.829a3.425 3.425 0 0 0-2.422-1.003H5.75c-.87 0-1.575-.705-1.575-1.575V5.75Zm7.25 1c0-.787-.638-1.425-1.425-1.425H5.75a.425.425 0 0 0-.425.425v10.5c0 .235.19.425.425.425h2.593c1.143 0 2.241.427 3.082 1.194V6.75Zm1.15 11.119a4.575 4.575 0 0 1 3.082-1.194h2.593c.235 0 .425-.19.425-.425V5.75a.425.425 0 0 0-.425-.425H14c-.787 0-1.425.638-1.425 1.425v11.119Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BordersIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.25 4.175h-8.5v1.15h8.5v-1.15Zm2.425 3.575v8.5h1.15v-8.5h-1.15Zm-14.5 0v8.5h1.15v-8.5h-1.15ZM16.25 18.675h-8.5v1.15h8.5v-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BriefcaseIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M4.75 9.75a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v7.5a2 2 0 0 1-2 2H6.75a2 2 0 0 1-2-2v-7.5Zm4-2.25v-.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2v.75M5 13.25h14m-10.25-1.5v2.5m6.5-2.5v2.5"
    />
  </svg>
));

export const BuildingStoreIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.943 5.934a2.575 2.575 0 0 1 2.345-1.509h9.424c1.01 0 1.926.59 2.344 1.51l.538 1.183c.152.334.23.698.23 1.065v9.067a2.575 2.575 0 0 1-2.574 2.575H6.75a2.575 2.575 0 0 1-2.575-2.575V8.183c0-.367.079-.73.23-1.065l.538-1.184Zm2.345-.359c-.56 0-1.066.327-1.298.835l-.537 1.184a1.426 1.426 0 0 0-.116.403c.045.46.215.88.482 1.178.266.297.646.5 1.18.5 1.14 0 1.917-.95 1.926-1.909V7.75a.575.575 0 1 1 1.15 0v.016c.009.959.787 1.909 1.925 1.909 1.145 0 1.925-.96 1.925-1.925a.575.575 0 1 1 1.15 0c0 .964.78 1.925 1.925 1.925.534 0 .915-.203 1.18-.5.268-.299.437-.718.483-1.178a1.424 1.424 0 0 0-.116-.403L18.01 6.41a1.425 1.425 0 0 0-1.297-.835H7.288ZM7 10.825c1.094 0 1.968-.54 2.5-1.297a3.013 3.013 0 0 0 2.5 1.297c1.094 0 1.968-.54 2.5-1.297a3.013 3.013 0 0 0 2.5 1.297c.667 0 1.23-.205 1.675-.546v6.971c0 .787-.638 1.425-1.425 1.425h-2.426V15.75a2.575 2.575 0 0 0-2.575-2.575h-.5a2.575 2.575 0 0 0-2.575 2.575v2.926H6.75a1.425 1.425 0 0 1-1.425-1.425v-6.97c.444.34 1.008.545 1.675.545Zm3.324 4.924c0-.787.638-1.425 1.425-1.425h.5c.787 0 1.425.638 1.425 1.425v2.925h-3.35V15.75Z"
      clipRule="evenodd"
    />
  </svg>
));

export const BuildingIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.75 6.75a2 2 0 0 1 2-2h8.5a2 2 0 0 1 2 2v12.5H5.75V6.75Zm13.5 12.5H4.75"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.75 15.75a2 2 0 0 1 2-2h.5a2 2 0 0 1 2 2v3.5h-4.5v-3.5Z"
    />
    <path
      fill="currentColor"
      d="M10 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
));

export const BurgerIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 5.325A2.425 2.425 0 0 0 6.325 7.75v1.425h11.35V7.75a2.425 2.425 0 0 0-2.425-2.425h-6.5ZM5.175 7.75c0-1.974 1.6-3.575 3.575-3.575h6.5c1.974 0 3.575 1.6 3.575 3.575v2l-.575.575H5.75l-.575-.575v-2Zm0 7 .575-.575H12l.257.06 1.743.872 1.743-.871.257-.061h2.25l.575.575v1.5c0 1.974-1.6 3.575-3.575 3.575h-6.5a3.575 3.575 0 0 1-3.575-3.575v-1.5Zm1.15.575v.925a2.425 2.425 0 0 0 2.425 2.425h6.5a2.425 2.425 0 0 0 2.425-2.425v-.925h-1.54l-1.878.94h-.514l-1.879-.94h-5.54Zm-1.575-2.5h14.5v-1.15H4.75v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CalendarIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.425 7.325v.925h1.15v-.925h6.85v.925h1.15v-.925h.675c.787 0 1.425.638 1.425 1.425v8.5c0 .787-.638 1.425-1.425 1.425H6.75a1.425 1.425 0 0 1-1.425-1.425v-8.5c0-.787.638-1.425 1.425-1.425h.675Zm1.15-1.15h6.85V4.75h1.15v1.425h.675a2.575 2.575 0 0 1 2.575 2.575v8.5a2.575 2.575 0 0 1-2.575 2.575H6.75a2.575 2.575 0 0 1-2.575-2.575v-8.5A2.575 2.575 0 0 1 6.75 6.175h.675V4.75h1.15v1.425Zm-.825 5.15h8.5v-1.15h-8.5v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CheckIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.75 6.175A2.575 2.575 0 0 0 4.175 8.75v6.5a2.575 2.575 0 0 0 2.575 2.575h10.5a2.575 2.575 0 0 0 2.575-2.575v-6.5a2.575 2.575 0 0 0-2.575-2.575H6.75ZM5.325 8.75c0-.787.638-1.425 1.425-1.425h.423a1.925 1.925 0 0 1-1.848 1.849V8.75Zm0 1.574a3.075 3.075 0 0 0 2.999-2.999h7.352a3.075 3.075 0 0 0 2.999 3v3.35a3.075 3.075 0 0 0-3 3h-7.35a3.075 3.075 0 0 0-3-3v-3.35Zm13.35-1.15a1.925 1.925 0 0 1-1.849-1.849h.424c.787 0 1.425.638 1.425 1.425v.424Zm-1.849 7.501a1.925 1.925 0 0 1 1.849-1.849v.424c0 .787-.638 1.425-1.425 1.425h-.424ZM5.325 14.826a1.925 1.925 0 0 1 1.848 1.849H6.75a1.425 1.425 0 0 1-1.425-1.425v-.424Zm5-2.826c0-.795.224-1.49.557-1.97.334-.484.739-.705 1.118-.705.38 0 .784.221 1.118.704.333.48.557 1.176.557 1.971 0 .795-.224 1.49-.557 1.97-.334.483-.739.705-1.118.705-.38 0-.784-.222-1.118-.704-.333-.48-.557-1.176-.557-1.971ZM12 8.175c-.864 0-1.584.506-2.064 1.2C9.454 10.07 9.175 11 9.175 12s.28 1.93.761 2.625c.48.694 1.2 1.2 2.064 1.2.863 0 1.583-.506 2.063-1.2.482-.695.762-1.625.762-2.625s-.28-1.93-.761-2.625c-.48-.694-1.2-1.2-2.064-1.2Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CheckmarkCircleIcon = withIcon(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.85 12a6.85 6.85 0 1 1-13.7 0 6.85 6.85 0 0 1 13.7 0ZM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8.051 2.86 4-5-.898-.72-3.64 4.552-2.052-1.641-.718.898 2.5 2 .449.36.359-.45Z"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export const CheckmarkIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.285 16.795 9.56-10L18.012 6l-9.13 9.55-3.026-3.382-.857.767 3.441 3.846a.575.575 0 0 0 .844.014Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ChevronDownIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.171 10.359 12 13.405l2.829-3.046.842.782-3.25 3.5h-.842l-3.25-3.5.842-.782Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ChevronLeftIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.859 8.329-3.5 3.25v.842l3.5 3.25.782-.842L10.595 12l3.046-2.829-.782-.842Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ChevronRightIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.359 9.171 13.405 12l-3.046 2.829.782.842 3.5-3.25v-.842l-3.5-3.25-.782.842Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ChevronUpIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.579 10.359-3.25 3.5.842.782L12 11.595l2.829 3.046.842-.782-3.25-3.5h-.842Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CircleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 18.85a6.85 6.85 0 1 0 0-13.7 6.85 6.85 0 0 0 0 13.7ZM12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
      clipRule="evenodd"
    />
  </svg>
));

export const Clock24hIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M19.5 12v.25A7.25 7.25 0 1 1 9 5.767m3.25-.09c0-.512.558-.927 1.247-.927.688 0 1.253.2 1.253.926 0 .727-2.5 2.574-2.5 2.574h2.5M12 10.75V12l2.25 2.25m4.167-6.875H16.75l1.667-2.625v2.625Zm0 0v.875m0-.875h.833"
    />
  </svg>
));

export const ClockIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 12a6.675 6.675 0 1 1 13.35 0 6.675 6.675 0 0 1-13.35 0ZM12 4.175a7.825 7.825 0 1 0 0 15.65 7.825 7.825 0 0 0 0-15.65ZM11.425 8v4.238l.168.169 2 2 .813-.814-1.831-1.831V8h-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CloseIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.187 12-4.844 4.843.813.814L12 12.813l4.843 4.844.813-.814L12.813 12l4.843-4.843-.813-.814L12 11.187 7.156 6.343l-.813.814L11.187 12Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CogIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="m5.621 14.963 1.101.172c.813.127 1.393.872 1.333 1.71l-.081 1.137a.811.811 0 0 0 .445.787l.814.4c.292.145.641.09.88-.134l.818-.773a1.55 1.55 0 0 1 2.138 0l.818.773a.776.776 0 0 0 .88.135l.815-.402a.808.808 0 0 0 .443-.785l-.08-1.138c-.06-.838.52-1.583 1.332-1.71l1.101-.172a.798.798 0 0 0 .651-.62l.201-.9a.816.816 0 0 0-.324-.847l-.918-.643a1.634 1.634 0 0 1-.476-2.132l.555-.988a.824.824 0 0 0-.068-.907l-.563-.723a.78.78 0 0 0-.85-.269l-1.064.334a1.567 1.567 0 0 1-1.928-.949l-.407-1.058a.791.791 0 0 0-.737-.511l-.903.002a.791.791 0 0 0-.734.516l-.398 1.045a1.566 1.566 0 0 1-1.93.956l-1.11-.348a.78.78 0 0 0-.851.27l-.56.724a.823.823 0 0 0-.062.91l.568.99c.418.73.213 1.666-.469 2.144l-.907.636a.817.817 0 0 0-.324.847l.2.9c.072.325.33.57.651.62Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="M12 14.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
    />
  </svg>
));

export const CopyIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 6.75c0-.787.638-1.425 1.425-1.425h6.75c.649 0 1.175.526 1.175 1.175h1.15A2.325 2.325 0 0 0 13.5 4.175H6.75A2.575 2.575 0 0 0 4.175 6.75v6.75A2.325 2.325 0 0 0 6.5 15.825v-1.15A1.175 1.175 0 0 1 5.325 13.5V6.75Zm4 4c0-.787.638-1.425 1.425-1.425h6.5c.787 0 1.425.638 1.425 1.425v6.5c0 .787-.638 1.425-1.425 1.425h-6.5a1.425 1.425 0 0 1-1.425-1.425v-6.5Zm1.425-2.575a2.575 2.575 0 0 0-2.575 2.575v6.5a2.575 2.575 0 0 0 2.575 2.575h6.5a2.575 2.575 0 0 0 2.575-2.575v-6.5a2.575 2.575 0 0 0-2.575-2.575h-6.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const CreditCardIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.75 6.325c-.787 0-1.425.638-1.425 1.425v1.925h13.35V7.75c0-.787-.638-1.425-1.425-1.425H6.75Zm11.925 4.5H5.325v5.425c0 .787.638 1.425 1.425 1.425h10.5c.787 0 1.425-.638 1.425-1.425v-5.425ZM4.175 7.75A2.575 2.575 0 0 1 6.75 5.175h10.5a2.575 2.575 0 0 1 2.575 2.575v8.5a2.575 2.575 0 0 1-2.575 2.575H6.75a2.575 2.575 0 0 1-2.575-2.575v-8.5Zm6.075 7.075h-2.5v-1.15h2.5v1.15Zm5.001 0h-.001v-1.15h1v1.15h-1Z"
      clipRule="evenodd"
    />
  </svg>
));

export const DashboardIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 6.75A2.75 2.75 0 0 1 6.75 4h1.5A2.75 2.75 0 0 1 11 6.75v1.5A2.75 2.75 0 0 1 8.25 11h-1.5A2.75 2.75 0 0 1 4 8.25v-1.5ZM6.75 5.5c-.69 0-1.25.56-1.25 1.25v1.5c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25v-1.5c0-.69-.56-1.25-1.25-1.25h-1.5ZM14 7a.75.75 0 0 1 .75-.75h1.5v-1.5a.75.75 0 0 1 1.5 0v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5A.75.75 0 0 1 14 7ZM4 15.75A2.75 2.75 0 0 1 6.75 13h1.5A2.75 2.75 0 0 1 11 15.75v1.5A2.75 2.75 0 0 1 8.25 20h-1.5A2.75 2.75 0 0 1 4 17.25v-1.5Zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v1.5c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25v-1.5c0-.69-.56-1.25-1.25-1.25h-1.5Zm9-1.5A2.75 2.75 0 0 0 13 15.75v1.5A2.75 2.75 0 0 0 15.75 20h1.5A2.75 2.75 0 0 0 20 17.25v-1.5A2.75 2.75 0 0 0 17.25 13h-1.5Zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const DocumentTextIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 4.175A2.575 2.575 0 0 0 5.175 6.75v10.5a2.575 2.575 0 0 0 2.575 2.575h8.5a2.575 2.575 0 0 0 2.575-2.575v-7l-.169-.407-5.5-5.5-.406-.168h-5ZM6.325 6.75c0-.787.638-1.425 1.425-1.425h4.425V8.25a2.575 2.575 0 0 0 2.575 2.575h2.925v6.425c0 .787-.638 1.425-1.425 1.425h-8.5a1.425 1.425 0 0 1-1.425-1.425V6.75Zm10.537 2.925-3.537-3.537V8.25c0 .787.638 1.425 1.425 1.425h2.112Zm-8.112 3.65h2.5v-1.15h-2.5v1.15Zm0 3h6.5v-1.15h-6.5v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const DocumentIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 4.175A2.575 2.575 0 0 0 5.175 6.75v10.5a2.575 2.575 0 0 0 2.575 2.575h8.5a2.575 2.575 0 0 0 2.575-2.575v-7l-.169-.407-5.5-5.5-.406-.168h-5Zm9.925 13.075v-6.425H14.75a2.575 2.575 0 0 1-2.575-2.575V5.325H7.75c-.787 0-1.425.638-1.425 1.425v10.5c0 .787.638 1.425 1.425 1.425h8.5c.787 0 1.425-.638 1.425-1.425Zm-4.35-11.112 3.537 3.537H14.75a1.425 1.425 0 0 1-1.425-1.425V6.138Z"
      clipRule="evenodd"
    />
  </svg>
));

export const Dollar2Icon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.325 6.175V4.75h-1.15v1.425h-1.8a3.2 3.2 0 0 0 0 6.4h1.8v4.1H6.75v1.15h4.425v1.425h1.15v-1.425h2.3a3.2 3.2 0 0 0 0-6.4h-2.3v-4.1h4.925v-1.15h-4.925Zm-1.15 1.15h-1.8a2.05 2.05 0 0 0 0 4.1h1.8v-4.1Zm1.15 5.25v4.1h2.3a2.05 2.05 0 1 0 0-4.1h-2.3Z"
      clipRule="evenodd"
    />
  </svg>
));

export const DotsIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm6 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
    />
  </svg>
));

export const DownloadIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.425 12.786 9.17 10.359l-.843.782 3.25 3.5h.843l3.25-3.5-.843-.782-2.253 2.427V4.75h-1.15v8.036Zm-7.25 3.464v-1.5h1.15v1.5a2.425 2.425 0 0 0 2.425 2.425h8.5a2.425 2.425 0 0 0 2.425-2.425v-1.5h1.15v1.5c0 1.974-1.6 3.575-3.575 3.575h-8.5a3.575 3.575 0 0 1-3.575-3.575Z"
      clipRule="evenodd"
    />
  </svg>
));

export const EggTimerIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.75 4.175-.55.74 1.1 3.669C6.737 10.042 7.63 11.25 8.866 12c-1.235.75-2.129 1.958-2.566 3.416l-1.1 3.669.55.74h12.5l.55-.74-1.1-3.669c-.437-1.458-1.331-2.667-2.566-3.416 1.235-.75 2.129-1.958 2.566-3.416l1.1-3.669-.55-.74H5.75Zm1.651 4.078-.878-2.928h10.954L16.6 8.253c-.472 1.574-1.559 2.736-3.023 3.199v1.096c1.464.463 2.55 1.625 3.023 3.198l.878 2.929H6.523l.878-2.929c.472-1.573 1.559-2.735 3.023-3.198v-1.096C8.96 10.989 7.874 9.827 7.4 8.253Z"
      clipRule="evenodd"
    />
  </svg>
));

export const EjectIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.904 13.792.463.916h12.5l.463-.916-6.25-8.5h-.926l-6.25 8.5Zm6.713-7.189 5.113 6.955H7.504l5.113-6.955Zm6.25 10.955h-12.5v1.15h12.5v-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ExpandIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.175 4.75v4.5h1.15V6.138l4.518 4.519.813-.814-4.518-4.518H9.25v-1.15h-4.5l-.575.575ZM14.75 19.825h4.5l.575-.575v-4.5h-1.15v3.112l-4.519-4.519-.813.814 4.519 4.518H14.75v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ExternalLinkIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.575 5.175h4.5l.575.575v4.5H18.5V7.138l-4.269 4.269-.813-.814 4.268-4.268h-3.111v-1.15ZM6 9.75a2.575 2.575 0 0 1 2.575-2.575h2.5v1.15h-2.5c-.787 0-1.425.638-1.425 1.425v6.5c0 .787.638 1.425 1.425 1.425h6.5c.787 0 1.425-.638 1.425-1.425v-2.5h1.15v2.5a2.575 2.575 0 0 1-2.575 2.575h-6.5A2.575 2.575 0 0 1 6 16.25v-6.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const EyeOffIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m4.343 18.843 2.209-2.208a8.91 8.91 0 0 1-1.816-2.642c-.37-.823-.561-1.578-.561-1.993 0-.357.14-.953.4-1.61a9.13 9.13 0 0 1 1.284-2.244C7.07 6.599 9.038 5.175 12 5.175c1.91 0 3.416.593 4.572 1.44l2.272-2.272.813.814-14.5 14.5-.813-.814Zm5.294-5.293-2.272 2.27a7.762 7.762 0 0 1-1.58-2.301c-.345-.764-.46-1.344-.46-1.52 0-.143.079-.579.319-1.187a7.98 7.98 0 0 1 1.121-1.959C7.805 7.526 9.462 6.325 12 6.325c1.572 0 2.797.46 3.747 1.115l-2.198 2.197a2.826 2.826 0 0 0-3.912 3.912Zm.843-.844 2.226-2.225a1.676 1.676 0 0 0-2.226 2.225Zm8.07-1.33a7.185 7.185 0 0 0-.442-1.123l1.033-.506c.234.479.402.928.513 1.307.107.366.17.703.17.946 0 .357-.14.953-.399 1.61a9.13 9.13 0 0 1-1.285 2.244c-1.21 1.547-3.178 2.971-6.14 2.971-.78 0-1.496-.1-2.15-.276l.3-1.11a7.04 7.04 0 0 0 1.85.236c2.538 0 4.195-1.201 5.235-2.53a7.976 7.976 0 0 0 1.12-1.958c.241-.608.32-1.044.32-1.187 0-.082-.03-.296-.125-.624Z"
      clipRule="evenodd"
    />
  </svg>
));

export const EyeIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 12c0-.143.079-.579.319-1.187a7.98 7.98 0 0 1 1.121-1.959C7.805 7.526 9.462 6.325 12 6.325c2.538 0 4.195 1.2 5.235 2.53a7.95 7.95 0 0 1 1.12 1.958c.241.608.32 1.044.32 1.187 0 .143-.079.579-.32 1.187a7.978 7.978 0 0 1-1.12 1.959c-1.04 1.328-2.697 2.529-5.235 2.529-2.538 0-4.195-1.201-5.235-2.53a7.98 7.98 0 0 1-1.121-1.958c-.24-.608-.32-1.044-.32-1.187ZM12 5.175c-2.962 0-4.93 1.424-6.14 2.97a9.129 9.129 0 0 0-1.286 2.245c-.26.657-.4 1.253-.4 1.61 0 .357.14.953.4 1.61a9.13 9.13 0 0 0 1.285 2.244c1.21 1.547 3.179 2.971 6.14 2.971 2.963 0 4.93-1.424 6.141-2.97a9.13 9.13 0 0 0 1.285-2.245c.26-.657.4-1.253.4-1.61 0-.357-.14-.953-.4-1.61a9.128 9.128 0 0 0-1.285-2.244C16.93 6.599 14.962 5.175 12 5.175ZM10.325 12a1.675 1.675 0 1 1 3.35 0 1.675 1.675 0 0 1-3.35 0ZM12 9.175a2.825 2.825 0 1 0 0 5.65 2.825 2.825 0 0 0 0-5.65Z"
      clipRule="evenodd"
    />
  </svg>
));

export const FaceBigSmileIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.068 8.527a.722.722 0 1 0 0-1.444.722.722 0 0 0 0 1.444Zm0 1.083a1.805 1.805 0 1 0 0-3.61 1.805 1.805 0 0 0 0 3.61Zm5.596-1.083a.722.722 0 1 0 0-1.444.722.722 0 0 0 0 1.444Zm0 1.083a1.805 1.805 0 1 0 0-3.61 1.805 1.805 0 0 0 0 3.61Zm-7.21 4.033a4.873 4.873 0 0 1-.37-1.866H6a5.957 5.957 0 1 0 11.914 0H16.83a4.874 4.874 0 0 1-9.377 1.866Z"
      clipRule="evenodd"
    />
  </svg>
));

export const FaceHappyIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 12a6.675 6.675 0 1 1 13.35 0 6.675 6.675 0 0 1-13.35 0ZM12 4.175a7.825 7.825 0 1 0 0 15.65 7.825 7.825 0 0 0 0-15.65Zm-2 6.575a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm4.75-.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.825c1.208 0 1.898-.55 2.25-1.165a2.535 2.535 0 0 0 .324-1.119v-.025l.001-.01v-.005L14 13.5h-.575v-.006.004a1.316 1.316 0 0 1-.021.168c-.023.12-.068.275-.153.424-.149.26-.46.585-1.251.585-.792 0-1.102-.325-1.25-.585a1.389 1.389 0 0 1-.175-.592v-.004.005L10 13.5h-.575v.041l.006.081a2.536 2.536 0 0 0 .32 1.038c.351.615 1.04 1.165 2.249 1.165Z"
      clipRule="evenodd"
    />
  </svg>
));

export const FaceNeutralIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 12a6.675 6.675 0 1 1 13.35 0 6.675 6.675 0 0 1-13.35 0ZM12 4.175a7.825 7.825 0 1 0 0 15.65 7.825 7.825 0 0 0 0-15.65Zm-3 10.9h6v-1.15H9v1.15ZM10.75 10a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm3.25.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const FiltersIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M4.75 7C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5V7ZM19.25 8.5C19.6642 8.5 20 8.16421 20 7.75C20 7.33579 19.6642 7 19.25 7V8.5ZM4.75 8.5H19.25V7H4.75V8.5Z"
      fill="currentColor"
    />
    <path
      d="M6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5V11ZM17.25 12.5C17.6642 12.5 18 12.1642 18 11.75C18 11.3358 17.6642 11 17.25 11V12.5ZM6.75 12.5H17.25V11H6.75V12.5Z"
      fill="currentColor"
    />
    <path
      d="M8.75 15C8.33579 15 8 15.3358 8 15.75C8 16.1642 8.33579 16.5 8.75 16.5V15ZM15.25 16.5C15.6642 16.5 16 16.1642 16 15.75C16 15.3358 15.6642 15 15.25 15V16.5ZM8.75 16.5H15.25V15H8.75V16.5Z"
      fill="currentColor"
    />
  </svg>
));

export const GiftIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.78 4.22a.75.75 0 0 1 0 1.06L14.06 7h5.19a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75H19v5.25A2.75 2.75 0 0 1 16.25 20h-8.5A2.75 2.75 0 0 1 5 17.25V12h-.25a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 4.75 7h5.19L8.22 5.28a.75.75 0 0 1 1.06-1.06L12 6.94l2.72-2.72a.75.75 0 0 1 1.06 0ZM12.75 12h4.75v5.25c0 .69-.56 1.25-1.25 1.25h-3.5V12Zm-1.5 0H6.5v5.25c0 .69.56 1.25 1.25 1.25h3.5V12Zm7.25-1.5h-13v-2h13v2Z"
      clipRule="evenodd"
    />
  </svg>
));

export const GoogleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.366 9.105C14.59 8.36 13.6 7.983 12.5 7.983c-1.952 0-3.605 1.318-4.196 3.09v.002c-.15.45-.235.93-.235 1.425 0 .494.085.975.235 1.425.59 1.773 2.243 3.092 4.196 3.092 1.01 0 1.868-.266 2.54-.716a3.45 3.45 0 0 0 1.497-2.263H12.5v-2.902h7.064c.088.491.136 1.003.136 1.535 0 2.284-.818 4.206-2.236 5.512h-.001C16.222 19.328 14.524 20 12.499 20a7.497 7.497 0 0 1-6.702-4.132v-.002A7.497 7.497 0 0 1 5 12.5c0-1.21.29-2.355.798-3.368h-.001A7.497 7.497 0 0 1 12.499 5c2.022 0 3.72.743 5.018 1.953l-2.15 2.152Z"
      clipRule="evenodd"
    />
  </svg>
));

export const GraphIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.75 19.25h14.5m-13-10.5h-.5a1 1 0 0 0-1 1v5.5a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1v-5.5a1 1 0 0 0-1-1Zm6-4h-.5a1 1 0 0 0-1 1v9.5a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1v-9.5a1 1 0 0 0-1-1Zm6 4h-.5a1 1 0 0 0-1 1v5.5a1 1 0 0 0 1 1h.5a1 1 0 0 0 1-1v-5.5a1 1 0 0 0-1-1Z"
    />
  </svg>
));

export const HashIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.683 4.652-.607 3.523H5.75v1.15h3.128l-.923 5.35H4.75v1.15h3.007l-.574 3.327 1.134.196.607-3.523h4.833l-.574 3.327 1.134.196.607-3.523h3.326v-1.15h-3.128l.923-5.35h3.205v-1.15h-3.007l.574-3.327-1.134-.196-.607 3.523h-4.833l.574-3.327-1.134-.196Zm4.272 10.023.923-5.35h-4.833l-.923 5.35h4.833Z"
      clipRule="evenodd"
    />
  </svg>
));

export const HelpCircleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 5.325a6.675 6.675 0 1 0 0 13.35 6.675 6.675 0 0 0 0-13.35ZM4.175 12a7.825 7.825 0 1 1 15.65 0 7.825 7.825 0 0 1-15.65 0Zm6.146-1.936L9.75 10l-.572-.064v-.002l.001-.003.001-.009.004-.027a3.829 3.829 0 0 1 .08-.39 3.91 3.91 0 0 1 .358-.91c.38-.683 1.108-1.42 2.378-1.42 1.127 0 1.892.36 2.343.99.422.591.482 1.31.482 1.835a2.51 2.51 0 0 1-1.638 2.366c-.424.164-.612.433-.612.634v.25h-1.15V13c0-.904.74-1.472 1.347-1.707.608-.234.903-.76.903-1.293 0-.474-.065-.881-.268-1.166-.175-.244-.534-.509-1.407-.509-.73 0-1.128.388-1.373.83a2.766 2.766 0 0 0-.305.9v.01-.001ZM12 16.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const HomeIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.326 4.277a.575.575 0 0 0-.653 0l-7.25 5a.575.575 0 0 0-.248.473v7.5a2.575 2.575 0 0 0 2.575 2.575h10.5a2.575 2.575 0 0 0 2.575-2.575v-7.5a.575.575 0 0 0-.249-.473l-7.25-5ZM5.325 17.25v-7.198L12 5.45l6.675 4.603v7.198c0 .787-.638 1.425-1.425 1.425h-2.426V15.75a2.575 2.575 0 0 0-2.575-2.575h-.5a2.575 2.575 0 0 0-2.575 2.575v2.926H6.75a1.425 1.425 0 0 1-1.425-1.425Zm5-1.5c0-.788.637-1.426 1.424-1.426h.5c.787 0 1.425.638 1.425 1.425v2.925h-3.35V15.75Z"
      clipRule="evenodd"
    />
  </svg>
));

export const InformationCircleIcon = withIcon(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden="true"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.85 12a6.85 6.85 0 1 1-13.7 0 6.85 6.85 0 0 1 13.7 0ZM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7.415-.25v4h-1.15v-4h1.15ZM12.01 9.5a.75.75 0 0 0 0-1.5H12a.75.75 0 0 0 0 1.5h.01Z"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export const InformationIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.75 6.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm-.575 3.5v7h1.15v-7h-1.15Z"
    />
  </svg>
));

export const LinkIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M16.75 13.25 18 12a4.243 4.243 0 1 0-6-6l-1.25 1.25m-3.5 3.5L6 12a4.243 4.243 0 1 0 6 6l1.25-1.25m1-7-4.5 4.5"
    />
  </svg>
));

export const LinkedIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 4.175A4.825 4.825 0 0 0 4.175 9v1.25h1.15V9A3.675 3.675 0 0 1 9 5.325h3.5a4.175 4.175 0 1 1 0 8.35H11v1.15h1.5a5.325 5.325 0 1 0 0-10.65H9Zm2.5 5a5.325 5.325 0 1 0 0 10.65H15A4.825 4.825 0 0 0 19.825 15v-1.25h-1.15V15A3.675 3.675 0 0 1 15 18.675h-3.5a4.175 4.175 0 1 1 0-8.35H13v-1.15h-1.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const LockIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="M5.75 11.75a1 1 0 0 1 1-1h10.5a1 1 0 0 1 1 1v5.5a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2v-5.5Zm2-1.25v-.157c0-1.562-.094-3.302.997-4.42C9.368 5.288 10.375 4.75 12 4.75c1.626 0 2.632.537 3.254 1.174 1.09 1.117.996 2.857.996 4.419v.157"
    />
  </svg>
));

export const MailIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 7.75c0-.192.038-.375.107-.543l6.187 5.474h.762l6.187-5.474c.069.168.107.35.107.543v8.5c0 .787-.638 1.425-1.425 1.425H6.75a1.425 1.425 0 0 1-1.425-1.425v-8.5ZM12 11.482 6.265 6.41c.152-.054.315-.084.485-.084h10.5c.17 0 .333.03.484.084L12 11.482ZM6.75 5.175A2.575 2.575 0 0 0 4.175 7.75v8.5a2.575 2.575 0 0 0 2.575 2.575h10.5a2.575 2.575 0 0 0 2.575-2.575v-8.5a2.575 2.575 0 0 0-2.575-2.575H6.75Z"
      clipRule="evenodd"
    />
  </svg>
));

export const MailboxIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M11.25 15.25V8a3.25 3.25 0 1 0-6.5 0v7.25a1 1 0 0 0 1 1h4.5a1 1 0 0 0 1-1Z"
    />
    <path stroke="currentColor" d="M8.5 9a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M8.75 4.75h6.5a4 4 0 0 1 4 4v6.5a1 1 0 0 1-1 1h-9.5m6-7.25h1.5m-1 7.5v2.75"
    />
  </svg>
));

export const MenuIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.25 6.325H4.75v-1.15h14.5v1.15Zm0 12.5H4.75v-1.15h14.5v1.15Zm-14.5-6.25h14.5v-1.15H4.75v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const MinimizeIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m4.343 5.157 4.519 4.518H5.75v1.15h4.5l.575-.575v-4.5h-1.15v3.112L5.156 4.343l-.813.814Zm9.407 8.018-.575.575v4.5h1.15v-3.112l4.518 4.519.813-.814-4.518-4.518h3.112v-1.15h-4.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const MinusIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.25 12.575H4.75v-1.15h14.5v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const MonitorIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M9.75 15.25h7.5a2 2 0 0 0 2-2v-6.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h3Zm0 0s.25 3-1.75 4h8c-2-1-1.75-4-1.75-4"
    />
  </svg>
));

export const PackageIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M5.75 8.87 12 12.25M5.75 8.87v7L12 19.25M5.75 8.87l3.125-1.56M12 12.25v7m0-7 3.125-1.69M12 19.25l6.25-3.38v-7M8.875 7.31 12 5.75l6.25 3.12M8.875 7.31l6.25 3.25m3.125-1.69-3.125 1.69"
    />
  </svg>
));

export const PaintbrushIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="m12.79 8.736 3.407-3.408c.72-.72 1.857-.749 2.54-.065.684.683.655 1.82-.065 2.54l-3.434 3.435"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="m15.666 11.666-3.332-3.333a2 2 0 0 0-2.743-.08L8.75 9 15 15.25l.747-.84a2 2 0 0 0-.08-2.744ZM9 10l-4.25 2.75 6.5 6.5L14 15m-7 0 1.25-1.25"
    />
  </svg>
));

export const PaperclipIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.45 6.114a2.87 2.87 0 0 1 3.953 0 2.595 2.595 0 0 1-.01 3.774l-.001.001-5.708 5.482-.001.001c-.486.47-1.281.47-1.767 0a1.136 1.136 0 0 1 0-1.651l4.727-4.567-.8-.827-4.726 4.566a2.286 2.286 0 0 0 0 3.306c.931.9 2.433.9 3.364 0l5.706-5.48a3.745 3.745 0 0 0 .015-5.432l-.4.414.4-.414c-1.535-1.483-4.016-1.483-5.55 0h-.001l-5.642 5.43h-.001a5.207 5.207 0 0 0-.02 7.557c2.141 2.068 5.604 2.068 7.745 0l6.116-5.91-.799-.826-6.116 5.909.4.413-.4-.413c-1.695 1.637-4.451 1.637-6.147 0l-.399.413.4-.413a4.057 4.057 0 0 1 .015-5.899l.002-.001 5.644-5.432Z"
      clipRule="evenodd"
    />
  </svg>
));

export const PenIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.864 5.3a1.575 1.575 0 0 0-2.228 0l-9.292 9.293-.154.275-1 4.25.692.692 4.25-1 .275-.153L18.7 9.364a1.575 1.575 0 0 0 0-2.228l-1.836-1.835Zm-1.414.814a.425.425 0 0 1 .6 0l1.836 1.835a.425.425 0 0 1 0 .602L8.71 17.728l-3.186.75.75-3.187 9.177-9.177Zm3.8 12.561h-5.5v1.15h5.5v-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const PersonWithLuggageIcon = withIcon(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden="true"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.15}
        d="M14.25 14.25c0-2.75-.85-4.5-4.25-4.5s-4.25 1.75-4.25 4.5h2v3a2 2 0 0 0 2 2h2.5m2-5h-2v5m2-5h4v5h-6m0-12.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      />
    </svg>
  ),
);

export const PhoneIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="M8.893 4.75H6.068c-.728 0-1.318.59-1.318 1.318 0 7.28 5.902 13.182 13.182 13.182.728 0 1.318-.59 1.318-1.318v-2.825l-3.107-2.071-1.611 1.61c-.28.28-.698.368-1.05.186a11.093 11.093 0 0 1-2.518-1.796 8.726 8.726 0 0 1-1.836-2.542c-.16-.34-.067-.733.199-1l1.637-1.637L8.893 4.75Z"
    />
  </svg>
));

export const PinIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.325 11c0-3.171 2.667-5.675 5.675-5.675 3.007 0 5.675 2.504 5.675 5.675 0 .854-.336 1.764-.893 2.677-.554.909-1.301 1.779-2.064 2.541A23.996 23.996 0 0 1 12 18.544a23.983 23.983 0 0 1-2.718-2.325c-.763-.763-1.51-1.633-2.065-2.542-.556-.913-.892-1.823-.892-2.677Zm5.351 8.725L12 19.25l-.323.476a.575.575 0 0 0 .646 0L12 19.25l.323.475h.002l.004-.004.015-.01.056-.039.206-.147a25.126 25.126 0 0 0 2.925-2.493c.8-.8 1.615-1.743 2.233-2.756.616-1.01 1.06-2.13 1.06-3.276 0-3.829-3.204-6.825-6.824-6.825-3.62 0-6.825 2.996-6.825 6.825 0 1.146.445 2.267 1.06 3.276.618 1.013 1.433 1.956 2.233 2.756a25.127 25.127 0 0 0 3.132 2.64l.055.039.015.01.005.003v.001h.001ZM10.325 11a1.675 1.675 0 1 1 3.35 0 1.675 1.675 0 0 1-3.35 0ZM12 8.175a2.825 2.825 0 1 0 0 5.65 2.825 2.825 0 0 0 0-5.65Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ProfileIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.325 8a2.675 2.675 0 1 1 5.35 0 2.675 2.675 0 0 1-5.35 0ZM12 4.175a3.825 3.825 0 1 0 0 7.65 3.825 3.825 0 0 0 0-7.65Zm0 9.25c-2.12 0-3.689.463-4.846 1.153A6.108 6.108 0 0 0 4.85 16.99a1.787 1.787 0 0 0 .175 2.001c.414.52 1.092.834 1.822.834h10.305c.73 0 1.407-.313 1.822-.834.43-.54.554-1.283.174-2.001a6.109 6.109 0 0 0-2.302-2.411c-1.158-.691-2.728-1.154-4.847-1.154Zm-6.132 4.102a4.959 4.959 0 0 1 1.875-1.961c.95-.567 2.309-.991 4.257-.991 1.949 0 3.307.424 4.257.99a4.96 4.96 0 0 1 1.875 1.962c.154.291.105.544-.057.748-.177.222-.51.4-.922.4H6.848c-.412 0-.746-.178-.923-.4-.162-.204-.211-.457-.057-.748Z"
      clipRule="evenodd"
    />
  </svg>
));

export const QuickBooksIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 12c0 1.101.454 2.158 1.262 2.936a4.39 4.39 0 0 0 3.046 1.216h.615V14.61h-.615a2.76 2.76 0 0 1-1.915-.764A2.563 2.563 0 0 1 5.6 12c0-.692.285-1.356.793-1.846a2.76 2.76 0 0 1 1.915-.764h1.477v8.068a1.501 1.501 0 0 0 .468 1.09c.149.144.325.257.52.335.193.077.402.117.612.117V7.847H8.308a4.39 4.39 0 0 0-3.046 1.217A4.078 4.078 0 0 0 4 12Zm11.692-4.153h-.615V9.39h.615a2.76 2.76 0 0 1 1.915.764c.508.49.793 1.154.793 1.846 0 .692-.285 1.356-.793 1.846a2.76 2.76 0 0 1-1.915.764h-1.477V6.542c0-.409-.168-.801-.468-1.09-.3-.29-.707-.452-1.132-.452v11.152h3.077c.566 0 1.126-.107 1.649-.316a4.32 4.32 0 0 0 1.397-.9c.4-.385.718-.843.934-1.347a4.023 4.023 0 0 0 0-3.178 4.146 4.146 0 0 0-.934-1.347 4.322 4.322 0 0 0-1.397-.9 4.448 4.448 0 0 0-1.649-.317Z"
    />
  </svg>
));

export const RefreshIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m10.865 4.323-2.5 2.25v.854l2.5 2.25.77-.854-1.387-1.248h3.002A5.425 5.425 0 0 1 18.675 13v.25h1.15V13a6.575 6.575 0 0 0-6.575-6.575h-3.002l1.386-1.248-.769-.854ZM5.325 11v-.25h-1.15V11a6.575 6.575 0 0 0 6.575 6.575h3.001l-1.386 1.248.77.854 2.5-2.25a.575.575 0 0 0 0-.854l-2.5-2.25-.77.854 1.386 1.248H10.75A5.425 5.425 0 0 1 5.325 11Z"
      clipRule="evenodd"
    />
  </svg>
));

export const SearchIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.325 11a5.675 5.675 0 1 1 11.35 0 5.675 5.675 0 0 1-11.35 0ZM11 4.175a6.825 6.825 0 1 0 4.402 12.04l3.441 3.442.813-.814-3.44-3.44A6.825 6.825 0 0 0 11 4.175Z"
      clipRule="evenodd"
    />
  </svg>
));

export const SendIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.198 4.455 2.818 9.58l-.007 1.088 7.339 2.56 2.56 7.34 1.088-.008 5.127-15.38-.727-.726Zm-7.748 7.661L4.781 10.14l12.69-4.23-4.23 12.69-1.978-5.67 1.866-1.866-.813-.813-1.866 1.866Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ShareIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.425 6.214 9.17 8.641l-.843-.782 3.25-3.5h.843l3.25 3.5-.843.782-2.253-2.427v8.036h-1.15V6.214ZM4.175 16.25v-1.5h1.15v1.5a2.425 2.425 0 0 0 2.425 2.425h8.5a2.425 2.425 0 0 0 2.425-2.425v-1.5h1.15v1.5c0 1.974-1.6 3.575-3.575 3.575h-8.5a3.575 3.575 0 0 1-3.575-3.575Z"
      clipRule="evenodd"
    />
  </svg>
));

export const ShieldTickIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.235 4.225a.575.575 0 0 0-.47 0l-7.25 3.25a.575.575 0 0 0-.339.487L4.75 8l-.574-.038v.011l-.002.028a13.871 13.871 0 0 0-.015.474c-.006.318-.004.77.025 1.313.056 1.08.216 2.534.639 3.997.42 1.458 1.114 2.965 2.263 4.114 1.163 1.163 2.762 1.926 4.914 1.926 2.152 0 3.751-.763 4.914-1.926 1.149-1.149 1.842-2.656 2.263-4.114.423-1.463.583-2.916.64-3.997a19.579 19.579 0 0 0 .014-1.686A8.962 8.962 0 0 0 19.826 8l-.002-.028v-.01L19.25 8l.574-.038a.575.575 0 0 0-.339-.487l-7.25-3.25ZM5.308 8.493c0-.04.002-.079.003-.114L12 5.38l6.69 2.999.002.114c.004.296.003.723-.024 1.235-.053 1.028-.206 2.388-.595 3.737-.392 1.354-1.01 2.66-1.972 3.62-.946.947-2.253 1.59-4.101 1.59-1.848 0-3.155-.643-4.101-1.59-.961-.96-1.58-2.266-1.972-3.62-.39-1.35-.542-2.709-.595-3.737a18.443 18.443 0 0 1-.024-1.235Zm6.158 6.094 3.25-4.5-.932-.674-2.817 3.9-.775-.931-.884.736 1.25 1.5a.575.575 0 0 0 .908-.031Z"
      clipRule="evenodd"
    />
  </svg>
));

export const SpeechBubbleIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.15}
      d="M12 18.25c3.866 0 7.25-2.095 7.25-6.75 0-4.655-3.384-6.75-7.25-6.75S4.75 6.845 4.75 11.5c0 1.768.488 3.166 1.305 4.22.239.31.334.72.168 1.073-.1.215-.207.42-.315.615-.454.816.172 2.005 1.087 1.822 1.016-.204 2.153-.508 3.1-.956a1.15 1.15 0 0 1 .635-.103c.415.053.84.079 1.27.079Z"
    />
  </svg>
));

export const SquareCrossIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M17.25 19.25H6.75a2 2 0 0 1-2-2V6.75a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v10.5a2 2 0 0 1-2 2Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="m9.75 14.25 4.5-4.5m0 4.5-4.5-4.5"
    />
  </svg>
));

export const StampIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.952 5.325a.425.425 0 0 0-.417.502l.99 5.348h2.95l.991-5.348a.425.425 0 0 0-.418-.502H9.952Zm-1.548.712.951 5.138H8.75a3.575 3.575 0 0 0-3.575 3.575v1.5l.575.575h12.5l.575-.575v-1.5c0-1.975-1.6-3.575-3.575-3.575h-.605l.952-5.138a1.575 1.575 0 0 0-1.549-1.862H9.952c-.984 0-1.728.894-1.548 1.862Zm.346 6.288a2.425 2.425 0 0 0-2.425 2.425v.925h11.35v-.925a2.425 2.425 0 0 0-2.425-2.425h-6.5Zm-4 7.5h14.5v-1.15H4.75v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const SwitchIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.25 11.25 4.75 8m0 0 3.5-3.25M4.75 8h10.5m.5 4.75 3.5 3.25m0 0-3.5 3.25m3.5-3.25H8.75"
    />
  </svg>
));

export const TiersIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.175 3.71-6.15 3.976a1.65 1.65 0 0 0-.032 2.75 1.65 1.65 0 0 0 0 2.728 1.65 1.65 0 0 0 .032 2.75l6.15 3.976a1.65 1.65 0 0 0 1.792 0l6.15-3.976a1.65 1.65 0 0 0 .032-2.75 1.65 1.65 0 0 0 0-2.728 1.65 1.65 0 0 0-.032-2.75l-6.15-3.976a1.65 1.65 0 0 0-1.792 0Zm6.916 7.41.402.26a.5.5 0 0 1 0 .84l-6.15 3.975a.5.5 0 0 1-.543 0L5.65 12.22a.5.5 0 0 1 0-.84l.401-.26 5.124 3.313a1.65 1.65 0 0 0 1.792 0l5.124-3.313Zm-10.98-.684 4.689 3.03a.5.5 0 0 0 .542 0l4.69-3.03 1.059-.685.402-.26a.5.5 0 0 0 0-.84l-6.15-3.975a.5.5 0 0 0-.543 0L5.65 8.65a.5.5 0 0 0 0 .84l.401.26 1.06.685ZM5.65 14.108l.401-.26 5.124 3.313a1.65 1.65 0 0 0 1.792 0l5.124-3.312.402.26a.5.5 0 0 1 0 .84l-6.15 3.975a.5.5 0 0 1-.543 0l-6.15-3.976a.5.5 0 0 1 0-.84Z"
      clipRule="evenodd"
    />
  </svg>
));

export const TimerIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.15}
      d="M18.25 13a6.25 6.25 0 1 1-12.5 0 6.25 6.25 0 0 1 12.5 0ZM16.5 8.5l.75-.75M12 6.5V4.75m0 0H9.75m2.25 0h2.25m-2.25 5v3.5"
    />
  </svg>
));

export const TolaIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.85 12a6.85 6.85 0 1 1-13.7 0 6.85 6.85 0 0 1 13.7 0ZM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5-2.5h-2.425v6h-1.15v-6H9V8.35h6V9.5Z"
      clipRule="evenodd"
    />
  </svg>
));

export const TrashIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.75 5.325c-.787 0-1.425.638-1.425 1.425v.425h4.35V6.75c0-.787-.638-1.425-1.425-1.425h-1.5Zm4.075 1.85V6.75a2.575 2.575 0 0 0-2.575-2.575h-1.5A2.575 2.575 0 0 0 8.175 6.75v.425H4.75v1.15h.473l.795 9.148a2.575 2.575 0 0 0 2.566 2.352h5.832a2.575 2.575 0 0 0 2.566-2.352l.795-9.148h.473v-1.15h-3.425Zm1.798 1.15H6.377l.787 9.049c.064.736.68 1.301 1.42 1.301h5.832c.74 0 1.356-.565 1.42-1.302l.787-9.048ZM9.175 16.25v-5.5h1.15v5.5h-1.15Zm3.5-5.5v5.5h1.15v-5.5h-1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const UnknownIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm6-6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-12-6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm6 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm6 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-12 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
));

export const UnorderedListIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.075 6a.075.075 0 1 1-.15 0 .075.075 0 0 1 .15 0ZM6 4.925a1.075 1.075 0 1 0 0 2.15 1.075 1.075 0 0 0 0-2.15Zm3.75 1.65h8.5v-1.15h-8.5v1.15ZM6.075 12a.075.075 0 1 1-.15 0 .075.075 0 0 1 .15 0ZM6 10.925a1.075 1.075 0 1 0 0 2.15 1.075 1.075 0 0 0 0-2.15Zm3.75 1.65h8.5v-1.15h-8.5v1.15ZM6.075 18a.075.075 0 1 1-.15 0 .075.075 0 0 1 .15 0ZM6 16.925a1.075 1.075 0 1 0 0 2.15 1.075 1.075 0 0 0 0-2.15Zm3.75 1.65h8.5v-1.15h-8.5v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const UploadIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.55 4.24a.75.75 0 0 0-1.1 0L8.2 7.74a.75.75 0 1 0 1.1 1.02l1.95-2.1v7.59a.75.75 0 0 0 1.5 0V6.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5ZM4.75 14a.75.75 0 0 1 .75.75v1.5a2.25 2.25 0 0 0 2.25 2.25h8.5a2.25 2.25 0 0 0 2.25-2.25v-1.5a.75.75 0 0 1 1.5 0v1.5A3.75 3.75 0 0 1 16.25 20h-8.5A3.75 3.75 0 0 1 4 16.25v-1.5a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
));

export const UsersIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 5.325a2.175 2.175 0 1 0 0 4.35 2.175 2.175 0 0 0 0-4.35ZM6.175 7.5a3.325 3.325 0 1 1 6.65 0 3.325 3.325 0 0 1-6.65 0ZM5.45 18.343c.147-.72.453-1.675 1.073-2.439.602-.74 1.518-1.329 2.978-1.329 1.46 0 2.377.588 2.978 1.329.621.764.927 1.719 1.073 2.44a.24.24 0 0 1-.058.218.366.366 0 0 1-.274.113H5.782a.366.366 0 0 1-.275-.113.24.24 0 0 1-.058-.219Zm4.05-4.918c-1.829 0-3.065.762-3.87 1.754-.787.968-1.144 2.128-1.308 2.935-.196.965.59 1.711 1.46 1.711h7.437c.869 0 1.655-.746 1.46-1.711-.165-.807-.521-1.967-1.308-2.935-.806-.992-2.042-1.754-3.87-1.754Zm6.25 1.15c.803 0 1.378.401 1.818 1.024.452.64.73 1.479.895 2.222.092.416-.233.854-.786.854h-.927v1.15h.927c1.16 0 2.187-.998 1.908-2.254-.178-.805-.498-1.814-1.078-2.635-.591-.838-1.482-1.511-2.757-1.511v1.15Zm-1-9.25c1.131 0 1.925.899 1.925 2.175 0 1.276-.794 2.175-1.925 2.175v1.15c1.907 0 3.075-1.564 3.075-3.325 0-1.761-1.168-3.325-3.075-3.325v1.15Z"
      clipRule="evenodd"
    />
  </svg>
));

export const XeroIcon = withIcon((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="m5.43 11.989 2.182-2.183a.415.415 0 0 0 .112-.267.4.4 0 0 0-.401-.401.47.47 0 0 0-.29.111l-2.182 2.183-2.183-2.183a.415.415 0 0 0-.267-.111.4.4 0 0 0-.401.4c0 .112.045.201.111.29l2.183 2.183-2.183 2.183c-.089.067-.111.18-.111.29a.4.4 0 0 0 .4.4.369.369 0 0 0 .268-.111l2.183-2.183 2.182 2.183c.09.089.18.111.29.111a.4.4 0 0 0 .4-.4.372.372 0 0 0-.11-.268L5.43 11.989Zm10.824 0a2.88 2.88 0 0 0 2.873 2.873A2.88 2.88 0 0 0 22 11.989a2.88 2.88 0 0 0-2.873-2.873 2.88 2.88 0 0 0-2.873 2.873Zm.824 0c0-1.136.913-2.071 2.071-2.071 1.136 0 2.071.913 2.071 2.07a2.053 2.053 0 0 1-2.07 2.072 2.065 2.065 0 0 1-2.07-2.071h-.002Zm-1.025-2.806h-.113c-.356 0-.713.111-1 .334a.4.4 0 0 0-.379-.312.376.376 0 0 0-.378.378v4.833a.4.4 0 1 0 .801 0v-2.96c0-.98.09-1.38.936-1.492h.156c.223 0 .4-.156.4-.379a.416.416 0 0 0-.423-.4v-.002Zm-2.694 2.272c-.18-.8-.602-1.448-1.27-1.871a2.897 2.897 0 0 0-3.207.089c-.757.535-1.203 1.448-1.203 2.36 0 .224.02.469.09.691.291 1.136 1.271 2.004 2.427 2.136.343.044.69.021 1.024-.067.29-.067.602-.2.869-.356.29-.18.512-.423.735-.69l.02-.02c.156-.201.134-.469-.045-.602-.133-.111-.378-.156-.579.089-.044.067-.089.111-.133.18-.16.184-.348.342-.557.468-.29.156-.624.245-.958.245-1.136-.02-1.737-.8-1.96-1.381-.044-.111-.066-.223-.089-.334v-.067h4.1c.535-.02.824-.423.735-.868v-.002Zm-4.811.067v-.045a2.078 2.078 0 0 1 2.004-1.559 2.1 2.1 0 0 1 2.027 1.604H8.548Z"
    />
  </svg>
));
