import type { VFC } from 'react';
import type {
  SVGComponent,
  SVGComponentProps,
} from '~/components/SVGComponent';
import { classNames } from '~/utils/style';

const iconSizeClassNames = {
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-10 h-10',
  xl: 'w-16 h-16',
};

type IconSize = keyof typeof iconSizeClassNames;

export interface IconProps extends SVGComponentProps {
  /**
   * @default 'md'
   */
  size?: IconSize;
}

export type IconComponent = VFC<IconProps>;

export function withIcon(Component: SVGComponent) {
  function Icon(props: IconProps) {
    const { className, size = 'md', ...rest }: typeof props = props;
    return (
      <Component
        className={classNames(className, iconSizeClassNames[size])}
        {...rest}
      />
    );
  }
  return Icon;
}

export type IconDirection = 0 | 90 | 180 | 270;

export interface RotatingIconProps extends IconProps {
  Icon: SVGComponent;
  direction: IconDirection;
}

export function RotatingIcon(props: RotatingIconProps) {
  const { Icon, direction, className, ...rest } = props;
  return (
    <Icon
      className={classNames(
        'origin-center transition-transform duration-300',
        direction === 90 && 'rotate-90',
        direction === 180 && 'rotate-180',
        direction === 270 && 'rotate-270',
        className,
      )}
      {...rest}
    />
  );
}
