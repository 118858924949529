import { cn } from '~/modules/ui/cva';

/* Common focus state classNames */
export const focusRingClassNames = cn(
  'focus-visible:t2-outline-none focus-visible:t2-ring-2 focus-visible:t2-ring-ring focus-visible:t2-ring-offset-2 focus-visible:t2-ring-offset-surface',
);

/* Common form element classNames */
export const inputClassNames = cn(
  't2-text-body t2-h-10 t2-flex t2-items-center t2-w-full t2-rounded-lg t2-border t2-border-input t2-bg-surface t2-px-3 t2-text-foreground placeholder:t2-text-foreground-secondary',
  '[input[type=file]&]:t2-py-1.5 file:t2-border-0 file:t2-bg-transparent file:t2-text-caption file:t2-font-medium',
  focusRingClassNames,
  'disabled:t2-cursor-not-allowed disabled:t2-bg-input/15 disabled:t2-text-foreground-secondary/70',
  // Fixes clashing with global.css
  'focus:t2-border-input',
);

/* Common popover classnames */
export const popoverClassNames = cn(
  't2-bg-surface t2-text-foreground t2-relative t2-overflow-hidden t2-rounded-lg t2-shadow-popover',
);

export const menuContentClassNames = cn(
  popoverClassNames,
  't2-z-50 t2-min-w-40 t2-p-1',
  'data-[state=open]:t2-animate-in data-[state=closed]:t2-animate-out data-[state=closed]:t2-fade-out-0 data-[state=open]:t2-fade-in-0 data-[state=closed]:t2-zoom-out-95 data-[state=open]:t2-zoom-in-95 data-[side=bottom]:t2-slide-in-from-top-2 data-[side=left]:t2-slide-in-from-right-2 data-[side=right]:t2-slide-in-from-left-2 data-[side=top]:t2-slide-in-from-bottom-2',
);

export const menuItemClassNames = cn(
  't2-relative t2-flex t2-gap-x-2 t2-text-body t2-leading-6 t2-cursor-default t2-items-center t2-rounded-md t2-px-2 t2-py-1.5 t2-transition-colors',
  't2-select-none t2-outline-none focus:t2-bg-surface-muted focus:t2-text-foreground aria-selected:t2-bg-surface-muted aria-selected:t2-text-foreground',
  'aria-disabled:t2-opacity-50 aria-disabled:t2-cursor-not-allowed',
  '[&>svg]:t2-text-foreground-secondary',
);

export const menuItemIndicatorClassNames = cn(
  't2-absolute t2-left-2 t2-flex t2-size-6 t2-items-center t2-justify-center',
  '[&>svg]:t2-size-5 [&>svg]:t2-text-foreground-secondary',
);

export const menuSeparatorClassNames = cn(
  't2-bg-surface-muted t2--mx-1 t2-my-1 t2-h-px',
);

export const menuLabelClassNames = cn(
  't2-text-caption t2-text-foreground-secondary t2-py-1.5 t2-px-2',
);

export const menuShortcutClassNames = cn(
  't2-text-caption t2-ml-auto t2-tracking-widest t2-opacity-60',
);

/* Common Dialog
 * <Dialog />, <AlertDialog />, <Sheet />, etc.
 */

export const dialogOverlayClassNames = cn(
  't2-fixed t2-inset-0 t2-z-50 t2-outline-none t2-backdrop-blur-[60px] t2-backdrop-grayscale-50 t2-will-change-auto t2-bg-surface-inverse/10',
  'data-[state]:t2-duration-400 data-[state]:t2-ease-drawer data-[state=closed]:t2-animate-out data-[state=closed]:t2-fade-out-0 data-[state=open]:t2-animate-in data-[state=open]:t2-fade-in-0',
);

export const dialogContentClassNames = cn(
  // Positioning
  't2-fixed t2-left-[50%] t2-top-[50%] t2-z-50 t2-translate-x-[-50%] t2-translate-y-[-50%]',
  // Sizing
  't2-overflow-x-hidden t2-overflow-y-scroll t2-p-8 t2-w-full t2-max-w-[calc(100vw_-_32px)] sm:t2-max-w-lg t2-max-h-[calc(100vh_-_32px)]',
  // Visual
  't2-grid t2-gap-6 t2-bg-surface-muted t2-rounded-3xl',
  // Animation
  't2-duration-200 data-[state=open]:t2-animate-in data-[state=closed]:t2-animate-out data-[state=closed]:t2-fade-out-0 data-[state=open]:t2-fade-in-0 data-[state=closed]:t2-zoom-out-95 data-[state=open]:t2-zoom-in-95 data-[state=closed]:t2-slide-out-to-left-1/2 data-[state=closed]:t2-slide-out-to-top-[48%] data-[state=open]:t2-slide-in-from-left-1/2 data-[state=open]:t2-slide-in-from-top-[48%]',
);
