import { CreditCardIcon } from '~/components/GeneratedIcon';
import {
  AmexLogo,
  DinersLogo,
  DiscoverLogo,
  JcbLogo,
  MastercardLogo,
  VisaLogo,
} from '~/components/Logo';
import type { SVGComponent } from '~/components/SVGComponent';
import type { IconComponent } from '~/components/withIcon';
import type { ContactAvatarColor } from '~/modules/account/server';
import type { CardScheme } from '~/utils/checkout';
import { cx } from '~/utils/css-utils';

export function Ornament() {
  throw new Error(
    'Ornament is not a component. It is a namespace for ornament components.',
  );
}

type OrnamentSize = 'xs' | 'sm' | 'lg';

export type OrnamentBaseProps = {
  /**  @default 'sm' */
  size?: OrnamentSize;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const ornamentSizeClass = {
  xs: 'h-4 w-4',
  sm: 'h-6 w-6',
  lg: 'h-8 w-8',
};

const OrnamentBase: React.FC<OrnamentBaseProps> = (props) => {
  return (
    <div
      className={cx(
        'flex shrink-0 items-center justify-center transition-all duration-400 ease-drawer',
        ornamentSizeClass[props.size ?? 'sm'],
        props.className,
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

Ornament.Base = OrnamentBase;
Ornament.Base.displayName = 'Ornament.Base';

export type IconOrnamentProps = OrnamentBaseProps & {
  Icon: IconComponent;
  /**
   * @default 'subtle'
   */
} & (
    | {
        variant?: Exclude<IconOrnamentVariant, 'inverse' | 'tint'>;
      }
    | { variant: Extract<IconOrnamentVariant, 'inverse'>; bgColor: string }
    | { variant: Extract<IconOrnamentVariant, 'tint'>; textColor?: string }
  );

type IconOrnamentVariant = 'subtle' | 'emphasis' | 'tint' | 'inverse';

const IconOrnament: React.FC<IconOrnamentProps> = (props) => {
  const { variant = 'subtle' } = props;

  function getInverseBg() {
    if (props.variant !== 'inverse') {
      return;
    }
    const { bgColor = 'bg-black' } = props;
    return bgColor;
  }

  function getTintTextColor() {
    if (props.variant !== 'tint') {
      return;
    }
    const { textColor = 'text-blue-500' } = props;
    return textColor;
  }

  const variants: Record<IconOrnamentVariant, Record<OrnamentSize, string>> = {
    subtle: {
      xs: 'text-gray-500 border-none',
      sm: 'text-gray-500 border-none',
      lg: 'text-gray-900 bg-white border-hairline border-gray-300 rounded-lg',
    },
    emphasis: {
      xs: 'text-gray-900',
      sm: 'text-gray-900',
      lg: 'text-gray-900 border-none bg-gray-100 rounded-lg',
    },
    tint: {
      xs: `border-none ${getTintTextColor()}`,
      sm: `border-none ${getTintTextColor()}`,
      lg: `bg-gray-100 rounded-lg ${getTintTextColor()}`,
    },
    inverse: {
      xs: `text-white rounded-md border-none ${getInverseBg()}`,
      sm: `text-white rounded-md border-none ${getInverseBg()}`,
      lg: `text-white rounded-lg border-none ${getInverseBg()}`,
    },
  };

  return (
    <Ornament.Base
      {...props}
      className={cx(variants[variant][props.size ?? 'sm'], props.className)}
    >
      <props.Icon size="md" />
    </Ornament.Base>
  );
};

Ornament.Icon = IconOrnament;
Ornament.Icon.displayName = 'Ornament.Icon';

interface ContactOrnamentProps extends OrnamentBaseProps {
  color: ContactAvatarColor;
}

const ContactOrnament: React.FC<ContactOrnamentProps> = (props) => {
  const { size = 'sm' } = props;
  return (
    <Ornament.Base {...props} className={cx(size === 'sm' && 'p-1')}>
      <div
        className={cx(
          `h-full w-full`,
          props.color === 'gray' ? 'bg-gray-300' : `bg-${props.color}`,
          props.size === 'lg' ? 'rounded-md' : 'rounded-[4px]',
        )}
      />
    </Ornament.Base>
  );
};

Ornament.Contact = ContactOrnament;
Ornament.Contact.displayName = 'Ornament.Contact';

interface AvatarOrnamentProps extends OrnamentBaseProps {
  name: string;
  src?: string | null;
}

const AvatarOrnament: React.FC<AvatarOrnamentProps> = (props) => {
  const { name, size } = props;
  const sizeClassName = size === 'lg' ? 'h-8 w-8' : 'h-6 w-6';
  const children = props.src ? (
    <img
      alt={`Avatar for ${name}`}
      src={props.src}
      className={cx('rounded-full', sizeClassName)}
    />
  ) : (
    <span
      className={cx(
        'flex items-center justify-center rounded-full bg-gray-100 text-gray-500 uppercase',
        sizeClassName,
      )}
    >
      {name.charAt(0).toUpperCase()}
    </span>
  );

  return (
    <Ornament.Base
      {...props}
      className={cx(
        'overflow-hidden rounded-full text-center leading-6 mix-blend-multiply',
        size === 'lg' ? 'text-[17px]' : 'text-[12px]',
      )}
    >
      {children}
    </Ornament.Base>
  );
};

Ornament.Avatar = AvatarOrnament;
Ornament.Avatar.displayName = 'Ornament.Avatar';

interface ImageOrnamentProps extends OrnamentBaseProps {
  src: string;
  alt: string;
}

const ImageOrnament: React.FC<ImageOrnamentProps> = (props) => {
  return (
    <Ornament.Base
      {...props}
      className={cx(
        `overflow-hidden rounded-full bg-center bg-cover bg-no-repeat`,
        props.className,
      )}
      aria-label={props.alt}
      style={{
        backgroundImage: `url(${props.src})`,
      }}
    >
      {/* Dynamic border */}
      <div
        className={cx(
          'flex h-full w-full rounded-full border-gray-300 border-hairline mix-blend-multiply',
        )}
      />
    </Ornament.Base>
  );
};

Ornament.Image = ImageOrnament;
Ornament.Image.displayName = 'Ornament.Image';

interface CardOrnamentProps extends OrnamentBaseProps {
  scheme: CardScheme | 'unknown';
}

const CardOrnament: React.FC<CardOrnamentProps> = (props) => {
  const variants: Record<OrnamentSize, string> = {
    xs: '',
    sm: '',
    lg: 'bg-white border-hairline border-gray-300 rounded-lg',
  };

  const logos: Record<NonNullable<CardScheme | 'unknown'>, SVGComponent> = {
    visa: VisaLogo,
    mastercard: MastercardLogo,
    amex: AmexLogo,
    discover: DiscoverLogo,
    diners: DinersLogo,
    jcb: JcbLogo,
    unknown: CreditCardIcon,
  };

  const Logo = props.scheme ? logos[props.scheme] : CreditCardIcon;

  return (
    <Ornament.Base {...props} className={variants[props.size ?? 'sm']}>
      <Logo className="w-6" />
    </Ornament.Base>
  );
};

Ornament.Card = CardOrnament;
Ornament.Card.displayName = 'Ornament.Card';

Ornament.Number = function NumberOrnament(
  props: OrnamentBaseProps & {
    number: number;
  },
) {
  const sizeClassName = props.size === 'lg' ? 'h-8 w-8' : 'h-6 w-6';

  return (
    <Ornament.Base
      {...props}
      className={cx(
        'overflow-hidden rounded-full text-center leading-6 mix-blend-multiply',
        props.size === 'lg' ? 'text-[17px]' : 'text-[12px]',
      )}
    >
      <span
        className={cx(
          'flex items-center justify-center rounded-full bg-gray-100 text-gray-500 uppercase',
          sizeClassName,
        )}
      >
        {props.number}
      </span>
    </Ornament.Base>
  );
};
