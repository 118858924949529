// @ts-check

const baseColors = /** @type {const} */ ({
  white: 'rgb(var(--white) / <alpha-value>)',
  black: 'rgb(var(--black) / <alpha-value>)',
  red: {
    50: 'rgb(var(--red-50) / <alpha-value>)',
    100: 'rgb(var(--red-100) / <alpha-value>)',
    200: 'rgb(var(--red-200) / <alpha-value>)',
    300: 'rgb(var(--red-300) / <alpha-value>)',
    400: 'rgb(var(--red-400) / <alpha-value>)',
    500: 'rgb(var(--red-500) / <alpha-value>)',
    600: 'rgb(var(--red-600) / <alpha-value>)',
    700: 'rgb(var(--red-700) / <alpha-value>)',
    800: 'rgb(var(--red-800) / <alpha-value>)',
    900: 'rgb(var(--red-900) / <alpha-value>)',
    DEFAULT: 'rgb(var(--red-default) / <alpha-value>)',
  },
  orange: {
    50: 'rgb(var(--orange-50) / <alpha-value>)',
    100: 'rgb(var(--orange-100) / <alpha-value>)',
    200: 'rgb(var(--orange-200) / <alpha-value>)',
    300: 'rgb(var(--orange-300) / <alpha-value>)',
    400: 'rgb(var(--orange-400) / <alpha-value>)',
    500: 'rgb(var(--orange-500) / <alpha-value>)',
    600: 'rgb(var(--orange-600) / <alpha-value>)',
    700: 'rgb(var(--orange-700) / <alpha-value>)',
    800: 'rgb(var(--orange-800) / <alpha-value>)',
    900: 'rgb(var(--orange-900) / <alpha-value>)',
    DEFAULT: 'rgb(var(--orange-default) / <alpha-value>)',
  },
  yellow: {
    50: 'rgb(var(--yellow-50) / <alpha-value>)',
    100: 'rgb(var(--yellow-100) / <alpha-value>)',
    200: 'rgb(var(--yellow-200) / <alpha-value>)',
    300: 'rgb(var(--yellow-300) / <alpha-value>)',
    400: 'rgb(var(--yellow-400) / <alpha-value>)',
    500: 'rgb(var(--yellow-500) / <alpha-value>)',
    600: 'rgb(var(--yellow-600) / <alpha-value>)',
    700: 'rgb(var(--yellow-700) / <alpha-value>)',
    800: 'rgb(var(--yellow-800) / <alpha-value>)',
    900: 'rgb(var(--yellow-900) / <alpha-value>)',
    DEFAULT: 'rgb(var(--yellow-default) / <alpha-value>)',
  },
  green: {
    50: 'rgb(var(--green-50) / <alpha-value>)',
    100: 'rgb(var(--green-100) / <alpha-value>)',
    200: 'rgb(var(--green-200) / <alpha-value>)',
    300: 'rgb(var(--green-300) / <alpha-value>)',
    400: 'rgb(var(--green-400) / <alpha-value>)',
    500: 'rgb(var(--green-500) / <alpha-value>)',
    600: 'rgb(var(--green-600) / <alpha-value>)',
    700: 'rgb(var(--green-700) / <alpha-value>)',
    800: 'rgb(var(--green-800) / <alpha-value>)',
    900: 'rgb(var(--green-900) / <alpha-value>)',
    DEFAULT: 'rgb(var(--green-default) / <alpha-value>)',
  },
  blue: {
    50: 'rgb(var(--blue-50) / <alpha-value>)',
    100: 'rgb(var(--blue-100) / <alpha-value>)',
    200: 'rgb(var(--blue-200) / <alpha-value>)',
    300: 'rgb(var(--blue-300) / <alpha-value>)',
    400: 'rgb(var(--blue-400) / <alpha-value>)',
    500: 'rgb(var(--blue-500) / <alpha-value>)',
    600: 'rgb(var(--blue-600) / <alpha-value>)',
    700: 'rgb(var(--blue-700) / <alpha-value>)',
    800: 'rgb(var(--blue-800) / <alpha-value>)',
    900: 'rgb(var(--blue-900) / <alpha-value>)',
    DEFAULT: 'rgb(var(--blue-default) / <alpha-value>)',
  },
  gray: {
    50: 'rgb(var(--gray-50) / <alpha-value>)',
    100: 'rgb(var(--gray-100) / <alpha-value>)',
    200: 'rgb(var(--gray-200) / <alpha-value>)',
    300: 'rgb(var(--gray-300) / <alpha-value>)',
    400: 'rgb(var(--gray-400) / <alpha-value>)',
    500: 'rgb(var(--gray-500) / <alpha-value>)',
    600: 'rgb(var(--gray-600) / <alpha-value>)',
    700: 'rgb(var(--gray-700) / <alpha-value>)',
    800: 'rgb(var(--gray-800) / <alpha-value>)',
    900: 'rgb(var(--gray-900) / <alpha-value>)',
  },
  brand: {
    secondary: {
      azure: 'rgb(var(--azure) / <alpha-value>)',
      klein: 'rgb(var(--klein) / <alpha-value>)',
      teal: 'rgb(var(--teal) / <alpha-value>)',
      lightBlue: 'rgb(var(--lightBlue) / <alpha-value>)',
      cheese: 'rgb(var(--cheese) / <alpha-value>)',
      pink: 'rgb(var(--pink) / <alpha-value>)',
      brandOrange: 'rgb(var(--brandOrange) / <alpha-value>)',
      lilac: 'rgb(var(--lilac) / <alpha-value>)',
      softBlue: 'rgb(var(--softBlue) / <alpha-value>)',
      aubergine: 'rgb(var(--aubergine) / <alpha-value>)',
      seafoam: 'rgb(var(--seafoam) / <alpha-value>)',
      spearmint: 'rgb(var(--spearmint) / <alpha-value>)',
      pine: 'rgb(var(--pine) / <alpha-value>)',
      safari: 'rgb(var(--safari) / <alpha-value>)',
      // Guillaume: Removing as Red is perceived as negative.
      // If we decide to re-add it, let's discuss.
      // matteRed: 'rgb(var(--matteRed) / <alpha-value>)',
    },

    tolablue: {
      DEFAULT: 'rgb(var(--tolablue-default) / <alpha-value>)',
      light: 'rgb(var(--tolablue-light) / <alpha-value>)',
      contrast: 'rgb(var(--tolablue-contrast) / <alpha-value>)',
    },
    carrotjuice: {
      DEFAULT: 'rgb(var(--carrotjuice-default) / <alpha-value>)',
      light: 'rgb(var(--carrotjuice-light) / <alpha-value>)',
      contrast: 'rgb(var(--carrotjuice-contrast) / <alpha-value>)',
    },
    telio: {
      DEFAULT: 'rgb(var(--telio-default) / <alpha-value>)',
      light: 'rgb(var(--telio-light) / <alpha-value>)',
      contrast: 'rgb(var(--telio-contrast) / <alpha-value>)',
    },
    nicewin95: {
      DEFAULT: 'rgb(var(--nicewin95-default) / <alpha-value>)',
      light: 'rgb(var(--nicewin95-light) / <alpha-value>)',
      contrast: 'rgb(var(--nicewin95-contrast) / <alpha-value>)',
    },

    series: [
      'rgb(var(--azure) / <alpha-value>)',
      'rgb(var(--klein) / <alpha-value>)',
      'rgb(var(--pine) / <alpha-value>)',
      'rgb(var(--softBlue) / <alpha-value>)',
      'rgb(var(--teal) / <alpha-value>)',
      'rgb(var(--seafoam) / <alpha-value>)',
      'rgb(var(--spearmint) / <alpha-value>)',
      'rgb(var(--aubergine) / <alpha-value>)',
      'rgb(var(--safari) / <alpha-value>)',
      'rgb(var(--lilac) / <alpha-value>)',
      'rgb(var(--matteRed) / <alpha-value>)',
      'rgb(var(--pink) / <alpha-value>)',
      'rgb(var(--brandOrange) / <alpha-value>)',
      'rgb(var(--cheese) / <alpha-value>)',
    ],
  },
});

const twPalette = /** @type {const} */ ({
  all: baseColors,
  fg: {
    initial: 'initial',
    inherit: 'inherit',
    current: 'currentcolor',
    transparent: 'transparent',
    base: baseColors.gray[900],
    'base-muted': baseColors.gray[500],
    'base-inverse': baseColors.white,
    emphasis: baseColors.blue[500],
    'emphasis-contrast': baseColors.blue[900],
    success: baseColors.green[700],
    'success-contrast': baseColors.green[900],
    warning: baseColors.orange[700],
    'warning-contrast': baseColors.orange[900],
    danger: baseColors.red[700],
    'danger-contrast': baseColors.red[900],
  },
  bg: {
    initial: 'initial',
    current: 'currentcolor',
    transparent: 'transparent',
    base: baseColors.white,
    'base-muted': baseColors.gray[50],
    'base-inverse': baseColors.gray[900],
    emphasis: baseColors.blue[500],
    'emphasis-muted': baseColors.blue[50],
    success: baseColors.green[500],
    'success-muted': baseColors.green[50],
    warning: baseColors.orange[500],
    'warning-muted': baseColors.orange[50],
    danger: baseColors.red[500],
    'danger-muted': baseColors.red[50],
    brand: baseColors.brand,
    invoice: {
      dark: {
        gray: '#0B0C0D',
        red: '#AE4848',
        blue: '#605AA9',
        yellow: '#A9985A',
        green: '#2F4E33',
        tolaBlue: '#0072F9',
      },
      light: {
        gray: '#E2E5E9',
        red: '#FEE3E7',
        blue: '#E1DDFE',
        yellow: '#FEF7DD',
        green: '#E2FEDD',
      },
    },
  },
  series: baseColors.brand.series,
});

const invoiceThemeColorSafelist = Object.entries(twPalette.bg.invoice).flatMap(
  ([variant, value]) =>
    Object.keys(value).flatMap((color) => {
      const key = `bg-invoice-${variant}-${color}`;
      return [key, `hover:${key}`, `before:${key}`];
    }),
);

/**
 * @param {string} color
 */
function withOpacity(color) {
  return color.replace('<alpha-value>', '1');
}

/**
 *
 * @param {*} obj
 */
function applyOpacityToAllStrings(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = withOpacity(obj[key]);
    } else if (typeof obj[key] === 'object') {
      applyOpacityToAllStrings(obj[key]);
    }
  }
}

function applyOpacity() {
  /**
   * @type {typeof twPalette}
   */
  const palette = JSON.parse(JSON.stringify(twPalette));
  applyOpacityToAllStrings(palette);
  return palette;
}

const palette = applyOpacity();

module.exports = { palette, twPalette, invoiceThemeColorSafelist };
