'use client';

import { Dialog, Transition } from '@headlessui/react';
import type { NextPage } from 'next';
import Router, { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Button } from '~/components/Button';
import type { CRC } from '~/components/CRC';
import type { DropdownItemProps } from '~/components/Dropdown';
import { Dropdown } from '~/components/Dropdown';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import {
  AddIcon,
  ArrowDownLeftIcon,
  ArrowLeftIcon,
  ArrowUpRightIcon,
  AsteriskIcon,
  BankIcon,
  BellIcon,
  CheckIcon,
  CogIcon,
  CreditCardIcon,
  DashboardIcon,
  FaceHappyIcon,
  MailIcon,
  MenuIcon,
  RefreshIcon,
  SpeechBubbleIcon,
  StampIcon,
  TiersIcon,
  UsersIcon,
} from '~/components/Icon';
import { NoSSRSuspense } from '~/components/NoSSRSuspense';
import { SpinnerIcon } from '~/components/Spinner';
import { createWizard } from '~/components/Wizard';
import { HStack } from '~/components/containers/Stack';
import { SideNav } from '~/components/layout/SideNav';
import { Ornament } from '~/components/next/atoms/Ornament';
import { Typography } from '~/components/next/foundation/Typography';
import { useLogout } from '~/hooks/useLogout';
import { useViewer } from '~/hooks/useOrg';
import { createCtx } from '~/utils/context';
import { cx } from '~/utils/css-utils';
import { randomDeterministicColors } from '~/utils/randomDeterministicColor';

const topMenuProps = {
  relative: false,
  pad: false,
  openDirection: 'down' as const,
  openSide: 'manual' as const,
  className: 'min-w-0 grow',
  itemsClassName: '-left-3 -right-3',
};
function TopMenuItem(
  props: { ornament: ReactNode; children: string } & DropdownItemProps,
) {
  const { ornament, children, ...rest } = props;
  return (
    <Dropdown.Item pad={false} {...rest}>
      {ornament}
      <span className="truncate">{children}</span>
    </Dropdown.Item>
  );
}

const [useLayoutProvider, LayoutProvider] = createCtx<{
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}>();

const ProfileMenu = () => {
  const viewer = useViewer();
  const logout = useLogout();

  return (
    <Dropdown
      button={
        <Dropdown.Button
          as="button"
          className="rounded-full hover:opacity-70"
          data-test-id="profile-menu"
        >
          <Ornament.Avatar
            size="lg"
            name={viewer.displayName}
            src={viewer.image ?? undefined}
          />
        </Dropdown.Button>
      }
      {...topMenuProps}
    >
      <TopMenuItem
        href={viewer.pathFor('/settings/profile')}
        ornament={<Ornament.Icon size="sm" Icon={CogIcon} />}
      >
        User preferences
      </TopMenuItem>
      <TopMenuItem
        onClick={() =>
          logout.logout({
            callbackUrl: '/org',
          })
        }
        disabled={logout.loading}
        ornament={
          <>
            {!logout.loading && (
              <Ornament.Icon size="sm" Icon={ArrowLeftIcon} />
            )}
            <Transition
              show={logout.loading}
              enter="transition-transform duration-300"
              enterFrom="translate-x-2 opacity-50"
              enterTo="translate-x-0 opacity-100"
            >
              <Ornament.Icon Icon={SpinnerIcon} size="sm" />
            </Transition>
          </>
        }
      >
        {logout.loading ? 'Logging out...' : 'Log out'}
      </TopMenuItem>
    </Dropdown>
  );
};

const OrganizationMenu = () => {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <Dropdown
      button={
        <Dropdown.Button
          as="button"
          className={cx(
            'grid h-8 w-[stretch] grid-cols-[min-content_1fr] items-center gap-2 rounded pr-[13px] pl-1 hover:bg-gray-50',
          )}
          data-test-id="organization-menu"
          aria-label="Change organization"
        >
          <Ornament.Contact
            size="sm"
            color={randomDeterministicColors(viewer.organization.displayName)}
          />
          <Typography
            as="span"
            variant="body"
            className="truncate text-left text-gray-500"
          >
            {viewer.organization.displayName}
          </Typography>
        </Dropdown.Button>
      }
      {...topMenuProps}
    >
      {viewer.memberships.length > 1 && (
        <>
          {viewer.memberships.map((membership) => {
            if (membership.organization.id === viewer.organization.id) {
              return null;
            }
            if (membership.organization.type !== 'COMPANY') {
              return null;
            }
            return (
              <TopMenuItem
                key={membership.organization.id}
                href={{
                  pathname: router.pathname,
                  query: { slug: membership.organization.slug },
                }}
                ornament={
                  <Ornament.Contact
                    size="sm"
                    color={randomDeterministicColors(
                      membership.organization.displayName,
                    )}
                  />
                }
              >
                {membership.organization.displayName}
              </TopMenuItem>
            );
          })}

          <div
            className={`before:-left-3 before:-right-3 relative h-6 w-full before:absolute before:top-1/2 before:translate-y-1/2 before:border-gray-300 before:border-t-hairline before:content-['']`}
          />
        </>
      )}

      <TopMenuItem
        href={{ pathname: '/org/new' }}
        ornament={<Ornament.Icon Icon={AddIcon} />}
      >
        Add organization
      </TopMenuItem>
    </Dropdown>
  );
};

const NavWizard = createWizard({
  start: 'default',
  end: ['default', 'settings'],
  steps: ['default', 'settings'],
  schema: {},
});

NavWizard.setTransition('horizontal');
NavWizard.useSingleLayout = true;
NavWizard.Layout = function Layout(props) {
  return (
    <aside
      data-test-id="navigation-pane"
      className="relative flex h-fill w-full flex-1 justify-between gap-6 overflow-hidden overflow-y-auto border-r-gray-300 border-r-hairline"
      {...props}
    />
  );
};
NavWizard.Step = function Step(props) {
  return (
    <div className="flex h-full w-full flex-1 flex-col justify-between gap-6 bg-white px-6 pt-8">
      {props.children}
    </div>
  );
};

function DefaultSideBar() {
  const wizard = NavWizard.useContext();
  const viewer = useViewer();
  const intercom = useIntercom();
  const pathFor = viewer.pathFor;

  return (
    <NavWizard.Step>
      <SideNav>
        <div className="h-full max-h-[49px]">
          <div className="relative inline-flex w-full justify-between gap-2">
            <div>
              <OrganizationMenu />
            </div>

            <div>
              <ProfileMenu />
            </div>
          </div>
        </div>
        <SideNav.Group>
          <SideNav.Link name="Home" href={pathFor('/')} Icon={DashboardIcon} />
          <SideNav.Link
            name="Pay"
            href={pathFor('/pay')}
            Icon={ArrowUpRightIcon}
          />
          <SideNav.Link
            name="Get paid"
            href={pathFor('/get-paid')}
            Icon={ArrowDownLeftIcon}
          />
          {viewer.organization.financingLimit !== null && (
            <SideNav.Link
              name="Financing"
              href={viewer.pathFor('/financing')}
              Icon={AsteriskIcon}
            />
          )}
          <SideNav.Link
            name="Contacts"
            href={pathFor('/contacts')}
            Icon={FaceHappyIcon}
          />
        </SideNav.Group>
        <SideNav.Group>
          {viewer.membership.baasStatus === 'CONNECTED' && (
            <SideNav.Link
              name="Refer a company"
              Icon={CheckIcon}
              href={pathFor('/refer')}
            />
          )}
          <SideNav.Button
            name="Support"
            Icon={SpeechBubbleIcon}
            onClick={() => {
              intercom.show();
            }}
          />
          <SideNav.Link
            name="Settings"
            href={pathFor('/settings')}
            Icon={CogIcon}
            onClick={(e) => {
              // Skip the default behavior of navigating to the settings page and instead push the settings step
              // This is in order to prevent jarring behavior when navigating to the settings page on mobile as the menu will change next time you open it
              e.preventDefault();
              wizard.push('settings');
            }}
          />
        </SideNav.Group>
      </SideNav>
    </NavWizard.Step>
  );
}

function SettingsSideBar() {
  const wizard = NavWizard.useContext();
  const viewer = useViewer();

  return (
    <NavWizard.Step>
      <SideNav>
        <div className="h-full max-h-[49px]">
          <HStack gap="2" justifyContent="start">
            <Button
              variant="subtle"
              onClick={() => {
                wizard.back();
              }}
              EndIcon={ArrowLeftIcon}
            />
          </HStack>
        </div>
        <SideNav.Group>
          <SideNav.Link
            name={`${viewer.firstName} ${viewer.lastName}`}
            href={viewer.pathFor('/settings/profile')}
            ornament={
              <Ornament.Avatar
                name={`${viewer.firstName} ${viewer.lastName}`}
                src={viewer.image ?? undefined}
              />
            }
          />
        </SideNav.Group>
        <SideNav.Group>
          <SideNav.Link
            name="General"
            href={viewer.pathFor('/settings')}
            ornament={
              <Ornament.Icon
                Icon={CogIcon}
                bgColor="bg-gray-500"
                variant="inverse"
              />
            }
          />

          <SideNav.Link
            name="Tiers"
            href={viewer.pathFor('/settings/tiers')}
            ornament={
              <Ornament.Icon
                Icon={TiersIcon}
                bgColor="bg-gray-500"
                variant="inverse"
              />
            }
          />
        </SideNav.Group>
        <SideNav.Group>
          <SideNav.Link
            name="Bank accounts"
            href={viewer.pathFor('/settings/accounts')}
            ornament={
              <Ornament.Icon
                Icon={BankIcon}
                bgColor="bg-blue-500"
                variant="inverse"
              />
            }
          />
          <SideNav.Link
            name="Cards"
            href={viewer.pathFor('/settings/cards')}
            ornament={
              <Ornament.Icon
                Icon={CreditCardIcon}
                bgColor="bg-blue-500"
                variant="inverse"
              />
            }
          />
        </SideNav.Group>
        <SideNav.Group>
          <SideNav.Link
            name="Accounting"
            href={viewer.pathFor('/settings/accounting')}
            ornament={
              <Ornament.Icon
                Icon={RefreshIcon}
                bgColor="bg-brand-nicewin95"
                variant="inverse"
              />
            }
          />
          <SideNav.Link
            name="Approvals"
            href={viewer.pathFor('/settings/approvals')}
            ornament={
              <Ornament.Icon
                Icon={StampIcon}
                bgColor="bg-[#089173]"
                variant="inverse"
              />
            }
          />
          {viewer.can('organization.edit') && (
            <SideNav.Link
              name="Reminders"
              href={viewer.pathFor('/settings/reminders')}
              ornament={
                <Ornament.Icon
                  Icon={BellIcon}
                  bgColor="bg-[#089173]"
                  variant="inverse"
                />
              }
            />
          )}
          <SideNav.Link
            name="Email forwarding"
            href={viewer.pathFor('/settings/email-forwarding')}
            ornament={
              <Ornament.Icon
                Icon={MailIcon}
                bgColor="bg-[#089173]"
                variant="inverse"
              />
            }
          />
        </SideNav.Group>
        <SideNav.Group>
          <SideNav.Link
            name="Members"
            href={viewer.pathFor('/settings/members')}
            ornament={
              <Ornament.Icon
                Icon={UsersIcon}
                bgColor="bg-brand-carrotjuice"
                variant="inverse"
              />
            }
          />
        </SideNav.Group>
      </SideNav>
    </NavWizard.Step>
  );
}

function normalizeAsPath(asPath: string) {
  return asPath.replace(/\/org\/[^/?]+/, '/org/[slug]').split('?')[0];
}

const Sidebar = () => {
  const { asPath } = useRouter();
  const normalizedAsPath = normalizeAsPath(asPath);
  const isSettingPath =
    normalizedAsPath && normalizedAsPath.startsWith('/org/[slug]/settings');

  return (
    <NavWizard
      closeable={false}
      steps={{ default: <DefaultSideBar />, settings: <SettingsSideBar /> }}
      history={isSettingPath ? ['default', 'settings'] : ['default']}
    />
  );
};

function MobileMenuButton() {
  const layout = useLayoutProvider();
  return (
    <header className="pointer-events-none fixed top-0 z-50 px-4 pt-8 sm:px-8 lg:hidden">
      <button
        className={cx(
          'pointer-events-auto flex items-center rounded-full bg-white shadow-md',
        )}
        aria-label="Open the sidebar"
        onClick={() => layout.setSidebarOpen(true)}
        data-test-id="organization-menu"
      >
        <MenuIcon className={cx('h-8 w-8 p-1')} />
      </button>
    </header>
  );
}

const MobileSidebar: CRC = (props) => {
  const { setSidebarOpen, sidebarOpen } = useLayoutProvider();
  useEffect(() => {
    type OnRoutechangeStartFn = (
      asPath: string,
      transitionOptions: { shallow: boolean },
    ) => void;

    const onRouteChangeStart: OnRoutechangeStartFn = () => {
      setSidebarOpen(false);
    };
    Router.events.on('routeChangeStart', onRouteChangeStart);
    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [setSidebarOpen]);

  return (
    <>
      <MobileMenuButton />
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-dialog flex lg:hidden"
          onClose={() => {
            setSidebarOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-30"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-30"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-sidenav max-w-xs flex-1 flex-col">
              {props.children}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export function PageLayout(props: { children: ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <LayoutProvider value={{ sidebarOpen, setSidebarOpen }}>
      <div className="theme-light flex min-h-screen">
        <MobileSidebar>
          <Sidebar />
        </MobileSidebar>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-sidenav lg:flex-col">
          {/* Static sidebar for desktop */}
          <Sidebar />
        </div>
        <main className="flex flex-1 flex-col lg:pl-sidenav">
          <ErrorBoundary>
            <NoSSRSuspense>{props.children}</NoSSRSuspense>
          </ErrorBoundary>
        </main>
      </div>
    </LayoutProvider>
  );
}

/**
 * Layout content
 */
PageLayout.Content = function LayoutContent(props: {
  /**
   * Title of the page
   */
  title: string;
  children: ReactNode;
}) {
  return (
    <>
      <PageLayout.ContentPadding>{props.children}</PageLayout.ContentPadding>
    </>
  );
};

PageLayout.ContentPadding = function LayoutContentPadding(props: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={cx('p-4 sm:p-6 md:p-8', props.className)}>
      {props.children}
    </div>
  );
};

export type NextPageWithLayout<TProps = Record<string, unknown>> =
  NextPage<TProps> & {
    getLayout?: (page: ReactElement) => ReactNode;
    /**
     * Require MFA to be set up before rendering the page
     * Is `true` of any `/org/*` page, and `false` for all other pages
     */
    requireMfaIfLoggedIn?: boolean;
  };
