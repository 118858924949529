// ℹ️ Type-only import: Do not remove 'type' from 'import type' here.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import { TRPCClientError } from '@trpc/react-query';
import { TRPCBase } from '~/server/trpc';

export function isTRPCClientError(
  cause: unknown,
): cause is TRPCClientError<TRPCBase> {
  return cause instanceof TRPCClientError;
}
