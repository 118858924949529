import { useEffect, useRef } from 'react';
import { useEventCallback } from '~/hooks/useEventCallback';

/**
 * From https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
 */
export function useTimeout(_callback: () => void, delay: number | null) {
  const timeoutRef = useRef<number>();

  const callback = useEventCallback(_callback);

  useEffect(() => {
    const tick = () => callback();
    if (delay) {
      const timerId = window.setTimeout(tick, delay);
      timeoutRef.current = timerId;
      return () => window.clearTimeout(timerId);
    }
  }, [delay, callback]);

  return timeoutRef;
}
