import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { Spinner } from '~/components/Spinner';
import { useOnMount } from '~/hooks/useOnMount';
import type { CRC } from './CRC';

export interface RedirectProps {
  href: LinkProps['href'];
}

/**
 * Isomorphic redirect to another page.
 * Optionally takes `children` to display a client-side loading state whilst doing the redirect.
 */
// `pathname` is a string and not `Route` so it can be used in `_redirectTo`
export const Redirect: CRC<RedirectProps> = (props) => {
  const router = useRouter();

  // Client-side redirect
  useOnMount(() => {
    void router.replace(props.href);
  });

  return <>{props.children}</>;
};

export const RedirectWithSpinner = (props: RedirectProps) => {
  return (
    <Redirect {...props}>
      <Spinner />
    </Redirect>
  );
};
