import type { CRC } from '~/components/CRC';

export const Single: CRC = (props) => {
  return (
    <div
      className="flex h-fill min-h-fill w-full flex-col text-gray-700"
      {...props}
    >
      {props.children}
    </div>
  );
};
