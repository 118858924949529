/**
 * Helper function to use to conditionally apply `className`s
 *
 * @deprecated use `cx` instead from `css-utils.ts`.
 */
export function classNames(
  ...args: (string | undefined | null | false | 0 | 0n)[]
) {
  return args.filter(Boolean).join(' ');
}
