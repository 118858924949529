import { Transition } from '@headlessui/react';
import type { ReactNode } from 'react';
import { classNames } from '~/utils/style';

export type AutoSplitLayoutProps = {
  main: ReactNode;
  aside: ReactNode;
  emptySelection?: ReactNode;
  showAside?: boolean;
  'data-test-id'?: string;
};

export function AutoSplitLayout(props: AutoSplitLayoutProps) {
  return (
    <AutoSplitLayout.Root data-test-id={props['data-test-id']}>
      <AutoSplitLayout.Main>{props.main}</AutoSplitLayout.Main>

      <div className="relative flex-1">
        {props.emptySelection && (
          <div
            aria-hidden={props.showAside ?? false}
            className={classNames(
              'absolute inset-0 h-full bg-white',
              props.showAside
                ? 'pointer-events-none opacity-0'
                : 'hidden opacity-100 md:flex',
            )}
          >
            {props.emptySelection}
          </div>
        )}

        <AutoSplitLayout.Aside show={props.showAside ?? false}>
          {props.aside}
        </AutoSplitLayout.Aside>
      </div>
    </AutoSplitLayout.Root>
  );
}

AutoSplitLayout.Root = function AutoSplitLayoutRoot(props: {
  children: ReactNode;
  'data-test-id'?: string;
}) {
  return (
    <section
      className="divide relative grid h-fill grid-cols-1 overflow-hidden md:grid-cols-2 md:divide-x-hairline md:divide-gray-300"
      {...props}
    />
  );
};

AutoSplitLayout.Main = function AutoSplitLayoutPane(props: {
  children: ReactNode;
}) {
  return <main className="h-fill" {...props} />;
};

AutoSplitLayout.Aside = function AutoSplitLayoutPane(props: {
  children: ReactNode;
  show: boolean;
}) {
  return (
    <Transition
      appear
      as="aside"
      show={props.show}
      unmount={false}
      className="fixed inset-0 z-dialog flex h-full flex-1 items-center bg-white md:relative md:z-0"
      enter="ease-drawer transition-all duration-250"
      enterFrom="opacity-0 translate-x-10 md:translate-x-0"
      enterTo="opacity-100 translate-x-0 md:translate-x-0"
      leave="ease-drawer transition-all duration-250"
      leaveFrom="opacity-100 translate-x-0 md:translate-x-0"
      leaveTo="opacity-0 translate-x-10 md:translate-x-0"
      aria-hidden={!props.show}
    >
      {props.children}
    </Transition>
  );
};
