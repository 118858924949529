import { useIntercom } from 'react-use-intercom';

export const IntercomArticleHelp = (props: {
  text: string;
  intercomArticleId: number;
}) => {
  const intercom = useIntercom();
  return (
    <button
      className="text-left underline-offset-2 opacity-75 hover:underline hover:opacity-100"
      onClick={() => {
        intercom.showArticle(props.intercomArticleId);
      }}
    >
      {props.text}
    </button>
  );
};
