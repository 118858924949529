import { AutoSplitLayout } from '~/components/next/layout/AutoSplitLayout';
import { BaseLayout } from '~/components/next/layout/BaseLayout';
import { SplitLayout } from '~/components/next/layout/SplitLayout';

export function Layout() {
  throw new Error(
    'Layout is not a component. It is a namespace for layout components.',
  );
}

Layout.Base = BaseLayout;
Layout.AutoSplit = AutoSplitLayout;
Layout.Split = SplitLayout;
