import { Slot } from '@radix-ui/react-slot';
import type { VariantProps } from 'cva';
import type { ComponentPropsWithoutRef, ElementType } from 'react';
import React, { forwardRef } from 'react';
import type { SetRequired } from 'type-fest';
import { cva } from '~/utils/css-utils';
import type {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '~/utils/polymorphic';

export const typographicVariants = cva({
  base: '',
  variants: {
    variant: {
      inherit: 'text-inherit',
      title: 'text-title',
      headline: 'text-headline',
      subheadline: 'text-subheadline',
      body: 'text-body',
      caption: 'text-caption',
      footnote: 'text-footnote',
    },
    weight: {
      400: 'font-400',
      500: 'font-500',
      600: 'font-600',
    },
    features: {
      tnums: 'tabular-nums',
    },
  },
  defaultVariants: {
    weight: 400,
  },
});

export type TypographicVariants = VariantProps<typeof typographicVariants>;

export type TypographyProps<TTag extends ElementType> = SetRequired<
  PolymorphicComponentPropsWithRef<TTag, TypographicVariants>,
  'as'
>;

type TypographyElement = <TTag extends React.ElementType>(
  props: TypographyProps<TTag>,
) => React.ReactElement | null;

/**
 * @deprecated Use named components instead (`<Title>, <Headline>, ...etc`)
 */
export const Typography = forwardRef(function Typography<
  TTag extends ElementType,
>(props: TypographyProps<TTag>, forwardedRef?: PolymorphicRef<TTag>) {
  const { as, children, className, variant, monospace, ...rest } = props;

  const Component = as;

  return (
    <Component
      className={typographicVariants({
        variant,
        features: monospace && 'tnums',
        className,
      })}
      ref={forwardedRef}
      {...rest}
    >
      {children}
    </Component>
  );
}) as TypographyElement;
interface TextProps extends TypographicVariants {
  asChild?: boolean;
}

export const Text = forwardRef<
  HTMLDivElement,
  TextProps & ComponentPropsWithoutRef<'span'>
>(function TextWithRef(
  { asChild, children, className, variant, weight, features, ...props },
  forwardedRef,
) {
  const Comp = asChild ? Slot : 'span';

  return (
    <Comp
      ref={forwardedRef}
      className={typographicVariants({
        variant,
        weight,
        features,
        className,
      })}
      {...props}
    >
      {children}
    </Comp>
  );
});

export const Title = forwardRef<
  HTMLHeadingElement,
  TextProps & ComponentPropsWithoutRef<'h1'>
>(function TitleWithRef({ variant = 'title', ...props }, forwardedRef) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});

export const Headline = forwardRef<
  HTMLHeadingElement,
  TextProps & ComponentPropsWithoutRef<'h2'>
>(function HeadlineWithRef({ variant = 'headline', ...props }, forwardedRef) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});

export const Subheadline = forwardRef<
  HTMLHeadingElement,
  TextProps & ComponentPropsWithoutRef<'h3'>
>(function SubheadlineWithRef(
  { variant = 'subheadline', ...props },
  forwardedRef,
) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});

export const Body = forwardRef<
  HTMLParagraphElement,
  TextProps & ComponentPropsWithoutRef<'p'>
>(function BodyWithRef({ variant = 'body', ...props }, forwardedRef) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});

export const Caption = forwardRef<
  HTMLDivElement,
  TextProps & ComponentPropsWithoutRef<'div'>
>(function CaptionWithRef({ variant = 'caption', ...props }, forwardedRef) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});

export const Footnote = forwardRef<
  HTMLDivElement,
  TextProps & ComponentPropsWithoutRef<'div'>
>(function FootnoteWithRef({ variant = 'footnote', ...props }, forwardedRef) {
  return <Text ref={forwardedRef} variant={variant} {...props} />;
});
