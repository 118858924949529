import type { FC, HTMLProps } from 'react';
import { HStack } from '~/components/containers/Stack';
import { Text } from '~/components/next/foundation/Typography';
import { cx } from '~/utils/css-utils';

/**
 * Anything in here has to be approved by the Legal team at Lithic and Patriot
 * bank. So if you change anything just be aware it will result in a potential
 * copy review by them.
 */

interface ExternalLinkProps extends HTMLProps<HTMLAnchorElement> {}
const ExternalLink: FC<ExternalLinkProps> = ({ children, href, className }) => {
  return (
    <a
      href={href}
      target="_blank"
      className={cx(
        'text-base decoration-1 underline-offset-2 hover:underline',
        className,
      )}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

interface ElectronicDisclaimerProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const ElectronicDisclaimer: FC<ElectronicDisclaimerProps> = (props) => {
  return (
    <HStack gap="2" className="items-center">
      <input
        type="checkbox"
        checked={props.checked ?? false}
        onChange={() => props.onChange(!props.checked)}
        className="rounded"
        data-test-id="electronic-communications-checkbox"
      />
      <p>
        I agree to the{' '}
        <ExternalLink href="/legal/e-sign">
          Electronic communications agreement
        </ExternalLink>
      </p>
    </HStack>
  );
};

interface BankingDisclaimerProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const BankingDisclaimer: FC<BankingDisclaimerProps> = (props) => {
  return (
    <HStack gap="2" className="items-center">
      <input
        type="checkbox"
        checked={props.checked ?? false}
        onChange={() => props.onChange(!props.checked)}
        className="rounded"
        data-test-id="direct-deposit-checkbox"
      />
      <p>
        I agree to the{' '}
        <ExternalLink href="/legal/business-banking">
          Business banking agreement
        </ExternalLink>
      </p>
    </HStack>
  );
};

export const AccountDisclaimer = () => {
  return (
    <Text className="text-base-muted">
      By signing up you agree to Tola&apos;s{' '}
      <ExternalLink href="/legal/terms">Terms of service</ExternalLink>
      {', '}
      <ExternalLink href="/legal/privacy">Privacy policy</ExternalLink>
      {', and '}
      <ExternalLink href="/legal/originating">
        Origination agreement
      </ExternalLink>
    </Text>
  );
};

export const NotBankDisclaimer = () => {
  return (
    <Text>
      Tola Inc. is not a FDIC-insured institution. Banking services provided by
      Patriot Bank, N.A., Member FDIC.
    </Text>
  );
};

export const SignupDisclaimer = () => {
  return (
    <Text className="text-base-muted">
      By signing up you agree to Tola&apos;s{' '}
      <ExternalLink href="/legal/terms">Terms of service</ExternalLink>
      {' and '}
      <ExternalLink href="/legal/privacy">Privacy policy</ExternalLink>.
    </Text>
  );
};
