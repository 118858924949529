import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import type { Route } from 'nextjs-routes';
import type { FC, ReactNode } from 'react';
import type { CRC } from '~/components/CRC';
import { HStack } from '~/components/containers/Stack';
import { Ornament } from '~/components/next/atoms/Ornament';
import { Typography } from '~/components/next/foundation/Typography';
import type { IconComponent } from '~/components/withIcon';
import { classNames } from '~/utils/style';

export function SideNav(props: { children: ReactNode }) {
  return <nav className="flex w-full flex-1 flex-col gap-8" {...props} />;
}

const SideNavGroup: CRC = (props) => {
  return <ul className="flex flex-col gap-2" {...props} />;
};

SideNav.Group = SideNavGroup;
SideNav.Group.displayName = 'SideNav.Group';

const sharedClasses =
  'flex w-full items-center rounded p-1 transition hover:bg-gray-100 active:bg-gray-100';
const focusClasses =
  'ring-2 focus-visible:ring-gray-300 ring-offset-2 ring-transparent';

type SideNavButtonProps = {
  name: string;
  Icon: IconComponent;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const SideNavButton: FC<SideNavButtonProps> = (props) => {
  return (
    <button
      disabled={props.disabled}
      className={classNames(
        'bg-white text-gray-500',
        sharedClasses,
        focusClasses,
      )}
      onClick={props.onClick}
    >
      <HStack gap="2" alignItems="center">
        <props.Icon />
        <Typography as="span" variant="body">
          {props.name}
        </Typography>
      </HStack>
    </button>
  );
};

SideNav.Button = SideNavButton;
SideNav.Button.displayName = 'SideNav.Button';

type SideNavLinkProps = Pick<LinkProps, 'replace' | 'onClick'> & {
  name: string;
  href: Route;
} & (
    | { Icon: IconComponent; ornament?: never }
    | { ornament: ReactNode; Icon?: never }
  );

const SideNavLink: FC<SideNavLinkProps> = (props) => {
  const { asPath } = useRouter();

  const pathname = props.href.pathname;

  const normalizedPathname = pathname.endsWith('/')
    ? pathname.slice(0, pathname.length - 1)
    : pathname;

  const normalizedAsPath = asPath
    .replace(/\/org\/[^/?]+/, '/org/[slug]')
    .split('?')[0];

  const isSelected = normalizedAsPath === normalizedPathname;

  return (
    <Link
      href={props.href}
      replace={props.replace}
      className={classNames(
        sharedClasses,
        focusClasses,
        isSelected ? 'bg-gray-100 text-base' : 'bg-white text-gray-500',
      )}
      onClick={props.onClick}
    >
      <HStack gap="2" alignItems="center">
        {props.Icon ? (
          <Ornament.Icon
            variant="tint"
            Icon={props.Icon}
            textColor={isSelected ? 'text-gray-900' : 'text-base-muted'}
          />
        ) : (
          props.ornament
        )}
        <Typography as="span" variant="body">
          {props.name}
        </Typography>
      </HStack>
    </Link>
  );
};

SideNav.Link = SideNavLink;
SideNav.Link.displayName = 'SideNav.Link';
