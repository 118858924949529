import Link from 'next/link';
import { useRouter } from 'next/router';
import { Route } from 'nextjs-routes';
import React, { useEffect, useMemo } from 'react';
import { PageSpinner } from '~/components/Spinner';
import { useViewer } from '~/hooks/useOrg';
import {
  dismissToast,
  showSuccessToast,
} from '~/modules/ui/primitives/toaster';
import { isString } from '~/utils/utility';

export const TOLA2_LAYOUT_FLAG = 'tola2-layout';
let redirectDisabled = false;

export function RedirectToTola2Guard(props: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const viewer = useViewer();
  const hasTola2Layout = viewer.featureFlags.includes(TOLA2_LAYOUT_FLAG);

  const { pathname, query } = router;

  const redirect = useMemo(() => {
    if (redirectDisabled || !hasTola2Layout) {
      return;
    }

    const orgSlugPathName = '/org/[slug]';
    const tola2PathName = '/org/[slug]/v2';

    if (
      !pathname.startsWith(orgSlugPathName) ||
      pathname.startsWith(tola2PathName)
    ) {
      return;
    }
    if (pathname === '/org/[slug]/welcome') {
      // /welcome already has tola 2 layout
      return;
    }

    const orgPath = pathname.substring(orgSlugPathName.length);
    const defaultRedirect = {
      query: router.query,
      pathname: `${tola2PathName}${orgPath}`,
    } as Route;

    // Anything that has special treatment here should be moved to a server-side redirect
    // in the `next.config.js`-file once `/v2/`-prefix is removed on path
    const overrides: Partial<Record<typeof pathname, () => Route | null>> = {
      // http://localhost:3000/org/test-company/refer
      '/org/[slug]/refer': () => {
        return viewer.pathFor('/v2/settings/refer');
      },

      '/org/[slug]/pay': () => {
        if (isString(query.id)) {
          // http://localhost:3000/org/test-company/pay?id=01J70K0TSQEBFGJFDR941FEQ92&kind=payable
          return viewer.pathFor('/v2/pay/[billId]', {
            billId: query.id,
          });
        }
        return null;
      },
      '/org/[slug]/financing': () => {
        if (isString(query.id)) {
          // http://localhost:3000/org/test-company/pay?id=01J70K0TSQEBFGJFDR941FEQ92&kind=payable
          return viewer.pathFor('/v2/pay/[billId]', {
            billId: query.id,
          });
        }
        return null;
      },

      '/org/[slug]/get-paid': () => {
        if (isString(query.id)) {
          // http://localhost:3000/org/test-company/get-paid?id=01J70K0VSW07STEES3AKG83D5T&kind=receivable
          return viewer.pathFor('/v2/get-paid/[billId]', {
            billId: query.id,
          });
        }
        return null;
      },

      '/org/[slug]/contacts': () => {
        if (isString(query.id)) {
          // http://localhost:3000/org/test-company/contacts?id=id--a13fb2b9f04f5c80f76cd14b
          return viewer.pathFor('/v2/contacts/[contactId]', {
            contactId: query.id,
          });
        }
        return null;
      },
    };

    const nextRoute = overrides[pathname]?.() ?? defaultRedirect;

    return {
      from: router,
      to: nextRoute,
    };
  }, [pathname, redirectDisabled, hasTola2Layout]);

  useEffect(() => {
    if (!redirect) {
      return;
    }

    router.replace(redirect.to).then((it) => {
      const toastId = showSuccessToast(`Redirected to new layout of Tola`, {
        description: (
          <>
            You can disable this redirect in your{' '}
            <Link
              href={viewer.pathFor('/v2/settings/profile')}
              className="t2-underline"
            >
              profile settings
            </Link>{' '}
            or{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                redirectDisabled = true;
                router.replace(redirect.from);
                dismissToast(toastId);
                showSuccessToast(`Disabled new Tola layout temporarily`, {
                  description:
                    'If you reload the page, the new Tola layout will be enabled again',
                });
              }}
              className="t2-underline"
            >
              disable it temporarily
            </a>{' '}
            until you reload the page.
          </>
        ),
      });
    });
  }, [redirect]);

  if (redirect) {
    return <PageSpinner />;
  }

  return <>{props.children}</>;
}
