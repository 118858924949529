import type { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import type { createTestingRouter } from '~/server/routers/testing';
import { trpcBase } from '~/utils/trpc.base';

type TestingRouter = ReturnType<typeof createTestingRouter>;

/**
 * These are only relevant on the root "trpc."-object
 **/
type IgnoredTypes =
  | 'Provider'
  | 'createClient'
  | 'useDehydratedState'
  | 'useContext'
  | 'useUtils';

/**
 * Alex could fix these inside tRPC if we want to use them.
 **/
type TODO_TYPES = 'useQueries' | 'useSuspenseQueries';

type TestingTypes = ReturnType<
  typeof createTRPCReact<TestingRouter>
>;

type TestingApi = Omit<TestingTypes, IgnoredTypes | TODO_TYPES>;

// biome-ignore lint/suspicious/noExplicitAny: generated
export const testingApi = (trpcBase as any)
  .testing as TestingApi;

export type TestingInputs = inferRouterInputs<TestingRouter>;
export type TestingOutputs = inferRouterOutputs<TestingRouter>;

export const useTestingUtils = (): Omit<
  ReturnType<
    
    TestingTypes['useUtils']
  >,
  'client'
> => {
  // biome-ignore lint/suspicious/noExplicitAny: generated
  return (trpcBase as any).useUtils().testing;
};
