import { Dialog } from '@headlessui/react';
import { useCallback, useRef } from 'react';
import type { Toast as _Toast } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { Button } from '~/components/Button';
import { ToastContainer, useToasts } from '~/components/Toast';
import { useKeyDown } from '~/hooks/useKeyDown';
import { classNames } from '~/utils/style';

export interface ConfirmDialogOpts {
  /** @default alert */
  type?: 'alert' | 'confirm';
  title: string;
  message?: React.ReactNode;
}

export type ConfirmDialogProps = {
  onResponse: (response: boolean) => void;
} & ConfirmDialogOpts;

export function ConfirmDialog(opts: ConfirmDialogProps & { t: _Toast }) {
  const {
    t,
    title,
    message,
    type = 'alert',
    onResponse: handleResponse,
  } = opts;

  const initialFocusRef = useRef<HTMLAnchorElement | HTMLButtonElement>(null);

  const handleConfirm = useCallback(() => {
    handleResponse(true);
    toast.dismiss(t.id);
  }, [handleResponse, t]);

  useKeyDown({
    key: 'Enter',
    enabled: t.visible,
    handler: function handleEnter(event: KeyboardEvent) {
      event.preventDefault();
      handleConfirm();
    },
  });

  const showCancel = type === 'confirm';

  return (
    <Dialog
      open={t.visible}
      // Prevent close on backdrop click
      onClose={() => null}
      initialFocus={initialFocusRef}
    >
      <Dialog.Overlay className="pointer-events-none">
        <Dialog.Backdrop
          className={classNames(
            'absolute top-0 left-0 z-alert flex h-screen w-screen flex-col items-center pt-4',
            t.visible ? 'animate-enter' : 'animate-leave',
          )}
        >
          <Dialog.Panel>
            <ToastContainer
              data-test-id={`config-dialog-${type}`}
              {...t.ariaProps}
            >
              <p className="overflow-hidden text-ellipsis whitespace-nowrap font-500">
                {title}
              </p>

              {message && (
                <p className={classNames('text-caption text-gray-600')}>
                  {message}
                </p>
              )}

              <div className="flex justify-end gap-2">
                {showCancel && (
                  <Button
                    variant="subtle"
                    onClick={() => {
                      handleResponse(false);
                      toast.dismiss(t.id);
                    }}
                    ref={initialFocusRef}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={handleConfirm}
                  ref={showCancel ? null : initialFocusRef}
                >
                  OK
                </Button>
              </div>
            </ToastContainer>
          </Dialog.Panel>
        </Dialog.Backdrop>
      </Dialog.Overlay>
    </Dialog>
  );
}

function createConfirmDialog(props: ConfirmDialogProps) {
  return function ParameterizedDialog(t: _Toast) {
    return <ConfirmDialog t={t} {...props} />;
  };
}

function showConfirmDialog(props: ConfirmDialogProps) {
  toast.custom(createConfirmDialog(props), {
    position: 'top-center',
    duration: Infinity,
    ariaProps: {
      role: 'alert',
      'aria-live': 'assertive',
    },
  });
}

export function useConfirmDialog() {
  const toasts = useToasts();

  const currentDialog = toasts.find(
    (t) => t.duration === Infinity && t.visible,
  );

  const hasVisibleDialog = Boolean(currentDialog);

  useKeyDown({
    key: 'Escape',
    handler: function handleEscape(event: KeyboardEvent) {
      if (!hasVisibleDialog) {
        return;
      }

      event.preventDefault();
      toast.dismiss(currentDialog?.id);
    },
  });

  const createShowConfigDialog = useCallback(
    (type: ConfirmDialogOpts['type']) => {
      return (opts: Omit<ConfirmDialogOpts, 'type'>) => {
        if (hasVisibleDialog) {
          console.warn('There is already an alert showing');
          return Promise.resolve();
        }

        return new Promise<boolean>((response) => {
          showConfirmDialog({
            type,
            ...opts,
            onResponse: response,
          });
        });
      };
    },
    [hasVisibleDialog],
  );

  const alert = createShowConfigDialog('alert');
  const confirm = createShowConfigDialog('confirm');

  return { alert, confirm, hasVisibleDialog };
}
