import { useEffect } from 'react';
import { useEventCallback } from '~/hooks/useEventCallback';

/**
 * Execute callback when component unmounts.
 */
export function useOnUnmount(_callback: () => void) {
  const callback = useEventCallback(_callback);

  useEffect(
    function trigerCallbackOnUnmount() {
      return () => callback();
    },
    [callback],
  );
}
