import type { Route, RoutedQuery } from 'nextjs-routes';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Button } from '~/components/Button';
import { EjectIcon } from '~/components/GeneratedIcon';
import { usePostHog } from '~/components/Posthog';
import { RedirectWithSpinner } from '~/components/Redirect';
import { Ornament } from '~/components/next/atoms/Ornament';
import { type ViewerOutputs, viewerApi } from '~/generated/trpc/viewer';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import { MFA } from '~/modules/account/ui/MFA';
import { PermissionProvider } from '~/permissions/provider';
import { createCtx } from '~/utils/context';
import { useSetDatadogUser } from '~/utils/datadog';

import { toUnixTime } from '~/utils/format';
import { getUserDisplayName } from '~/utils/getUserDisplayName';

export type ViewerMeOutput = ViewerOutputs['public']['me'];

const AppViewerContext = createContext<null | ViewerMeOutput>(null);

/**
 * Gets the current user from the app context
 * @remark Will throw if the user is not logged in, only use on authenticated pages.
 */
export const useAppViewer = () => {
  const viewer = useContext(AppViewerContext);
  if (!viewer) {
    throw new Error('Viewer is not defined');
  }
  return viewer;
};

/**
 * Gets the current user from the app context
 *
 * @returns The current user or null if not logged in
 */
export const useAppViewerOrNull = () => useContext(AppViewerContext);

/**
 * Fetches the viewer and sets up tracking and Intercom
 */
export const AppViewerProvider = (props: {
  children: React.ReactNode;
  requireMfa: boolean;
}) => {
  const posthog = usePostHog();
  const intercom = useIntercom();
  const publicConfig = usePublicConfig();

  const viewerQuery = viewerApi.public.me.useQuery();

  const viewer = viewerQuery.data ?? null;

  useEffect(() => {
    if (viewer?.impersonation) {
      posthog?.reset();
      posthog?.opt_out_capturing();
      return;
    }

    if (!viewer?.id) {
      return;
    }

    if (posthog?.has_opted_out_capturing()) {
      posthog?.opt_in_capturing();
    }

    posthog?.identify(viewer.id, {
      email: viewer.email,
      name: viewer.displayName,
    });
  }, [
    posthog,
    viewer?.id,
    viewer?.email,
    viewer?.impersonation,
    viewer?.displayName,
  ]);

  useEffect(() => {
    if (
      !viewer?.id ||
      viewer.impersonation ||
      publicConfig.APP_ENV === 'test'
    ) {
      return;
    }
    intercom.update({
      email: viewer.email,
      userId: viewer.id,
      userHash: viewer.tokens.intercom,
      createdAt: viewer?.createdAt ? toUnixTime(viewer.createdAt) : undefined,
      company: undefined,
      name: viewer.displayName,
      hideDefaultLauncher: true,
    });
  }, [
    intercom,
    viewer?.id,
    viewer?.email,
    viewer?.createdAt,
    viewer?.tokens.intercom,
    viewer?.impersonation,
    viewer?.displayName,
  ]);

  const setDatadogUser = useSetDatadogUser();
  useEffect(() => {
    if (!viewer?.id || viewer.impersonation) {
      return;
    }
    setDatadogUser({
      id: viewer.id,
      name: getUserDisplayName({
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        email: viewer.email,
      }),
      email: viewer.email,
    });
  }, [
    viewer?.email,
    viewer?.firstName,
    viewer?.id,
    viewer?.lastName,
    viewer?.impersonation,
    setDatadogUser,
  ]);

  const showMfaWizard =
    viewer && !viewer.hasMfa && !viewer.impersonation && props.requireMfa;

  return (
    <AppViewerContext.Provider value={viewer}>
      <ImpersonateUser />
      {showMfaWizard ? (
        <MFA requiresSetup={!viewer.mfaConfiguration.defaultChannel} />
      ) : (
        props.children
      )}
    </AppViewerContext.Provider>
  );
};

function ImpersonateUser() {
  const viewer = useContext(AppViewerContext);

  if (!viewer?.impersonation) {
    return null;
  }

  return (
    <div className="monospace fixed top-2 left-2 z-50 flex h-11 min-w-[250px] flex-row items-center justify-between gap-2 rounded-lg border-gray-300 border-hairline bg-black px-2 shadow-lg">
      {(viewer.firstName || viewer.image) && (
        <div className="rounded-full bg-white">
          <Ornament.Avatar
            size="lg"
            name={viewer.displayName}
            src={viewer.image ?? undefined}
          />
        </div>
      )}
      <div className="flex-1 text-[12px]">
        <p className="text-white opacity-70">Impersonating</p>
        <p className="text-white">{viewer.displayName}</p>
      </div>
      <Button
        variant="subtle"
        href={{
          pathname: '/api/auth/impersonate/eject',
        }}
        data-test-id="eject-impersonation"
        EndIcon={EjectIcon}
      />
    </div>
  );
}

const orgSegment = '/org/[slug]';
type OrgSegment = typeof orgSegment;
type OrgPath = Route['pathname'] & `${OrgSegment}${string}`;
type RemainingOrgPath = OrgPath extends `${OrgSegment}${infer TPath}`
  ? TPath extends ''
    ? '/'
    : TPath
  : never;

export function createPathFor(slug: string) {
  return function pathFor<
    TPath extends RemainingOrgPath,
    TQuery extends Omit<
      Extract<
        Route,
        { pathname: `${OrgSegment}${TPath}`; query: RoutedQuery<'/org/[slug]'> }
      >['query'],
      'slug'
    >,
  >(
    ...args: Record<string, never> extends TQuery
      ? [path: TPath, query?: Record<string, string>]
      : [path: TPath, query: TQuery]
  ): Extract<Route, { pathname: `${OrgSegment}${TPath}` }> {
    const [path, query] = args;
    return {
      pathname: path === '/' ? orgSegment : `${orgSegment}${path}`,
      query: { slug, ...query },
    } as Extract<Route, { pathname: `${OrgSegment}${TPath}` }>;
  };
}

type Membership = NonNullable<ViewerMeOutput>['memberships'][number];
type Organization = Membership['organization'];
type OrganizationProviderContext = {
  organization: Organization;
  membership: Membership;
  pathFor: ReturnType<typeof createPathFor>;
};
const [useOrgContext, Provider] = createCtx<OrganizationProviderContext>();

export const OrganizationProvider = (props: {
  children: React.ReactNode;
  slug: string;
}) => {
  const viewer = useAppViewer();
  const posthog = usePostHog();
  const intercom = useIntercom();

  const { slug } = props;

  const membership = viewer.memberships.find(
    (m) => m.organization.slug === slug,
  );
  const organization = membership?.organization;

  useEffect(() => {
    if (!organization?.id || viewer.impersonation) {
      return;
    }

    posthog?.group('organization', organization.id, {
      slug: organization.slug,
      displayName: organization.displayName,
    });

    return () => {
      posthog?.resetGroups();
    };
  }, [
    posthog,
    organization?.id,
    organization?.slug,
    organization?.displayName,
    viewer.impersonation,
  ]);

  useEffect(() => {
    if (!organization?.id || viewer.impersonation) {
      return;
    }
    intercom.update({
      company: {
        companyId: organization.id,
        name: organization.displayName,
      },
    });

    return () => {
      intercom.update({
        company: undefined,
      });
    };
  }, [
    intercom,
    organization?.displayName,
    organization?.id,
    viewer.impersonation,
  ]);
  const setDatadogUser = useSetDatadogUser();
  useEffect(() => {
    if (!organization?.id) {
      return;
    }
    if (viewer.impersonation) {
      return;
    }
    setDatadogUser({
      id: viewer.id,
      name: getUserDisplayName({
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        email: viewer.email,
      }),
      email: viewer.email,
      organization: {
        id: organization.id,
        slug: organization.slug,
      },
    });
  }, [
    organization?.id,
    organization?.slug,
    viewer.email,
    viewer.firstName,
    viewer.id,
    viewer.lastName,
    viewer.impersonation,
    setDatadogUser,
  ]);

  const pathFor = useMemo(() => createPathFor(props.slug), [props.slug]);

  if (!organization) {
    return <RedirectWithSpinner href="/org" />;
  }

  return (
    <Provider
      value={{
        organization,
        membership,
        pathFor,
      }}
    >
      <PermissionProvider
        abilities={membership.abilities}
        ctx={{ userId: viewer.id }}
      >
        {props.children}
      </PermissionProvider>
    </Provider>
  );
};

export const useOrganizationContext = useOrgContext;
