import { useMemo } from 'react';
import { useAppViewer, useOrganizationContext } from '~/components/AppContext';
import type { OrgOutputs } from '~/generated/trpc/org';
import { orgApi } from '~/generated/trpc/org';
import { usePermissions } from '~/permissions/provider';

export function useViewer() {
  const viewer = useAppViewer();
  const { organization, membership, pathFor } = useOrganizationContext();
  const permissions = usePermissions();

  return {
    ...permissions,
    ...viewer,
    organizationId: organization.id,
    organization,
    membership,
    pathFor,
  };
}

type PaymentInfo = OrgOutputs['paymentInfo']['list'][number];
type PaymentInfoType = PaymentInfo['type'];

function createTypeFilter<TType extends PaymentInfoType>(type: TType) {
  return (item: PaymentInfo): item is Extract<PaymentInfo, { type: TType }> => {
    return item.type === type;
  };
}

export function useOrgPaymentInfoList<TType extends PaymentInfoType>(
  type: TType,
) {
  const viewer = useViewer();

  const [list] = orgApi.paymentInfo.list.useSuspenseQuery({
    organizationId: viewer.organizationId,
  });

  return useMemo(() => list.filter(createTypeFilter(type)), [list, type]);
}
export type OrgPaymentInfoList<TType extends PaymentInfoType> = ReturnType<
  typeof useOrgPaymentInfoList<TType>
>;

export type Viewer = ReturnType<typeof useViewer>;
