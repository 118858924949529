'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { cn } from '~/modules/ui/cva';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(
  (
    { className, children, sideOffset = 8, collisionPadding = 8, ...props },
    ref,
  ) => (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className={cn(
          't2-relative t2-bg-surface-inverse t2-text-foreground-inverse t2-z-50 t2-rounded-xl t2-px-3 t2-py-1 t2-text-caption t2-max-w-sm',
          't2-animate-in t2-fade-in-0 t2-zoom-in-95 data-[state=closed]:t2-animate-out data-[state=closed]:t2-fade-out-0 data-[state=closed]:t2-zoom-out-95 data-[side=bottom]:t2-slide-in-from-top-2 data-[side=left]:t2-slide-in-from-right-2 data-[side=right]:t2-slide-in-from-left-2 data-[side=top]:t2-slide-in-from-bottom-2',
          className,
        )}
        {...props}
      >
        {children}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  ),
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TolaTooltipProps
  extends Omit<TooltipPrimitive.TooltipContentProps, 'content'> {
  children: React.ReactNode;
  content: string;
  defaultOpen?: TooltipPrimitive.TooltipProps['defaultOpen'];
  open?: TooltipPrimitive.TooltipProps['open'];
  onOpenChange?: TooltipPrimitive.TooltipProps['onOpenChange'];
  delayDuration?: TooltipPrimitive.TooltipProps['delayDuration'];
  disableHoverableContent?: TooltipPrimitive.TooltipProps['disableHoverableContent'];
}

// biome-ignore lint/style/noDefaultExport: Exports a simple default
export default function TolaTooltip({
  defaultOpen,
  open,
  onOpenChange,
  delayDuration,
  disableHoverableContent,
  content,
  children,
  ...props
}: TolaTooltipProps) {
  return (
    <Tooltip
      delayDuration={delayDuration}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipTrigger aria-label={content} asChild>
        {children}
      </TooltipTrigger>
      <TooltipContent {...props}>{content}</TooltipContent>
    </Tooltip>
  );
}

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
