import Matter, {
  Bodies,
  Body,
  Common,
  Composite,
  Composites,
  Engine,
  Mouse,
  MouseConstraint,
  Render,
  Runner,
} from 'matter-js';
import { useRef, useState } from 'react';
import { useOnMount } from '~/hooks/useOnMount';
import { useOnUnmount } from '~/hooks/useOnUnmount';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import type { MarblesProps } from './Marbles';

// large number to avoid balls falling through the walls on resize. don't touch!
const thickness = 6000;

const renderProps = {
  isStatic: true,
  render: {
    visible: false,
  },
};

function initMatter(element: HTMLElement) {
  const engine = Engine.create({
    positionIterations: 10,
    velocityIterations: 10,
  });

  const world = engine.world;

  const runner = Runner.create({
    isFixed: true,
  });

  const { width, height } = element.getBoundingClientRect();

  const render = Render.create({
    element,
    engine,
    options: {
      width,
      height,
      wireframes: false,
      background: 'transparent',
    },
  });

  Render.setPixelRatio(render, 2);
  Render.run(render);
  Runner.run(runner, engine);

  return { width, height, engine, render, world, runner };
}

function calculateScale(width: number, scale: number) {
  // constant used to control scale
  const widthBase = 1000;

  return Math.max((scale / widthBase) * width, scale);
}

interface MarblesOptions {
  element: HTMLElement;
}

interface MarblesInstance {
  setGravity: (gravity: { x: number; y: number }) => void;
  resize: ({
    width,
    height,
    scaleFactor,
  }: {
    width: number;
    height: number;
    scaleFactor: number;
  }) => void;
  stop: () => void;
  start: () => void;
}

async function preloadSprites(sprites: string[]) {
  const loadPromises = sprites.map((sprite) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = sprite;
      img.onload = () => resolve();
      img.onerror = () => reject(new Error(`Failed to load image: ${sprite}`));
    });
  });

  return Promise.all(loadPromises);
}

function createMarbles(
  opts: MarblesOptions,
  props: MarblesProps,
): MarblesInstance {
  const { element } = opts;

  const { width, height, engine, world, render, runner } = initMatter(element);

  const { scaleFactor } = props;

  // will be calculated dynamically depending on element width
  let scale: number = calculateScale(width, scaleFactor || 1);

  if (props.enableMouseConstraint) {
    addMouseConstraint();
  }

  let walls: {
    top: Matter.Body;
    bottom: Matter.Body;
    left: Matter.Body;
    right: Matter.Body;
  };

  // if dom elements are passed, create walls around them
  const domWalls: Matter.Body[] = [];

  let balls: Composite;

  function resize({
    width,
    height,
    scaleFactor,
  }: {
    width: number;
    height: number;
    scaleFactor: number;
  }) {
    if (!walls) {
      return;
    }
    render.options.width = width;
    render.options.height = height;
    render.bounds.max.x = render.bounds.min.x + width;
    render.bounds.max.y = render.bounds.min.y + height;

    // update scale of balls
    const oldScale = scale;
    scale = calculateScale(width, scaleFactor);

    if (scale !== oldScale) {
      const newScale = scale / oldScale;

      balls.bodies.forEach((ball) => {
        if (ball.render.sprite) {
          ball.render.sprite.xScale = ball.render.sprite.xScale * newScale;
          ball.render.sprite.yScale = ball.render.sprite.yScale * newScale;
          Body.scale(ball, newScale, newScale);
        }
      });
    }

    if (render.options.pixelRatio !== 1) {
      Render.setPixelRatio(render, render.options.pixelRatio || 2);
    } else {
      render.canvas.width = width;
      render.canvas.height = height;
    }

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: width, y: height },
    });

    resizeDomWalls();

    // reposition ground
    Matter.Body.setPosition(
      walls.bottom,
      Matter.Vector.create(width / 2, height + thickness / 2),
    );

    // reposition right wall
    Matter.Body.setPosition(
      walls.right,
      Matter.Vector.create(width + thickness / 2, height / 2),
    );
  }

  async function start() {
    const { sprites } = props;

    if (!sprites) {
      return;
    }

    await preloadSprites(sprites);

    walls = {
      bottom: Bodies.rectangle(
        width / 2,
        height + thickness / 2,
        thickness,
        thickness,
        { ...renderProps },
      ),

      top: Bodies.rectangle(width / 2, -thickness / 2, thickness, thickness, {
        ...renderProps,
      }),

      left: Bodies.rectangle(
        0 - thickness / 2,
        height / 2,
        thickness,
        height * 5,
        { ...renderProps },
      ),

      right: Bodies.rectangle(
        width + thickness / 2,
        height / 2,
        thickness,
        height * 5,
        { ...renderProps },
      ),
    };

    Composite.add(world, Object.values(walls));

    addDomWalls();

    let i = -1;

    balls = Composites.stack(
      props.xx || 0,
      props.yy || 0,
      props.columns || 2,
      props.rows || 2,
      props.columnGap || 0,
      props.rowGap || 0,
      function (x: number, y: number) {
        i++;
        const sides = 1;
        const radius = Common.random(48, 48) * scale;

        const texture = sprites[i % sprites.length] || '';
        const { svgBoundingBox = 144 } = props;

        return Bodies.polygon(x, y, sides, radius, {
          density: props.density,
          frictionAir: props.frictionAir,
          restitution: props.restitution,
          friction: props.friction,
          render: {
            sprite: {
              texture,
              xScale: (radius / svgBoundingBox) * 2,
              yScale: (radius / svgBoundingBox) * 2,
            },
          },
        });
      },
    );

    Composite.add(world, balls);

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: width, y: height },
    });
  }

  function getDomPosition(el: HTMLElement) {
    const bounds = el.getBoundingClientRect();

    const { width, height } = bounds;

    return {
      x: el.offsetLeft + width / 2,
      y: el.offsetTop + height / 2,
      width,
      height,
    };
  }

  function addDomWalls() {
    if (!props.domBounds) {
      return;
    }
    props.domBounds.forEach((el, i) => {
      const { x, y, width, height } = getDomPosition(el);

      domWalls[i] = Bodies.rectangle(x, y, width, height, {
        ...renderProps,
      });
    });

    Composite.add(world, domWalls);
  }

  function resizeDomWalls() {
    if (!props.domBounds) {
      return;
    }
    props.domBounds.forEach((el, i) => {
      const wall = domWalls[i];

      const { x, y } = getDomPosition(el);

      if (wall) {
        Matter.Body.setPosition(wall, Matter.Vector.create(x, y));
      }
    });
  }

  function addMouseConstraint() {
    //  add mouse control
    const mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.02,
          render: {
            visible: false,
          },
        },
      });

    // Allow the mousewheel event to propagate
    mouse.element.addEventListener(
      'mousewheel',
      function (event) {
        event.stopPropagation();
      },
      true,
    );

    Composite.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;
  }

  function stop() {
    Matter.Render.stop(render);
    Matter.Runner.stop(runner);
    Matter.World.clear(world, false);
    Matter.Engine.clear(engine);
    render.canvas.remove();
    render.textures = {};
  }

  function setGravity(gravity: { x: number; y: number }) {
    engine.gravity.x = gravity.x;
    engine.gravity.y = gravity.y;
  }

  return {
    setGravity,
    resize,
    stop,
    start,
  };
}
export function useMarbles(props: MarblesProps) {
  const publicConfig = usePublicConfig();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [marbles, setMarbles] = useState<MarblesInstance | null>(null);

  useOnMount(() => {
    // Disable marbles on CI
    if (publicConfig.APP_ENV === 'test') {
      return;
    }

    if (!containerRef.current) {
      return;
    }

    const marbles = createMarbles(
      {
        element: containerRef.current,
      },
      props,
    );

    void marbles.start();

    marbles.setGravity({
      x: props.gravityX !== undefined ? props.gravityX : 1,
      y: props.gravityY !== undefined ? props.gravityY : 1,
    });

    setMarbles(marbles);
  });

  useOnUnmount(() => {
    if (!marbles) {
      return;
    }

    marbles.stop();
  });

  return { marbles, containerRef };
}
