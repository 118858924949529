import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { ReactNode } from 'react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { AlertCircleIcon, CheckmarkIcon } from '~/components/GeneratedIcon';
import type { IconComponent } from '~/components/withIcon';
import { useTimeout } from '~/hooks/useTimeout';
import { classNames } from '~/utils/style';
import type { SpinnerProps } from './Spinner';
import { Spinner } from './Spinner';

const useRecentlyStoppedLoading = (props: { loading?: boolean }) => {
  const [recentlyStoppedLoading, setRecentlyStoppedLoading] = useState(false);

  const { loading } = props;
  useTimeout(
    () => setRecentlyStoppedLoading(false),
    recentlyStoppedLoading ? 1000 : null,
  );

  const prevLoading = useRef(loading);
  useEffect(() => {
    const wasLoading = prevLoading.current;
    prevLoading.current = loading;
    if (wasLoading === true && loading === false) {
      // We stopped loading
      setRecentlyStoppedLoading(true);
    } else if (wasLoading === false && loading === true) {
      // We just started loading, let's force it to false.
      setRecentlyStoppedLoading(false);
    }
  }, [loading]);

  return recentlyStoppedLoading;
};

function ButtonSpinner(props: {
  className?: string;
  style: SpinnerProps['style'];
  loading: boolean;
  error: boolean;
  recentlyStoppedLoading: boolean;
}) {
  return (
    <div className={classNames('relative', props.className)}>
      <Spinner
        style={props.style}
        className={classNames(
          'absolute h-full w-full opacity-0 transition duration-250',
          props.loading && 'opacity-100',
        )}
      />
      <CheckmarkIcon
        className={classNames(
          'absolute h-full w-full opacity-0 transition duration-250',
          props.recentlyStoppedLoading && !props.error && 'opacity-100',
        )}
      />
      <AlertCircleIcon
        className={classNames(
          'absolute h-full w-full opacity-0 transition duration-250',
          props.recentlyStoppedLoading && props.error && 'opacity-100',
        )}
      />
    </div>
  );
}

type Variant =
  | 'primary'
  | 'subtle'
  | 'black'
  | 'outline'
  | 'danger'
  | 'select'
  | 'custom';

type ButtonBaseProps = {
  /**
   * @default primary
   */
  variant?: Variant;
  /**
   * @default false
   */
  loading?: boolean;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * The button spinner should indicate an error occurred.
   * @default false
   */
  error?: boolean;

  hiddenTextWhenSmall?: boolean;
  StartIcon?: IconComponent;
  size?: 'select' | 'default';
} & /**
 * You can only have a StartIcon if you have a children
 * You always need either a EndIcon or a children
 *  */ (
  | { children: ReactNode; StartIcon?: IconComponent; EndIcon: IconComponent }
  | { children: ReactNode; StartIcon?: IconComponent; EndIcon?: never }
  | { children?: never; StartIcon?: never; EndIcon: IconComponent }
);

export type ButtonProps = ButtonBaseProps &
  (
    | (Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> & {
        href: LinkProps['href'] | string;
        shallow?: boolean;
      })
    | (Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick' | 'type'> & {
        // These are here to make the type checker happy when destructuring
        href?: never;
        shallow?: never;
      } & ( // Ensure that buttons have either `onClick` or a `type="submit"`
          | {
              onClick: React.ComponentPropsWithoutRef<'button'>['onClick'];
              type?: 'button';
            }
          | {
              type: 'submit';
              onClick?: React.ComponentPropsWithoutRef<'button'>['onClick'];
            }
          | {
              type?: 'button';
              disabled: true;
              onClick?: React.ComponentPropsWithoutRef<'button'>['onClick'];
            }
        ))
  );

const loadingStyles: Record<Variant, 'white' | 'black'> = {
  primary: 'white',
  black: 'white',
  subtle: 'black',
  select: 'black',
  outline: 'black',
  danger: 'white',
  custom: 'white',
} as const;

const variantConfigurations: Record<
  Variant,
  { enabled: string; clickable: string }
> = {
  // Different styles depending on color
  primary: {
    enabled: 'bg-blue-500 text-white',
    clickable:
      'focus-visible:ring-offset-white focus-visible:ring-blue-500 hover:bg-blue-300',
  },

  black: {
    enabled: 'bg-inverse text-inverse',
    clickable:
      'focus-visible:ring-offset-white focus-visible:ring-black hover:brightness-75',
  },

  subtle: {
    enabled: 'bg-secondary text-gray-secondary',
    clickable:
      'focus-visible:ring-offset-white focus-visible:ring-gray-300 hover:bg-gray-200 hover:text-gray-900',
  },

  outline: {
    enabled: 'border-hairline border-gray-300',
    clickable:
      'focus-visible:ring-gray-300 focus-visible:ring-offset-white hover:border-transparent',
  },

  select: {
    enabled: 'border-hairline border-gray-300',
    clickable:
      'focus-visible:ring-gray-300 focus-visible:ring-offset-white hover:border-transparent hover:border-gray-100 hover:bg-gray-100',
  },

  danger: {
    enabled: 'bg-orange-900 text-white',
    clickable:
      'focus-visible:ring-offset-white focus-visible:ring-orange-900 hover:bg-orange-700',
  },

  custom: {
    enabled: '',
    clickable: '',
  },
};

export const Button = forwardRef<
  React.ElementRef<'button'> | React.ElementRef<'a'>,
  ButtonProps
>(function Button(props: ButtonProps, forwardedRef) {
  const {
    disabled,
    variant = 'primary',
    StartIcon,
    EndIcon,
    onClick,
    href,
    shallow,
    hiddenTextWhenSmall,
    loading = false,
    error = false,
    type = 'button',
    size = 'default',
    // attributes propagated from `HTMLAnchorProps` or `HTMLButtonProps`
    ...passThroughProps
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const recentlyStoppedLoading = useRecentlyStoppedLoading({
    loading: isLoading,
  });

  // Buttons are **always** disabled if we're in a `loading` state
  const clickable = !(disabled || isLoading || recentlyStoppedLoading);

  // If pass an `href`-attr is passed it's `<a>`, otherwise it's a `<button />`
  const isLink = typeof href !== 'undefined';
  const elementType = isLink ? 'a' : 'button';
  const showLoadingSpinner = isLoading || recentlyStoppedLoading;

  const loadingStyle = loadingStyles[variant];

  const variantConfiguration = variantConfigurations[variant];

  const element = React.createElement(
    elementType,
    {
      ...passThroughProps,
      'aria-disabled': disabled ? 'true' : undefined,
      disabled: isLink ? undefined : !clickable,
      // Make sure buttons don't act as submit buttons as default
      type: isLink ? undefined : type,
      ref: forwardedRef,
      className: classNames(
        // Base styles independent what type of button it is
        'tappable relative flex items-center rounded-full font-400 transition duration-250',

        size === 'select' ? 'h-11 w-full' : 'h-8',

        // Outline for :active, :focus
        'ring-2 ring-transparent ring-offset-2 ring-offset-transparent',

        props.children
          ? classNames(
              'py-1',
              EndIcon ? 'min-w-[160px] pr-2' : 'pr-4',
              StartIcon ? 'pl-2' : 'pl-4',
            )
          : // Icon only
            classNames(EndIcon && 'flex w-8 items-center justify-center'),

        !clickable && 'cursor-not-allowed',

        disabled && 'bg-gray-100 text-gray-300',

        !disabled && variantConfiguration.enabled,
        clickable && variantConfiguration.clickable,

        props.className,
        recentlyStoppedLoading && props.error && 'animate-shake',

        isLink && 'inline-block',
      ),
      // if we click a disabled button, we prevent going through the click handler
      onClick: !clickable
        ? (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
          }
        : onClick,
    },
    <>
      <div
        className={classNames(
          props.children && 'grid flex-1 items-center gap-2',
          props.EndIcon
            ? classNames('grid-cols-[1fr_min-content]')
            : 'grid-cols-1',
        )}
      >
        {props.children && (
          <span
            className={classNames(
              'grid gap-2.5 whitespace-nowrap text-left transition-opacity duration-250',
              hiddenTextWhenSmall && 'hidden md:block',
              showLoadingSpinner && !EndIcon && 'opacity-0',
              props.StartIcon ? 'grid-cols-[min-content_1fr]' : 'grid-cols-1',
            )}
          >
            {StartIcon && <StartIcon />}
            <span className="truncate">{props.children}</span>
          </span>
        )}

        {EndIcon && showLoadingSpinner && (
          <ButtonSpinner
            style={loadingStyle}
            loading={isLoading}
            error={error}
            recentlyStoppedLoading={recentlyStoppedLoading}
            className="h-6 w-6"
          />
        )}

        {EndIcon && !showLoadingSpinner && <EndIcon className="h-6 w-6" />}
      </div>

      {!EndIcon && showLoadingSpinner && (
        <div className="absolute inset-0 flex items-center justify-center">
          <ButtonSpinner
            style={loadingStyle}
            loading={isLoading}
            error={error}
            recentlyStoppedLoading={recentlyStoppedLoading}
            className="h-6 w-6"
          />
        </div>
      )}
    </>,
  );

  return href ? (
    <Link
      passHref
      href={href as LinkProps['href']}
      shallow={shallow && shallow}
      legacyBehavior
    >
      {element}
    </Link>
  ) : (
    element
  );
});
