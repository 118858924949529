import { ToastOptions, showErrorToast } from '~/modules/ui/primitives/toaster';
import { isTRPCClientError } from '~/server-shared/trpc';
import { getErrorFromUnknown } from '~/utils/utility';

interface ErrorToastOptions extends Omit<ToastOptions, 'intent'> {
  title: string;
}

export const toastErrorHandler: (error: unknown) => void = (error) => {
  const err = getErrorFromUnknown(error);
  let toastOpts: ErrorToastOptions;
  if (err instanceof FormError) {
    toastOpts = err.toastOpts;
  } else if (
    isTRPCClientError(err) &&
    err.data?.httpStatus &&
    // 4xx
    err.data.httpStatus >= 400 &&
    err.data.httpStatus < 500
  ) {
    toastOpts = {
      title: 'Invalid input',
      description: err.message,
    };
  } else {
    toastOpts = {
      title: 'Something went wrong',
      description: err.message,
    };
  }
  showErrorToast(toastOpts.title, toastOpts);
};

export class FormError extends Error {
  public readonly toastOpts: ErrorToastOptions;
  public constructor(toastOpts: ErrorToastOptions) {
    super(toastOpts.title);
    this.toastOpts = {
      ...toastOpts,
    };

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
