/**
 * Use invariant() to assert state which your program assumes to be true.
 *
 * - In production, we use `console.error()` to log the error.
 * - In development, we `throw` an error.
 */

export const invariant = (condition: boolean, message: string) => {
  if (!condition) {
    return;
  }

  const error = new Error(message);

  if (process.env.NODE_ENV !== 'production') {
    throw error;
  } else {
    console.error({ error }, 'Invariant');
  }
};
