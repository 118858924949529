import type { ReactNode } from 'react';
import { Children, useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '~/components/Button';
import { ArrowLeftIcon, CloseIcon } from '~/components/GeneratedIcon';
import type { StackProps } from '~/components/containers/Stack';
import { HStack, VStack } from '~/components/containers/Stack';
import { createCtx } from '~/utils/context';
import { classNames } from '~/utils/style';
import { omit } from '~/utils/utility';

const [useBaseLayoutContext, BaseLayoutProvider] = createCtx<{
  canScrollDown: boolean;
  scrollYProgress: number;
  scrollToTop: () => void;
  updateScrollCtx: () => void;
}>();

export interface BaseLayoutProps {
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  narrow?: boolean;
  /** @default true */
  stickyFooter?: boolean;
  'data-test-id'?: string;
}

export function BaseLayout(props: BaseLayoutProps) {
  const { header, footer, children, narrow, stickyFooter = true } = props;

  return (
    <BaseLayout.Root narrow={narrow} data-test-id={props['data-test-id']}>
      <BaseLayout.Header>{header}</BaseLayout.Header>
      {children && (
        <BaseLayout.Content stickyFooter={stickyFooter}>
          {children}
          {footer && !stickyFooter && (
            <BaseLayout.Footer sticky={false}>{footer}</BaseLayout.Footer>
          )}
        </BaseLayout.Content>
      )}
      {footer && stickyFooter && (
        <BaseLayout.Footer sticky>{footer}</BaseLayout.Footer>
      )}
    </BaseLayout.Root>
  );
}

BaseLayout.Root = function BaseLayoutRoot(props: {
  children: ReactNode;
  narrow?: boolean;
  className?: string | undefined;
  'data-test-id'?: string;
}) {
  const { children, narrow, className = 'bg-white' } = props;
  const sectionRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const [canScrollDown, setCanScrollDown] = useState(false);
  const [scrollYProgress, setScrollYProgress] = useState(0);

  const updateScrollCtx = useDebouncedCallback(
    (element: HTMLElement) => {
      const canScrollDown =
        element.scrollHeight - (element.scrollTop + element.clientHeight) >= 1;
      setCanScrollDown(canScrollDown);
      setScrollYProgress(
        element.scrollTop / (element.scrollHeight - element.clientHeight),
      );
    },
    200,
    { leading: true, trailing: true },
  );

  const scrollToTop = useCallback(() => {
    sectionRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const element = childrenRef.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(() => {
      updateScrollCtx(element);
    });

    observer.observe(element);
    updateScrollCtx(element);

    return () => observer.disconnect();
  }, [updateScrollCtx]);

  return (
    <BaseLayoutProvider
      value={{
        canScrollDown,
        scrollYProgress,
        scrollToTop,
        updateScrollCtx: () =>
          sectionRef.current && updateScrollCtx(sectionRef.current),
      }}
    >
      <section
        ref={sectionRef}
        className={classNames(
          'flex h-full max-h-fill w-full flex-1 flex-col justify-start overflow-y-auto overflow-x-hidden overscroll-contain scroll-smooth pt-8',
          className,
        )}
        onScroll={(e) => updateScrollCtx(e.currentTarget)}
        data-test-id={props['data-test-id']}
      >
        <div
          ref={childrenRef}
          className={classNames('flex flex-1 flex-col', narrow && 'w-sheet')}
        >
          {children}
        </div>
      </section>
    </BaseLayoutProvider>
  );
};

BaseLayout.Header = function BaseLayoutHeader(props: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <header
      className={classNames(
        'sticky top-0 z-20 h-full max-h-[32px] min-h-[32px] flex-1 px-4 empty:pointer-events-none empty:invisible sm:px-8',
        props.className,
      )}
    >
      {props.children}
    </header>
  );
};

BaseLayout.AlignedHeader = function BaseLayoutHeader(props: {
  children: ReactNode;
}) {
  return (
    <header className="pointer-events-none sticky top-0 z-10 h-full max-h-[32px] min-h-[32px] px-4 pl-gutter-left sm:pr-8">
      {props.children}
    </header>
  );
};

/**
 * This is a wrapper to prevent the header from being clickable
 * Use it if you want to add a button to the header but don't want the container to prevent clicking on an element underneath it.
 */
BaseLayout.InnerHeader = function BaseLayoutInnerHeader(
  props: Pick<StackProps, 'gap' | 'className' | 'children'>,
) {
  const arrayChildren = Children.toArray(props.children);
  return (
    <HStack
      className={classNames(
        'pointer-events-none',
        props.className ?? 'justify-end',
      )}
      gap={props.gap ?? '3'}
    >
      {Children.map(arrayChildren, (child, index) => (
        <span className="pointer-events-auto" key={index}>
          {child}
        </span>
      ))}
    </HStack>
  );
};

BaseLayout.ModalHeader = function ModalHeader(props: {
  onBack?: () => void;
  onClose?: () => void;
}) {
  if (!props.onClose && !props.onBack) {
    return null;
  }

  return (
    <BaseLayout.InnerHeader
      className={classNames(props.onBack ? 'justify-between' : 'justify-end')}
    >
      {props.onBack && (
        <Button
          variant="subtle"
          onClick={props.onBack}
          EndIcon={ArrowLeftIcon}
        />
      )}
      {props.onClose && (
        <Button
          variant="subtle"
          onClick={props.onClose}
          EndIcon={CloseIcon}
          className="ml-auto"
        />
      )}
    </BaseLayout.InnerHeader>
  );
};

BaseLayout.Content = function BaseLayoutContent(props: {
  'data-test-id'?: string;
  children: ReactNode;
  /** @default true */
  stickyFooter?: boolean;
  /**
   * If set to manual, the component won't grow nor have a padding bottom
   * @default 'automatic'
   */
  layout?: 'manual' | 'automatic';
  className?: string;
}) {
  const { layout = 'automatic', className, ...rest } = props;

  return (
    <main
      className={classNames(
        'flex max-w-[100vw] flex-col pr-gutter-right pl-gutter-left',
        layout === 'automatic' && 'flex-1',
        className,
      )}
      {...omit(rest, 'stickyFooter')}
    />
  );
};

BaseLayout.Footer = function BaseLayoutFooter(props: {
  children: ReactNode;
  /** @default true */
  sticky?: boolean;
  bgColorClassName?: string;
}) {
  const { children, sticky = true, bgColorClassName = 'bg-white' } = props;
  const { canScrollDown, updateScrollCtx } = useBaseLayoutContext();
  const ref = useRef(null);

  // Update scroll context when the footer is resized
  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(() => {
      updateScrollCtx();
    });

    observer.observe(element);
    updateScrollCtx();

    return () => observer.disconnect();
  }, [updateScrollCtx]);

  return (
    <footer
      ref={ref}
      className={classNames(
        'z-10 border-t-hairline transition-colors duration-250 ease-drawer empty:hidden',

        bgColorClassName,

        sticky && canScrollDown ? 'border-gray-300' : 'border-transparent',

        sticky ? 'sticky bottom-0 z-10 pt-4 pb-8' : 'mt-auto pt-4 pb-8',
      )}
    >
      <div
        className={classNames(
          sticky && 'mx-6 sm:mx-16 lg:mr-gutter-right lg:ml-gutter-left',
        )}
      >
        {children}
      </div>
    </footer>
  );
};

BaseLayout.Title = function BaseLayoutTitle(props: { children: ReactNode }) {
  return <VStack className="py-10" gap="3" {...props} />;
};
