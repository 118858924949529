import { classNames } from '~/utils/style';

export type SplitLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export function SplitLayout(props: SplitLayoutProps) {
  const { className, ...rest } = props;
  return (
    <section
      className={classNames(
        'divide relative flex h-fill flex-cols overflow-hidden md:divide-x-hairline md:divide-gray-300',
        className,
      )}
      {...rest}
    />
  );
}
