import { Temporal } from '@js-temporal/polyfill';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@radix-ui/react-collapsible';
import { Slot } from '@radix-ui/react-slot';
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  ReactNode,
  forwardRef,
  useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { SetRequired } from 'type-fest';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from '~/modules/ui/primitives/dropdown-menu';
import {
  CalendarIcon,
  ChevronsDownUpIcon,
  ChevronsUpDownIcon,
  CopyIcon,
  DotsHorizontalIcon,
  DownloadIcon,
  EducationIcon,
  EyeIcon,
  EyeOffIcon,
  QuestionMarkCircleIcon,
} from '~/modules/ui/primitives/icon';
import { Object, ObjectTitle } from '~/modules/ui/primitives/object';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/modules/ui/primitives/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '~/modules/ui/primitives/tooltip';
import { writeToClipboard } from '~/modules/ui/write-to-clipboard';
import { toShortDate, toShortDateRange } from '~/utils/format';
import { usdAmount } from '~/utils/usdAmount';
import { VStack } from './stack';

function DetailList({
  gap = '4',
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return <VStack gap={gap} {...props} />;
}

function DetailGroup({
  gap = '0.5',
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      className={cn('t2-group/detail-group', className)}
      gap={gap}
      {...props}
    />
  );
}

function DetailGroupLabel({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        't2-text-foreground-secondary t2-leading-6 t2-text-body t2-px-4 t2-py-3 group-first/detail-group:first:t2-pt-0',
        className,
      )}
      {...props}
    />
  );
}

interface DetailProps extends ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
}

function Detail({ asChild, className, ...props }: DetailProps) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        't2-bg-surface t2-text-foreground t2-group/detail t2-relative t2-grid t2-grid-cols-[minmax(128px,_1fr)_2fr_24px] t2-gap-x-2 t2-rounded-xl t2-pl-4 t2-pr-3 t2-py-3 t2-text-body t2-outline-none',
        className,
      )}
      data-test-id="detail"
      {...props}
    />
  );
}

function DetailLabel({ className, ...props }: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn('t2-text-foreground-secondary t2-leading-6', className)}
      data-test-id="detail-label"
      {...props}
    />
  );
}

function DetailValue({
  alignItems = 'end',
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      className={cn('t2-leading-6 t2-min-w-0', className)}
      alignItems={alignItems}
      data-test-id="detail-value"
      {...props}
    />
  );
}

const DetailIndicator = forwardRef<
  React.ElementRef<typeof VStack>,
  ComponentPropsWithoutRef<typeof VStack>
>(
  (
    { justifyContent = 'center', alignItems = 'center', className, ...props },
    forwardedRef,
  ) => {
    return (
      <VStack
        className={cn(
          't2-rounded-md t2-bg-transparent t2-text-foreground-secondary t2-size-6 t2-shrink-0 t2-outline-none [&_>svg]:t2-size-5',
          className,
        )}
        alignItems={alignItems}
        justifyContent={justifyContent}
        ref={forwardedRef}
        data-test-id="detail-indicator"
        {...props}
      />
    );
  },
);

interface DetailLinkProps extends ComponentPropsWithoutRef<'a'> {
  className?: string;
  label: string;
}

function DetailLink({ children, className, label, ...props }: DetailLinkProps) {
  return (
    <Tooltip>
      <DetailIndicator
        className={cn(
          'hover:t2-bg-surface-muted t2-transition-colors t2-duration-200 t2-ease-out focus-visible:t2-ring-2 focus-visible:t2-ring-foreground',
          className,
        )}
        asChild
      >
        <TooltipTrigger asChild>
          <a {...props}>
            {children}
            <span className="t2-sr-only">{label}</span>
          </a>
        </TooltipTrigger>
      </DetailIndicator>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  );
}

interface DetailCopyProps
  extends ComponentPropsWithoutRef<typeof DetailIndicator> {
  className?: string;
  label: string;
  value: string;
}

function DetailCopy({
  children,
  className,
  label,
  value,
  ...props
}: DetailCopyProps) {
  return (
    <Tooltip>
      <DetailIndicator
        className={cn(
          'hover:t2-bg-surface-muted t2-transition-colors t2-duration-200 t2-ease-out focus-visible:t2-ring-2 focus-visible:t2-ring-foreground',
          className,
        )}
        {...props}
        asChild
      >
        <TooltipTrigger
          type="button"
          onClick={() => {
            writeToClipboard(value);
          }}
        >
          <CopyIcon />
          <span className="t2-sr-only">{label}</span>
        </TooltipTrigger>
      </DetailIndicator>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  );
}

interface DetailIndicatorMenuProps
  extends ComponentPropsWithoutRef<typeof DetailIndicator> {}

function DetailIndicatorMenu({
  className,
  ...props
}: DetailIndicatorMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <DetailIndicator
          className={cn(
            'hover:t2-bg-surface-muted t2-transition-colors t2-duration-200 t2-ease-out focus-visible:t2-ring-2 focus-visible:t2-ring-foreground',
            className,
          )}
          {...props}
        >
          <DotsHorizontalIcon />
          <span className="t2-sr-only">More</span>
        </DetailIndicator>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}

interface DetailIndicatorHelpProps
  extends SetRequired<
    ComponentPropsWithoutRef<typeof DetailIndicator>,
    'children'
  > {}

function DetailIndicatorHelp({
  className,
  children,
  ...props
}: DetailIndicatorHelpProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <DetailIndicator
          className={cn(
            'hover:t2-bg-surface-muted t2-ease-out focus-visible:t2-ring-2 focus-visible:t2-ring-foreground',
            't2-cursor-pointer t2-transition-colors t2-duration-200',
            className,
          )}
          {...props}
        >
          <span className="t2-sr-only">Help</span>
          <QuestionMarkCircleIcon />
        </DetailIndicator>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="t2-w-[var(--radix-popover-content-available-width)] t2-max-w-sm t2-p-2"
        withPortal={false}
      >
        {children}
      </PopoverContent>
    </Popover>
  );
}

function DetailCollapsible({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Collapsible>) {
  return (
    <Collapsible
      className={cn(
        't2-flex t2-flex-col t2-gap-0.5 t2-overflow-hidden t2-rounded-xl',
        className,
      )}
      {...props}
    />
  );
}

const DetailCollapsibleTrigger = forwardRef<
  ElementRef<typeof CollapsibleTrigger>,
  React.ComponentPropsWithoutRef<typeof CollapsibleTrigger>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Detail
      className={cn('t2-rounded-none t2-group t2-text-left', className)}
      asChild
    >
      <CollapsibleTrigger ref={forwardedRef} {...props}>
        {children}
        <DetailIndicator className="t2-flex t2-size-6 t2-shrink-0 t2-items-center t2-justify-center t2-transition-colors t2-duration-200 t2-ease-out group-hover:t2-bg-surface-muted group-focus-visible:t2-ring-2 group-focus-visible:t2-ring-foreground group-disabled:t2-invisible">
          <ChevronsDownUpIcon className="group-data-[state=closed]:t2-hidden group-data-[state=open]:t2-block" />
          <ChevronsUpDownIcon className="group-data-[state=closed]:t2-block group-data-[state=open]:t2-hidden" />
        </DetailIndicator>
      </CollapsibleTrigger>
    </Detail>
  );
});
DetailCollapsibleTrigger.displayName = 'DetailCollapsibleTrigger';

const DetailCollapsibleContent = forwardRef<
  ElementRef<typeof CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof CollapsibleContent>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <CollapsibleContent
      ref={forwardedRef}
      className={cn(
        // NB: Don't add padding here bc it messes up the animation
        't2-overflow-hidden t2-bg-surface/80 t2-transition-all',
        'data-[state=closed]:t2-animate-collapsible-up data-[state=open]:t2-animate-collapsible-down',
        className,
      )}
      {...props}
    >
      <div className="t2-w-full t2-p-5 t2-pr-9">{children}</div>
    </CollapsibleContent>
  );
});
DetailCollapsibleContent.displayName = 'DetailCollapsibleContent';

function DetailSingle({ children, className, ...props }: DetailProps) {
  return (
    <Detail className={cn('t2-grid-cols-1 t2-py-5', className)} {...props}>
      <VStack gap="2" className="*:t2-text-pretty">
        {children}
      </VStack>
    </Detail>
  );
}

interface DetailMessageProps extends DetailProps {
  author?: string | null;
}

function DetailMessage({
  children,
  className,
  author,
  ...props
}: DetailMessageProps) {
  return (
    <Detail
      className={cn('t2-grid-cols-1 t2-py-5', className)}
      {...props}
      data-test-id="detail-message"
    >
      <VStack
        gap="2"
        className="*:t2-text-pretty *:t2-hyphens-auto *:t2-break-words"
      >
        <DetailLabel>Message</DetailLabel>
        {children}
        {author && (
          <p className="t2-text-caption t2-text-foreground-secondary">
            Sent by {author}
          </p>
        )}
      </VStack>
    </Detail>
  );
}

interface DetailCurrencyAmountProps extends DetailProps {
  label: string;
  value: bigint;
}

function DetailCurrencyAmount({
  label,
  value,
  ...props
}: DetailCurrencyAmountProps) {
  return (
    <Detail {...props}>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue>{usdAmount.toCurrencyString(value)}</DetailValue>
    </Detail>
  );
}

interface DetailDateProps extends DetailProps {
  label: string;
  date: Temporal.PlainDate | Date;
  alignValueItems?: 'start' | 'end';
}

function DetailDate({
  alignValueItems = 'end',
  label,
  date,
  ...props
}: DetailDateProps) {
  return (
    <Detail {...props}>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue alignItems={alignValueItems}>
        <Object>
          <CalendarIcon className="t2-text-foreground-secondary" />
          <ObjectTitle>{toShortDate(date)}</ObjectTitle>
        </Object>
      </DetailValue>
    </Detail>
  );
}

interface DetailDateRangeProps extends DetailProps {
  label: string;
  startDate: Temporal.PlainDate | Date;
  endDate: Temporal.PlainDate | Date;
}

function DetailDateRange({
  label,
  startDate,
  endDate,
  ...props
}: DetailDateRangeProps) {
  return (
    <Detail {...props}>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue>{toShortDateRange(startDate, endDate)}</DetailValue>
    </Detail>
  );
}

interface DetailAttachmentProps extends DetailProps {
  label?: string;
  value: string;
  attachmentHref: string | null;
}

function DetailAttachment({
  label,
  value,
  attachmentHref,
  ...props
}: DetailAttachmentProps) {
  return (
    <Detail {...props}>
      <DetailLabel>{label ?? 'Attachment'}</DetailLabel>
      <DetailValue>{value}</DetailValue>
      <DetailLink
        label="Download attachment"
        href={attachmentHref as string}
        download
      >
        <DownloadIcon />
      </DetailLink>
    </Detail>
  );
}

interface MaskedDetailValue
  extends ComponentPropsWithoutRef<typeof DetailValue> {
  label?: string;
  value: ReactNode;
  maskedValue: ReactNode;
}

function MaskedDetailValue({
  value,
  maskedValue,
  ...props
}: MaskedDetailValue) {
  const [showMasked, setShowMasked] = useState(true);
  return (
    <>
      <DetailValue {...props}>{showMasked ? maskedValue : value}</DetailValue>
      <DetailIndicator
        className="hover:t2-bg-surface-muted t2-transition-colors t2-duration-200 t2-ease-out focus-visible:t2-ring-2 focus-visible:t2-ring-foreground"
        asChild
      >
        <button onClick={() => setShowMasked(!showMasked)}>
          {showMasked ? <EyeIcon /> : <EyeOffIcon />}
        </button>
      </DetailIndicator>
    </>
  );
}

interface DetailHelpArticleProps {
  articleId: number;
  label: string;
}

function DetailHelpArticle({ articleId, label }: DetailHelpArticleProps) {
  const intercom = useIntercom();

  return (
    <Detail
      className={cn(
        't2-bg-surface/50 t2-text-foreground-secondary t2-grid-cols-[auto_24px]',
        'hover:t2-bg-surface t2-transition-colors',
        'focus-visible:t2-relative focus-visible:t2-z-10',
        focusRingClassNames,
      )}
      asChild
    >
      <button onClick={() => intercom.showArticle(articleId)}>
        <DetailLabel>
          <Object>
            <EducationIcon />
            <ObjectTitle>{label}</ObjectTitle>
          </Object>
        </DetailLabel>
      </button>
    </Detail>
  );
}

export {
  DetailList,
  Detail,
  DetailSingle,
  DetailCollapsible,
  DetailCollapsibleContent,
  DetailCollapsibleTrigger,
  DetailGroup,
  DetailGroupLabel,
  DetailIndicator,
  DetailIndicatorMenu,
  DetailIndicatorHelp,
  DetailCopy,
  DetailLabel,
  DetailLink,
  DetailValue,
  DetailMessage,
  DetailCurrencyAmount,
  DetailDate,
  DetailDateRange,
  DetailAttachment,
  DetailHelpArticle,
  MaskedDetailValue,
};
