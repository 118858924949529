import type { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

import type { createViewerRouter } from '~/server/routers/viewer';
import { trpcBase } from '~/utils/trpc.base';

type ViewerRouter = ReturnType<typeof createViewerRouter>;

/**
 * These are only relevant on the root "trpc."-object
 **/
type IgnoredTypes =
  | 'Provider'
  | 'createClient'
  | 'useDehydratedState'
  | 'useContext'
  | 'useUtils';

/**
 * Alex could fix these inside tRPC if we want to use them.
 **/
type TODO_TYPES = 'useQueries' | 'useSuspenseQueries';

type ViewerTypes = ReturnType<
  typeof createTRPCReact<ViewerRouter>
>;

type ViewerApi = Omit<ViewerTypes, IgnoredTypes | TODO_TYPES>;

// biome-ignore lint/suspicious/noExplicitAny: generated
export const viewerApi = (trpcBase as any)
  .viewer as ViewerApi;

export type ViewerInputs = inferRouterInputs<ViewerRouter>;
export type ViewerOutputs = inferRouterOutputs<ViewerRouter>;

export const useViewerUtils = (): Omit<
  ReturnType<
    
    ViewerTypes['useUtils']
  >,
  'client'
> => {
  // biome-ignore lint/suspicious/noExplicitAny: generated
  return (trpcBase as any).useUtils().viewer;
};
